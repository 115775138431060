import { useState, useEffect } from "react";
import { DataTable, Page, Card, Filters } from "@shopify/polaris";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, getTableFooter } from "../../shared/components/functions";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function PitchersPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false)
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
      ["sn", { type: "text", name: "Serial number", value: url.get("sn") || "" }],
      ["pitcherType]", { type: "text", name: "Pitcher type", value: url.get("pitcherType") || "" }],
      ["boxsn", { type: "text", name: "Box SN", value: url.get("boxsn") || "" }],
    ])
  );

  useEffect(() => {
    async function fetchData() {
      let filterString = filtersToApiUrl(filters);
      const data = await getRequest(`/api/pitchers?${filterString}`);
      setItems(data);
      setLoaded(true)
      if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    }
    fetchData();
  }, [filters]);

  const rows = items.map((item) => {
    return [item.RfidSn, item.PitcherType, item.BoxID, item.Line2, item.Line3, item.TemperatureCorrection];
  });

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="Pitchers" fullWidth>
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter pitchers"
        />
        <DataTable
          verticalAlign="middle"
          columnContentTypes={["text", "text", "text", "text", "text", "text", "text"]}
          headings={[<b>SN</b>, <b>Pitcher type</b>, <b>Box SN</b>, <b>Line 2</b>, <b>Line 3</b>, <b>TempCorrection</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "pitchers")}
        />
      </Card>
    </Page>
  );
}
