import { useState, useEffect } from "react";
import { Badge, DataTable, Page, Button, Card, Filters } from "@shopify/polaris";
import { PrintIcon } from "@shopify/polaris-icons";
import { BoxModal } from "./components/boxModal";
import { printLabel } from "../../shared/dymo/labels/box_labels/pdfBox";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, getTableFooter } from "../../shared/components/functions";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function BoxesPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false)
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
    ])
  );

  useEffect(() => {
    async function fetchData() {
      let filterString = filtersToApiUrl(filters);
      const data = await getRequest(`/api/boxes?${filterString}`);
      setItems(data);
      setLoaded(true)
      if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    }
    fetchData();
  }, [filters]);

  async function printLabelBox(id) {
    const data = await getRequest(`/api/boxes/detail/${id}`);
    printLabel(data);
  }

  const rows = Array.isArray(items)
    ? items.map((item) => {
        return [
          item.ID,
          <BoxModal id={item.ID} />,
          item.WeightGram,
          item.Name,
          <Badge tone={item.InProduction ? "success" : null}>{item.InProduction ? "YES" : "NO"}</Badge>,
          <Badge tone={item.Produced ? "success" : null}>{item.Produced ? "YES" : "NO"}</Badge>,
          <Button icon={PrintIcon} size="slim" disabled={!item.Produced} onClick={() => printLabelBox(item.ID)} />,
        ];
      })
    : [];

    if (!loaded) return <PageSpinner />;

  return (
    <Page title="Boxes" fullWidth>
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter boxes"
        />
        <DataTable
          verticalAlign="middle"
          increasedTableDensity
          columnContentTypes={["text", "text", "text", "text", "text", "text", "text"]}
          headings={[<b>SN</b>, <b>Detail</b>, <b>Weight gr</b>, <b>Boxtype</b>, <b>In production</b>, <b>Produced</b>, <b>Label</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "boxes")}
        />
      </Card>
    </Page>
  );
}
