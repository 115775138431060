import { useState, useEffect } from "react";
import { Box, Divider, Modal } from "@shopify/polaris";
import InventoryReceivingItem from "./inventoryReceivingItem";
import { formatDate, formatDateTime, getRequest, patchRequest, postRequest } from "../../../shared/components/functions";
import { Toasts } from "../../../shared/components/toasts/toasts";

export default function InventoryReceivingModal({ getPurchase, active, toggleModal, lines }) {
  const [classTypes, setClassTypes] = useState([]);
  const [toasts, setToasts] = useState({ stockToast: null, qcToast: null, inventoryToast: null });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const result = await getRequest("/api/qc/types/classification");
      const types = result.filter((type) => !type.Success).map((type) => ({ label: type.Classification, value: String(type.ID) }));
      setClassTypes(types);
    }
    fetchData();
  }, []);

  const closeModal = (stockchange, qcchange, inventorychange) => {
    setToasts({
      stockToast: stockchange ? (stockchange === "ok" ? null : "ok") : "error",
      qcToast: qcchange ? (qcchange === "ok" ? null : "ok") : "error",
      inventoryToast: inventorychange ? "ok" : "error",
    });
    getPurchase();
    setLoading(false);
    toggleModal(false);
  };

  const handleAcceptAll = async () => {
    if (!window.confirm("This will accept every remaining incoming part on this order and increase its stock. Continue?")) return;

    setLoading(true);

    for (let i = 0; i < lines.length; i++) {
      const line = lines[i];

      const stockchange_payload = {
        part_id: line.produktnr,
        stock_diff: line.StillOnOrder,
        timestamp: formatDate(new Date()),
        is_order: true,
        order_id: line.BestellingID,
        source: `Stock in #${line.BestellingID} QC OK`,
        stockchange_type_id: 4,
      };

      const qc_payload = {
        user_id: 69,
        timestamp: formatDateTime(new Date()),
        quality_passed: true,
        note: "",
        stockchange_id: "",
        classification_id: 10,
        S3ObjectKeys: null,
      };

      const stockchange = await postRequest("/api/stockchanges", stockchange_payload);
      if (!stockchange) return closeModal(stockchange);

      qc_payload.stockchange_id = stockchange.stockchange_id;
      const qcchange = await postRequest("/api/qc", qc_payload);
      if (!qcchange) return closeModal(stockchange, qcchange);

      const inventorychange = await patchRequest(`/api/inventory/${stockchange_payload.part_id}`, { stockdiff: stockchange_payload.stock_diff });
      closeModal("ok", "ok", inventorychange);
    }
  };

  return (
    <>
      <Toasts toast={toasts?.stockToast} setToast={setToasts} toastContent="Stock updated" errorToastContent="Updating stock failed" />
      <Toasts toast={toasts?.qcToast} setToast={setToasts} toastContent="QC saved" errorToastContent="Saving QC failed" />
      <Toasts toast={toasts?.inventoryToast} setToast={setToasts} toastContent="Inventory updated" errorToastContent="Updating inventory failed" />
      <Modal open={active} onClose={toggleModal} title="Inventory receiving" primaryAction={{ content: "Accept full remaining order", onAction: handleAcceptAll, loading: loading }}>
        <Modal.Section>
          {lines.map((item, idx) => {
            return (
              <Box key={idx} paddingBlockEnd={lines.length !== idx + 1 && "600"}>
                <Box paddingBlockEnd="600">
                  <InventoryReceivingItem item={item} getPurchase={getPurchase} classTypes={classTypes} toggleModal={toggleModal} setToasts={setToasts} />
                </Box>
                {lines.length !== idx + 1 && <Divider />}
              </Box>
            );
          })}
        </Modal.Section>
      </Modal>
    </>
  );
}
