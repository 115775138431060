import { useEffect, useState } from "react";
import { Badge, Filters, DataTable, Page, Card, Link } from "@shopify/polaris";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, getTableFooter } from "../../shared/components/functions";

export default function CompaniesPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const statusOptions = [
    { label: "All", value: "all" },
    { label: "Active", value: "0" },
    { label: "Distcontinued", value: "1" },
  ];

  const relationOptions = [
    { label: "Customer", value: "Customer" },
    { label: "Supplier", value: "Supplier" },
    { label: "Private", value: "Private" },
  ];

  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100" }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
      ["name", { type: "text", name: "Name", value: url.get("name") || "" }],
      ["status", { type: "status", name: "Status", value: url.get("status") || "all", options: statusOptions }],
      ["relation", { type: "multi", name: "Relation", value: url.get("relation") ? JSON.parse(url.get("relation")) : [], options: relationOptions }],
    ])
  );

  useEffect(() => {
    async function fetchData() {
      let filterString = filtersToApiUrl(filters);
      const data = await getRequest(`/api/companies?${filterString}`);
      setItems(data);
      if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    }
    fetchData();
  }, [filters]);

  const rows = items.map((item) => {
    var particulier = item.Particulier ? <Badge>Private</Badge> : "";
    var supplier = item.Leverancier ? <Badge>Supplier</Badge> : "";
    var customer = item.Klant ? <Badge>Customer</Badge> : "";
    var country_badge = <div>{item.Land}</div>;

    const company_status =
      item.Status === "Actief" ? (
        <Badge tone="success">Actief</Badge>
      ) : item.Status === "Gesperd" ? (
        <Badge tone="critical">Gesperd</Badge>
      ) : item.Status === "In Aanvraag" ? (
        <Badge tone="info">In aanvraag</Badge>
      ) : item.Status === "Inactief" ? (
        <Badge tone="warning">Inactief</Badge>
      ) : (
        ""
      );
    return [
      <Link removeUnderline url={"/companies/" + item.FIRNR}>
        {item.Klantnummer}
      </Link>,
      item.NAAM,
      item.STRAAT_NR ? String(item.STRAAT_NR).substring(0, 30) : "",
      item.WOONPL,
      country_badge,
      <div>
        {particulier}
        {supplier}
        {customer}
      </div>,
      item.OPMERKING ? String(item.OPMERKING).substring(0, 30) : "",
      item.TEL,
      company_status,
    ];
  });

  return (
    <Page title="Companies" fullWidth>
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter companies"
        />
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text"]}
          headings={[<b>{"Number"}</b>, <b>{"Name"}</b>, <b>{"Address"}</b>, <b>{"City"}</b>, <b>{"Country"}</b>, <b>{"Relationship"}</b>, <b>{"Remark"}</b>, <b>{"Tel"}</b>, <b>{"Status"}</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), setFilters, "companies")}
        />
      </Card>
    </Page>
  );
}
