import { BlockStack, Box, Button, DropZone, InlineStack, Link, Modal, ResourceItem, ResourceList, Text, TextField, Thumbnail } from "@shopify/polaris";
import { postRequest } from "../../../../shared/components/functions";
import { DeleteIcon, EditIcon, NoteIcon } from "@shopify/polaris-icons";
import { useCallback, useState } from "react";
import { getFileColor, handleFileChange, validImageTypes } from "./filefunctions";

export const FileTable = ({ title, typeSortedFiles, AwsFileKeys, S3ObjectUrls, handleFiles }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [fileToEdit, setFileToEdit] = useState(null);
  const [initialFileToEdit, setInitialFileToEdit] = useState(null);
  const [fileToUpload, setFileToUpload] = useState();
  const [nameChange, setNameChange] = useState(false);
  const [fileChange, setFileChange] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleClose = () => {
    setIsEdit(false);
    setFileToEdit(null);
    setInitialFileToEdit(null);
    setNameChange(false);
    setFileChange(false);
    setFileToUpload(null);
  };

  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, _rejectedFiles) => {
    setFileToUpload(acceptedFiles[0]);
    setFileChange(true);
  }, []);

  const handleEdit = async () => {
    setIsSaving(true);
    if (nameChange) {
      const fileEnding = initialFileToEdit.key.slice(initialFileToEdit.key.lastIndexOf("_"));
      fileToEdit.key = `product_files/${fileToEdit.type}_${fileToEdit.name}${fileEnding}`;
      await postRequest(`/api/aws/s3/rename`, { oldKey: initialFileToEdit.key, newKey: fileToEdit.key });

      let updatedKeys = AwsFileKeys.filter((obj) => obj.key !== initialFileToEdit.key);

      updatedKeys.push(fileToEdit);
      await handleFiles(updatedKeys, "Files");
    }

    if (fileChange) {
      await handleFileChange(false, initialFileToEdit, fileToUpload, handleFiles, AwsFileKeys);
    }

    setIsSaving(false);
    handleClose();
  };

  const handleDelete = async (item) => {
    const confirmed = window.confirm("Are you sure you want to delete this file?");
    if (!confirmed) return;
    await postRequest(`/api/aws/s3/delete`, { keys: [item.key] });

    const updatedKeys = AwsFileKeys.filter((obj) => obj.key !== item.key);

    await handleFiles(updatedKeys, "Files");
  };

  const handleChange = (value, id) => {
    setNameChange(true);
    setFileToEdit((fileToEdit) => ({
      ...fileToEdit,
      [id]: value,
      key: `product_files/${fileToEdit.type}_${value}${fileToEdit.key.slice(initialFileToEdit.key.lastIndexOf("_"), -1)}`,
    }));
  };

  const fileUpload = !fileToUpload && <DropZone.FileUpload />;
  const uploadedFile = fileToUpload && (
    <Box padding="200">
      <InlineStack gap="200">
        <Thumbnail size="small" alt={fileToUpload.name} source={validImageTypes.includes(fileToUpload.type) ? window.URL.createObjectURL(fileToUpload) : NoteIcon} />
        <BlockStack>
          {fileToUpload.name}
          <Text variant="bodySm" as="p">
            {fileToUpload.size} bytes
          </Text>
        </BlockStack>
      </InlineStack>
    </Box>
  );

  return (
    <>
      <div style={{ padding: "5px 20px", backgroundColor: "#f6f6f7" }}>
        <Text variant="headingMd" fontWeight="semibold">
          {title}
        </Text>
      </div>

      <Box paddingInlineStart="200">
        <ResourceList
          items={typeSortedFiles}
          renderItem={(item) => {
            const fileExtension = item.key.slice(item.key.lastIndexOf(".") + 1);
            return (
              <ResourceItem>
                <InlineStack align="space-between">
                  <Link removeUnderline target="_blank" url={S3ObjectUrls.find((key) => key.key === item.key)?.url}>
                    {item.name}
                  </Link>

                  <InlineStack gap="200">
                    <div style={{ color: getFileColor(fileExtension) }}>{fileExtension}</div>
                    <div style={{ display: "flex", justifyContent: "space-around", align: "center", height: "15px", width: "60px", marginRight: 0 }}>
                      <Button
                        variant="plain"
                        icon={EditIcon}
                        onClick={() => {
                          setIsEdit(true);
                          setFileToEdit(item);
                          setInitialFileToEdit(item);
                        }}
                      />
                      <Button variant="plain" tone="critical" icon={DeleteIcon} onClick={() => handleDelete(item)} />
                    </div>
                  </InlineStack>
                </InlineStack>
              </ResourceItem>
            );
          }}
        />
      </Box>

      {isEdit ? (
        <Modal open={isEdit} onClose={handleClose} title="Update file name" primaryAction={{ content: "Save", onAction: handleEdit, loading: isSaving }}>
          <Modal.Section>
            <BlockStack gap="400">
              <TextField
                id="name"
                autoComplete="off"
                label=""
                disabled={fileChange}
                value={fileToEdit.name}
                onChange={(value) => handleChange(value, "name")}
                prefix={`${fileToEdit.type}_` || ""}
                suffix={`${initialFileToEdit.key.slice(initialFileToEdit.key.lastIndexOf("_"))}`}
              />
              <DropZone allowMultiple={false} disabled={nameChange} onDrop={handleDropZoneDrop}>
                {uploadedFile}
                {fileUpload}
              </DropZone>
            </BlockStack>
          </Modal.Section>
        </Modal>
      ) : null}
    </>
  );
};
