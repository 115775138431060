import { formatToCurrency, pad_with_zeroes } from "../../../shared/components/functions";
import { BlockStack, Card, DataTable, Divider, Link } from "@shopify/polaris";
import { CardHeading } from "../../../shared/components/CardHeading";

const SharePointFactuurTranslations = {
  1: "Orderbevestiging",
  2: "Confirmation de commande",
  3: "Order Confirmation",
  4: "Auftragsbestätigung",
};

export default function DocsBox({ sale, loaded }) {
  return (
    loaded && (
      <Card>
        <CardHeading title="Documents" />
        <BlockStack gap="400">
          <BlockStack>
            <CardHeading title="Order confirmation" />
            <BlockStack>
              <Link
                removeUnderline
                target="_blank"
                url={`https://schuilenburg.sharepoint.com/Schuilenburg/${pad_with_zeroes(sale.order.customer.company_number, 5)}/Orderbevestiging/${sale.order.order_number}/${
                  SharePointFactuurTranslations[sale.order.language.ID]
                }%20${sale.order.order_number}.pdf`}>
                {"Pdf online"}
              </Link>
              <Link removeUnderline target="_blank" url={`https://admin.schuilenburg.be/sales/${sale.order.order_id}`}>
                {"SB admin"}
              </Link>
            </BlockStack>
          </BlockStack>

          <Divider />

          <BlockStack>
            <CardHeading title={`Invoices (${sale.invoices.length})`} />
            {sale.invoices.length > 0 ? (
              <DataTable
                verticalAlign="middle"
                increasedTableDensity
                columnContentTypes={["text", "text", "text", "text"]}
                headings={["Invoice", "Value", "Value open", "Pdf"]}
                rows={sale.invoices.map((invoice) => {
                  return [
                    <Link removeUnderline target="_blank" url={`https://admin.schuilenburg.be/invoices/${invoice.faktuurnr}`}>
                      {invoice.Factuurnummer}
                    </Link>,
                    formatToCurrency(invoice.totaalbedrag),
                    formatToCurrency(invoice.bedragopen),
                    <Link removeUnderline target="_blank" url={`https://schuilenburg.sharepoint.com/Schuilenburg/00977/Orderbevestiging/20210269/Orderbevestiging%2020210269.pdf/${invoice.faktuurnr}`}>
                      {"open"}
                    </Link>,
                  ];
                })}
              />
            ) : (
              "No invoices yet for this sales order"
            )}
          </BlockStack>

          <Divider />

          <BlockStack>
            <CardHeading title={`Deliveries (${sale.deliveries.length})`} actions={[{ content: "Add delivery note", variant: 'secondary' }]} />
            {sale.deliveries.length > 0 ? (
              <DataTable
                verticalAlign="middle"
                increasedTableDensity
                columnContentTypes={["text", "text"]}
                headings={["Delivery note", "Pdf"]}
                rows={sale.deliveries.map((delivery) => {
                  return [
                    <Link removeUnderline target="_blank" url={`https://admin.schuilenburg.be/invoices/${delivery.faktuurnr}`}>
                      {delivery.LeveringsbonNummer}
                    </Link>,
                    <Link
                      removeUnderline
                      target="_blank"
                      url={`https://schuilenburg.sharepoint.com/Schuilenburg/00977/Orderbevestiging/20210269/Orderbevestiging%2020210269.pdf/${delivery.faktuurnr}`}>
                      {"open"}
                    </Link>,
                  ];
                })}
              />
            ) : (
              "No delivery notes yet for this sales order"
            )}
          </BlockStack>
        </BlockStack>
      </Card>
    )
  );
}
