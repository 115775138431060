import { create } from "zustand";
import { persist } from "zustand/middleware";
import { getRequest } from "../shared/components/functions";

export const useAuthStore = create((set) => ({
  user: null,
  setUser: (user) => set({ user }),
  errorMessage: null,
  isLoading: false,

  login: async (payload) => {
    set({ isLoading: true });
    try {
      let response = await fetch("/api/login", { method: "POST", headers: { "Content-Type": "application/json" }, body: JSON.stringify(payload) });
      if (response.status === 401) {
        set({ errorMessage: "Username or password was incorrect!" });
        set({ isLoading: false });
        return null;
      }
      let data = await response.json();
      if (response.ok) {
        set({ user: data });
        set({ isLoading: false });
        localStorage.setItem("currentUser", JSON.stringify(data));
        return data;
      }
    } catch (err) {
      set({ errorMessage: "Login failed, please try again." });
      set({ isLoading: false });
      console.log("Login error: ", err);
    }
  },

  logout: async () => {
    set({ isLoading: true });
    try {
      set({ user: null });
      set({ isLoading: false });
      localStorage.clear();
    } catch (err) {
      console.error("Logout failed.");
    }
    set({ isLoading: false });
  },
}));

export const useLookupStore = create(
  persist(
    (set) => ({
      hardwaretypes: [],
      setHardwaretypes: async () => set({ hardwaretypes: await getRequest(`/api/hardwaretypes`) }),

      hardwareboilertypes: [],
      setHardwareboilertypes: async () => set({ hardwareboilertypes: await getRequest(`/api/hardwareboilertypes`) }),

      pickingplaces: [],
      setPickingplaces: async () => set({ pickingplaces: await getRequest(`/api/pickingplaces`) }),

      producttypes: [],
      setProducttypes: async () => set({ producttypes: await getRequest(`/api/producttypes`) }),

      recipetypes: [],
      setRecipetypes: async () => set({ recipetypes: await getRequest(`/api/recipetypes`) }),

      softwaretypes: [],
      setSoftwaretypes: async () => set({ softwaretypes: await getRequest(`/api/softwaretypes`) }),

      units: [],
      setUnits: async () => set({ units: await getRequest(`/api/units`) }),

      users: [],
      setUsers: async () => set({ users: await getRequest(`/api/users`) }),

      workstations: [],
      setWorkstations: async () => set({ workstations: await getRequest(`/api/workstations`) }),
    }),
    { name: "lookup-storage" }
  )
);

export const useStationStore = create((set) => ({
  stationData: [],
  setStationData: async () => set({ stationData: await getRequest(`/api/rfid_events/stations`) }),
}));
