/*
    PMG10-LF-01-1
    PMJ20-FF01-1
    const strings = ["PMG20-LF01-1", "PMJ20-FF01-1"]
*/


let machine_type = new Map([
    ["G", { decoded: "Greg", image: "www.google.com/pm" }],
    ["J", { decoded: "Jack", image: "www.google.com/pm" }]
]);

let voltage = new Map([
    ["1", { decoded: "100-127 Volt", image: "" }],
    ["2", { decoded: "208-240 Volt", image: "" }]
]);

let frequency = new Map([
    ["0", { decoded: "50 Hertz and 60 Hertz", image: "" }],
    ["1", { decoded: "epic jack", image: "" }],
]);

let cable_attachment_type = new Map([
    ["F", { decoded: "Fixed", image: "" }],
    ["L", { decoded: "Loose", image: "" }],
    ["X", { decoded: "Fixed (cable fixed to device - excluding plug)", image: "" }],
    ["Y", { decoded: "Americas and Japan Only, cable fixed to device - excluding plug", image: "" }]
]);

let cable_type = new Map([
    ["B", { decoded: "Type B", image: "", color: "orange" }],
    ["D", { decoded: "Type D", image: "", color: "gray" }],
    ["E", { decoded: "Type E", image: "", color: "red" }],
    ["F", { decoded: "Type F", image: "", color: "lightblue" }],
    ["G", { decoded: "Type G", image: "", color: "darkgray" }],
    ["H", { decoded: "Type H", image: "", color: "yellow" }],
    ["I", { decoded: "Type I", image: "", color: "gold" }],
    ["J", { decoded: "Type J", image: "", color: "green" }],
    ["K", { decoded: "Type K", image: "", color: "lightgreen" }],
    ["L", { decoded: "Type L", image: "", color: "cyan" }],
    ["M", { decoded: "Type M", image: "", color: "tomato" }],
    ["N", { decoded: "Type N", image: "", color: "olive" }],
    ["O", { decoded: "Type O", image: "", color: "violet" }],
    ["Z", { decoded: "Type B", image: "", color: "white" }]
]);

let color = new Map([
    ["01", { decoded: "Black", image: "" }],
    ["02", { decoded: "White", image: "" }]
]);

let pitcher_holder = new Map([
    ["1", { decoded: "Standard", image: "" }],
    ["2", { decoded: "Epic", image: "" }]
]);

export let configurator = new Map([
    ["machine_type", machine_type],
    ["voltage", voltage],
    ["frequency", frequency],
    ["cable_attachment_type", cable_attachment_type],
    ["cable_type", cable_type],
    ["color", color],
    ["pitcher_holder", pitcher_holder]
]);

export function getDecodedMachineVersion(machineVersion: string){
    let decoded  = ""
    decoded += "Model: " + configurator.get("machine_type")?.get(machineVersion[2])?.decoded  + "\n" ;
    decoded += "Voltage: " + configurator.get("voltage")?.get(machineVersion[3])?.decoded  + "\n"
    // decoded += "Frequency: " + configurator.get("frequency")?.get(machineVersion[4])?.decoded  + "\n"
    decoded += "Cable attach: " + configurator.get("cable_attachment_type")?.get(machineVersion[6])?.decoded  + "\n"
    decoded += "Cable type: " + configurator.get("cable_type")?.get(machineVersion[7])?.decoded  + "\n"
    decoded += "Color: " + configurator.get("color")?.get(machineVersion[8] + machineVersion[9])?.decoded  + "\n"

    return decoded;
}