import { Button, Text, ButtonGroup, TextField, InlineError } from "@shopify/polaris";
import { useState } from "react";
import { handleSaveOnEnter } from "../../../shared/components/functions";
import { S3ImageUploader, S3ImageViewer } from "../../../shared/components/s3_image_display/s3ImageUploader";
import { useLookupStore } from "../../../utils/useStore";

const ImageDisplay = ({ check }) => {
  const picArr = JSON.stringify(
    check.pictures?.map((p) => {
      return p.key;
    })
  );
  return <S3ImageViewer S3ObjectKeys={picArr || "[]"} viewOnly />;
};

export const BooleanCheck = ({ check, handleSave, isLoading }) => {
  return (
    <div style={{ flexDirection: "column", width: "50%" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Text variant="headingLg" as="h5">
          {check.name}
        </Text>

        <ButtonGroup gap="loose">
          <Button variant="primary" loading={isLoading} onClick={() => handleSave(check, { passed: true })}>
            OK
          </Button>
          <Button variant="primary" tone="critical" loading={isLoading} onClick={() => handleSave(check, { passed: false })}>
            NOT OK
          </Button>
        </ButtonGroup>
      </div>

      <div>
        <Text variant="headingMd" as="h6">
          {check.description}
        </Text>
        <ImageDisplay check={check} />
      </div>
    </div>
  );
};

export const ValueCheck = ({ check, handleSave, isLoading }) => {
  const { units } = useLookupStore();
  const [checkValue, setCheckValue] = useState("");
  const [error, setError] = useState(false);

  const handleValueSave = () => {
    if (!checkValue) return setError(true);
    const updatedFields = {
      value: Number(checkValue),
      passed: Number(checkValue) >= check.acceptValueMin && check.acceptValueMax >= Number(checkValue),
    };
    handleSave(check, updatedFields);
    setCheckValue("");
  };

  return (
    <div style={{ flexDirection: "column", width: "50%" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Text variant="headingLg" as="h5">
          {check.name}
        </Text>

        <div onKeyDown={(e) => handleSaveOnEnter(e, handleValueSave)}>
          <TextField
            key={check.checkId}
            value={checkValue}
            type="number"
            onChange={(e) => setCheckValue(e)}
            error={error ? "Input is required" : false}
            suffix={units.find((u) => u.EenheidID === check.unitId)?.Eenheid}
            connectedRight={
              <Button loading={isLoading} onClick={handleValueSave}>
                Submit
              </Button>
            }
          />
        </div>
      </div>

      <div>
        <Text variant="headingMd" as="h6">
          {check.description}
        </Text>
        {units && units.length > 0 ? (
          <Text variant="headingMd" as="h6">
            Accepted values: {check.acceptValueMin}-{check.acceptValueMax}
            {units.find((u) => u.EenheidID === check.unitId)?.Eenheid}
          </Text>
        ) : null}
        <ImageDisplay check={check} />
      </div>
    </div>
  );
};

export const TextCheck = ({ check, handleSave, isLoading }) => {
  const [checkValue, setCheckValue] = useState("");
  const [error, setError] = useState(false);

  const handleValueSave = () => {
    if (!checkValue) return setError(true);
    const updatedFields = {
      value: checkValue,
      passed: true,
    };
    handleSave(check, updatedFields);
    setCheckValue("")
  };

  return (
    <div style={{ flexDirection: "column", width: "50%" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Text variant="headingLg" as="h5">
          {check.name}
        </Text>

        <div style={{ width: "500px" }} onKeyDown={(e) => handleSaveOnEnter(e, handleValueSave)}>
          <TextField
            key={check.checkId}
            value={checkValue}
            onChange={(e) => setCheckValue(e)}
            error={error ? "Input is required" : false}
            connectedRight={
              <Button loading={isLoading} onClick={handleValueSave}>
                Submit
              </Button>
            }
          />
        </div>
      </div>

      <div>
        <Text variant="headingMd" as="h6">
          {check.description}
        </Text>
        <ImageDisplay check={check} />
      </div>
    </div>
  );
};

export const PictureCheck = ({ check, handleSave, isLoading }) => {
  const [checkValue, setCheckValue] = useState("[]");
  const [error, setError] = useState(false);

  const handleValueSave = () => {
    const parsedValue = JSON.parse(checkValue);
    if (!parsedValue || parsedValue.length === 0) return setError(true);
    const valueArr = parsedValue.map((p) => {
      return { key: p };
    });
    const updatedFields = {
      value: valueArr,
      passed: true,
    };
    handleSave(check, updatedFields);
    setError(false);
    setCheckValue("[]");
  };

  return (
    <div style={{ flexDirection: "column", width: "50%" }}>
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <Text variant="headingLg" as="h5">
          {check.name}
        </Text>

        <Text variant="headingMd" as="h5">
          {error ? <InlineError message="Add at least one picture" fieldID="" /> : null}
        </Text>

        <div>
          <Button loading={isLoading} onClick={handleValueSave}>
            Submit
          </Button>
        </div>
      </div>
      <div>
        <Text variant="headingMd" as="h6">
          {check.description}
        </Text>
        <ImageDisplay check={check} />
      </div>

      <div style={{ marginTop: "20px" }}>
        <S3ImageUploader
          S3ObjectKeys={checkValue}
          S3ObjectNamePrefix={"picture_check"}
          onUploadImages={(updatedS3ObjectKeys) => {
            setCheckValue(JSON.stringify(updatedS3ObjectKeys));
          }}
          onRemoveImages={(updatedS3ObjectKeys) => {
            setCheckValue(JSON.stringify(updatedS3ObjectKeys));
          }}
        />
      </div>
    </div>
  );
};
