import { Box, Button, ButtonGroup, InlineStack, Text } from "@shopify/polaris";

export const CardHeading = ({ title, actions }) => {
  return (
    <Box paddingBlockEnd="300">
      <InlineStack align="space-between" wrap={false}>
        <Text variant="headingMd" as="h2">
          {title}
        </Text>
        {actions ? (
          <ButtonGroup>
            {actions.map((action, idx) => {
              return (
                <Button icon={action.icon} key={idx} variant={action.variant || "plain"} tone={action.tone || ""} loading={action.loading} disabled={action.disabled} onClick={action.onAction}>
                  {action.content}
                </Button>
              );
            })}
          </ButtonGroup>
        ) : null}
      </InlineStack>
    </Box>
  );
};
