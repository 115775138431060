/*
    @productId: the product id of the product we want to produce
    @qtyToProduce: the quantity of the product we want to produce
    @billOfMaterial: boolean to indicate if we want to have the bill of material or the tree
*/
import { DataTable, Link } from "@shopify/polaris";

export const TreeConsumer = ({ qtyToProduce, bomEntries }) => {
  if (bomEntries.length === 0) return <p>No bill of material found for product to produce.</p>;

  return (
    <DataTable
      verticalAlign="middle"
      increasedTableDensity
      headings={["ID", "Name", "Qty per assembly", "Qty total"]}
      columnContentTypes={["text", "text", "numeric", "numeric"]}
      rows={bomEntries.map((part) => {
        return [
          <Link removeUnderline url={`/products/${part.id}`}>
            #{part.id}
          </Link>,
          part.name,
          part.qty.toString().replace(".", ","),
          (Math.round(part.qty * qtyToProduce * 100) / 100).toString().replace(".", ","),
        ];
      })}
    />
  );
};
