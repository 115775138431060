import { TextField, ChoiceList, RangeSlider } from "@shopify/polaris";
import { useLocation } from "react-router-dom";
import { formatDateFilter } from "../components/functions";
import AsyncSelect from "react-select";

export const limitOptions = [
  { label: "50", value: "50" },
  { label: "100", value: "100" },
  { label: "500", value: "500" },
];

export function useUrl() {
  return new URLSearchParams(useLocation().search);
}

// ADD FILTERS (making them available)
export function addFilters(filters, setFilters) {
  let filterMethods = [];
  filters.forEach((value, key) => {
    if (key !== "q" && key !== "page" && key !== "t") {
      switch (value.type) {
        case "limit":
        case "status":
          filterMethods.push({
            key: key,
            label: value.name,
            shortcut: true,
            filter: <ChoiceList title="Products" titleHidden choices={value.options} selected={value.value || ""} onChange={(e) => handleFilterChange(e, key, value, setFilters, filters)} />,
          });
          break;
        case "date":
          filterMethods.push({
            key: key,
            label: value.name,
            shortcut: true,
            filter: <TextField type="date" labelHidden label="Date" value={value.value} onChange={(e) => handleFilterChange(e, key, value, setFilters)} />,
          });
          break;
        case "range":
          filterMethods.push({
            key: key,
            label: value.name,
            shortcut: true,
            filter: <RangeSlider label="Price" labelHidden value={20 || [0, 500]} prefix="$" output min={0} max={2000} step={1} onChange={() => console.log("slider changed value")} />,
          });
          break;
        case "multi":
        case "multi_id":
          filterMethods.push({
            key: key,
            label: value.name,
            shortcut: true,
            filter: <ChoiceList title={value.name} titleHidden allowMultiple choices={value.options} selected={value.value || []} onChange={(e) => handleFilterChange(e, key, value, setFilters)} />,
          });
          break;
        case "multiselect":
          // let selectValue = filters
          //   .get(key)
          //   .options.filter((o) => filters.get(key).value.includes(o.value));
          filterMethods.push({
            key: key,
            label: value.name,
            shortcut: true,
            filter: (
              <div style={{ width: "300px" }}>
                <AsyncSelect
                  // isMulti
                  name="type"
                  options={value.options}
                  isClearable={true}
                  value={value.value}
                  onChange={(event) => {
                    // if (event.length === 0) {
                    //   removeFilterTag(filter.key, filters.get(filter.key), setFilters)
                    // } else {
                    // console.log(event);
                    const values = event.map((e) => e.value);
                    // const values = event.map((e) => e);
                    handleFilterChange(values, key, filters.get(key), setFilters);
                    // }
                  }}
                  loadOptions={value.loadOptions}
                  menuPortalTarget={document.body}
                  styles={{
                    valueContainer: (base) => ({ ...base, widht: "300px" }),
                    control: (base) => ({ ...base, width: "300px", minWidth: "300px" }),
                    menu: (provided) => ({ ...provided, zIndex: 9999, width: "300px" }),
                    menuPortal: (base) => ({ ...base, zIndex: 9999, width: "300px" }),
                  }}
                />
              </div>
            ),
          });
          break;
        default:
          filterMethods.push({
            key: key,
            label: value.name,
            shortcut: true,
            filter: <TextField label={value.name} value={value.value} onChange={(e) => handleFilterChange(e, key, value, setFilters)} labelHidden />,
          });
      }
    }
  });
  return filterMethods;
}

export const handleFilterChange = (e, key, value, setFilters) => {
  if (value.type === "date") e = formatDateFilter(e);
  if (value.type === "status") e = e[0];
  setFilters((map) => new Map(map.set(key, { ...value, value: e })));
  setFilters((map) => new Map(map.set("page", { ...value, value: 1 })));
};

// APPLY FILTERS (selecting them for the list)
export function applyFilters(filters, setFilters) {
  const appliedFilters = [];
  filters.forEach((value, key) => {
    if (key !== "q" && key !== "limit" && key !== "page" && key !== "t") appliedFilters.push(applyFilter(key, filters, setFilters));
  });
  return appliedFilters.filter((filterItem) => filterItem?.key);
}

function applyFilter(key, filters, setFilters) {
  const filter = filters.get(key);
  if (!isEmpty(filter.value) && filter.type !== "none") {
    return {
      key,
      label: disambiguateLabel(key, filter.type, filter.name, filter.value, filter.options),
      onRemove: () => removeFilter(key, filters, setFilters),
    };
  }
}

// detect if the filter is applied
function isEmpty(value) {
  if (Array.isArray(value)) {
    return value.length === 0;
  } else {
    return value === "" || value == null || value === "all";
  }
}

// create tag when applied
function disambiguateLabel(key, type, name, value, options) {
  let details = [];
  switch (type) {
    case "status":
      if (key === "qcstatus") {
        if (value[0] === "0") return `Status 'QC Passed'`;
        else if (value[0] === "1") return `Status 'QC Failed'`;
        else if (value[0] === "2") return `Status 'QC Resolved'`;
      } else if (key === "stockstatus") {
        return String(value).localeCompare("1") ? `Status 'Stock'` : `Status 'Sold to customer'`;
      } else if (key === "structure") {
        return String(value).toUpperCase();
      } else {
        return String(value).localeCompare("1") ? `Status 'Active'` : `Status 'Discontinued'`;
      }
    case "range":
      return `${name} between ${value[0]} - ${value[1]}`;
    case "date":
      return `${name} ${value}`;
    case "multi":
    case "multi_id":
      details = options.filter((choice) => value?.includes(choice.value));
      return `${name}: ` + details.map((detail) => `${detail.label}`).join(", ");
    // case "multiselect":
    // details = choices.filter((choice) => value.includes(choice.value));
    // return `${name}: ` + details.map((detail) => `${detail.label}`).join(', ');
    default:
      return `${name} contains '${value}'`;
  }
}

// REMOVE APPLIED FILTERS
export const removeAllFilters = (filters, setFilters) => {
  return filters.forEach((value, key) => {
    removeFilter(key, filters, setFilters);
  });
};

export const removeFilter = (key, filters, setFilters) => {
  const filter = filters.get(key);
  const getInitialValue = (type) => {
    switch (type) {
      case "status":
        return "all";
      case "multi":
        return [];
      default:
        return "";
    }
  };
  setFilters(
    (map) =>
      new Map(
        map.set(key, {
          ...filter,
          value: getInitialValue(filter.type),
          tagVisible: false,
        })
      )
  );
  setFilters((map) => new Map(map.set("page", { ...filter, value: 1, tagVisible: false })));
};

export function filtersToApiUrl(filters) {
  let apiString = [];
  filters.forEach((value, key) => {
    switch (value.type) {
      case "hidden":
        return;
      case "multi":
      case "range":
        value.value.length > 0 && apiString.push(`${key}=${JSON.stringify(value.value)}`);
        break;

      case "multi_id":
        let arr = [];
        if (typeof value.value === "string") {
          if (value.value !== "") {
            try {
              arr = JSON.parse(value.value);
            } catch (error) {
              console.error("Invalid JSON string:", error);
            }
          }
        } else {
          arr = value.value;
        }
        if (arr.length > 0) {
          let array = arr.filter((a) => a !== "[" && a !== "]" && a !== ",").map((v) => Number(v));
          apiString.push(`${key}=${JSON.stringify(array)}`);
        }
        break;

      case "page":
        value.value !== "1" && apiString.push(`${key}=${value.value}`);
        break;
      case "status":
      case "qcstatus":
        value.value !== "all" && apiString.push(`${key}=${value.value}`);
        break;
      // case "limit":
      //   value.value !== "100" && apiString.push(`${key}=${value.value}`);
      //   break;
      default:
        value.value !== "" && apiString.push(`${key}=${value.value}`);
    }
  });
  return apiString.filter(Boolean).join("&");
}
