import { useState, useEffect, useCallback } from "react";
import { Page, Layout, Spinner, Card, Icon } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { getRequest, putRequest } from "../../shared/components/functions";
import { useToggle } from "../../shared/hooks/useToggle";
import { Toasts } from "../../shared/components/toasts/toasts";
import ChecksOnListTable from "./checksTable";
import { CheckListInfo } from "./checkListInfo";
import { EditIcon } from "@shopify/polaris-icons";
import { CheckListEdit } from "./checkListEdit";
import { useLookupStore } from "../../utils/useStore";
import { CardHeading } from "../../shared/components/CardHeading";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function CheckListPage() {
  const { id } = useParams();
  const { workstations } = useLookupStore();
  const [checkList, setCheckList] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isEdit, setIsEdit] = useToggle(false);
  const [toast, setToast] = useState(null);
  const [savingResult, setSavingResult] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [checkOptions, setCheckOptions] = useState([]);
  const workstationOptions = workstations.map((d) => {
    return { value: d.id, label: d.Name };
  });

  const fetchCheckList = useCallback(async () => {
    const data = await getRequest(`/api/checklists/${id}`);
    if (data) setCheckList(data[0]);
    setLoaded(true);
  }, []);

  const fetchCheckOptions = useCallback(async () => {
    const data = await getRequest(`/api/checks`);
    if (data)
      setCheckOptions(
        data.map((d) => {
          return { value: d.id, label: d.name };
        })
      );
  }, []);

  useEffect(() => {
    fetchCheckOptions();
    fetchCheckList();
  }, []);

  const handleSave = async () => {
    setSavingResult(true);
    const payload = {
      name: checkList.name,
      description: checkList.description,
      workstationId: checkList.workstationId,
      variantRegex: checkList.variantRegex,
      updatedAt: new Date(),
    };
    const response = await putRequest(`/api/checklists/${id}`, payload, setToast);
    if (response) {
      fetchCheckList();
    }
    setIsEdit(false);
    setSavingResult(false);
  };

  const handleDiscard = () => {
    setIsDirty(false);
    setIsEdit(false);
  };

  if (!loaded) return <PageSpinner />;

  return (
    <Page title={checkList.name} subtitle={`ID: #${id}`} fullWidth>
      <Toasts toast={toast} setToast={setToast} toastContent="Saved successfully" />

      <Layout>
        <Layout.Section>
          <ChecksOnListTable checkListId={id} checkOptions={checkOptions} setToast={setToast} />
        </Layout.Section>

        <Layout.Section variant="oneThird">
          <Card sectioned>
            <CardHeading
              title="Summary"
              actions={
                isEdit
                  ? [
                      { content: "Cancel", onAction: handleDiscard, variant: "primary", tone: "critical", disabled: savingResult },
                      { content: "Save", onAction: handleSave, variant: "primary", loading: savingResult, disabled: !isDirty },
                    ]
                  : [{ icon: EditIcon, onAction: () => setIsEdit(true), variant: "secondary" }]
              }
            />

            {isEdit ? (
              <CheckListEdit checkList={checkList} setCheckList={setCheckList} setIsDirty={setIsDirty} handleDiscard={handleDiscard} handleSave={handleSave} workstationOptions={workstationOptions} />
            ) : (
              <CheckListInfo checkList={checkList} />
            )}
          </Card>
        </Layout.Section>
      </Layout>
    </Page>
  );
}
