import { BlockStack, Card, Divider, Link, Text } from "@shopify/polaris";
import { formatToCurrency } from "../../../shared/components/functions";
import { CardHeading } from "../../../shared/components/CardHeading";

export default function SummaryBox({ sale, loaded }) {
  const getTotalInvoiceValue = (invoices) => {
    return invoices.reduce((total, invoice) => total + invoice.totaalbedrag, 0);
  };

  return (
    loaded && (
      <Card>
        <CardHeading title="Summary" />

        <BlockStack gap="400">
          <BlockStack>
            <CardHeading title="Customer" />
            <Link target="_blank" removeUnderline url={`https://admin.schuilenburg.be/companies/${sale.order.customer.company_id}`}>
              {sale.order.customer.company_name}
            </Link>
          </BlockStack>

          <Divider />

          <BlockStack>
            <CardHeading title="Due date" />
            {sale.order.delivery_term}
          </BlockStack>

          <Divider />

          <BlockStack>
            <CardHeading title="Order owner" />
            {sale.order.owner.user_name}
          </BlockStack>

          <Divider />

          <BlockStack>
            <CardHeading title="Financial" />
            <BlockStack>
              <Text>{`Value (VAT excl): ${formatToCurrency(sale.order.order_value)}`}</Text>
              <Text>{`Invoiced (VAT incl): ${formatToCurrency(sale.invoices.length !== 0 ? getTotalInvoiceValue(sale.invoices) : 0)}`}</Text>
              <Text>{`Invoice open (VAT incl): ${formatToCurrency(sale.invoices.length !== 0 ? sale.order.order_value - getTotalInvoiceValue(sale.invoices) : sale.order.order_value)}`}</Text>
              <Text>{`Invoicee: ${sale.invoices.length !== 0 ? sale.invoices[0].klantnaam : ""}`}</Text>
            </BlockStack>
          </BlockStack>

          <Divider />

          <BlockStack>
            <CardHeading title="Customer reference" />
            {sale.order.reference_customer}
          </BlockStack>
        </BlockStack>
      </Card>
    )
  );
}
