import { Popover, ActionList, Button } from "@shopify/polaris";
import { useToggle } from "../../../shared/hooks/useToggle";
import { useState } from "react";
import { Toasts } from "../../../shared/components/toasts/toasts";

export default function PopoverComponent({ id, delivered, cancelled, reloadOrders }) {
  const [popoverActive, setPopoverActive] = useToggle(false);
  const [toast, setToast] = useState(null);

  const activator = (
    <Button size="slim" onClick={setPopoverActive} disclosure>
      Actions
    </Button>
  );

  async function patchDBField(payload) {
    // await patchRequest(`/api/purchases/${id}`, payload, setToast)
    reloadOrders();
    setPopoverActive();
  }

  const markReceivedContent = delivered ? "Unmark delivered" : "Mark delivered";
  const markCancelledContent = cancelled ? "Unmark cancelled" : "Mark cancelled";

  return (
    <div style={{ marginTop: "-5px", marginBottom: "-5px" }}>
      <Toasts toast={toast} setToast={setToast} toastContent="Changes saved" />
      <Popover active={popoverActive} activator={activator} onClose={setPopoverActive}>
        <ActionList
          items={[
            { content: markReceivedContent, onAction: () => patchDBField({ delivered: !delivered }) },
            { content: markCancelledContent, onAction: () => patchDBField({ canceled: !cancelled }) },
          ]}
        />
      </Popover>
    </div>
  );
}
