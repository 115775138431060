import { useState, useEffect, useCallback } from "react";
import { Badge, BlockStack, Box, Button, ButtonGroup, Card, Divider, Link, Text } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { getRequest, patchRequest, postRequest, formatRoundedWeight } from "../../../shared/components/functions";
import { CardHeading } from "../../../shared/components/CardHeading";

const getEstimatedWeight = (lines) => {
  const weight = lines.reduce((total, line) => (line.Kg !== null ? total + line.Kg * line.aantal : total + 0), 0);
  return Math.round(weight * 100) / 100;
};

const infoPopup = () => {
  alert("Credentails to log in to Sendcloud: \n\nLogin: matthiasadriaens@gmail.com  \nPwd: -LU3-Fu8yC*a@H* \n\nThe login and pwd are automatically copied to your clipboard.");
  var copiedtext = "Login: matthiasadriaens@gmail.com  Pwd: -LU3-Fu8yC*a@H*";
  navigator.clipboard.writeText(copiedtext);
};

export default function ShippingInfoBox({ sale, loaded, getSale, setToast }) {
  let { id } = useParams();

  const [sendcloudOrder, setSendcloudOrder] = useState();
  const [sendcloudDataLoaded, setSendcloudDataLoaded] = useState(false);
  const [sendcloudError, setSendcloudError] = useState(null);

  const getSendcloudOrder = useCallback(async () => {
    const data = await getRequest(`/api/sendcloud/parcel/${sale.order.sendcloud_id}`);
    setSendcloudOrder(data);
    setSendcloudDataLoaded(true);
  }, []);

  const setupSendcloudOrder = async () => {
    //This function creates the order in sendcloud. It does not yet create the shipping label (request_label: false)
    //for testing, add:
    // shipment: {
    //   id: 8,
    //   name: "Unstamped letter",
    // },

    if (confirm("Click on OK to create the order in Sendcloud. \nYou will still be able to edit it before creating the label.")) {
      setSendcloudError(null);
      const parcel_weight = getEstimatedWeight(sale.lines) > 0.1 ? getEstimatedWeight(sale.lines) : 0.1;

      const payload = {
        parcel: {
          name: sale.order.contact.contact_name,
          company_name: sale.order.delivery_address.company_name,
          address: sale.order.delivery_address.company_street_number,
          city: sale.order.delivery_address.company_city,
          postal_code: sale.order.delivery_address.company_zip,
          apply_shipping_rules: true,
          email: sale.order.contact.contact_email,
          telephone: sale.order.contact.contact_gsm,
          data: [],
          country: sale.order.delivery_address.company_country_ISO2,
          weight: parcel_weight,
          order_number: sale.order.order_number,
          insured_value: 0,
          request_label: false,
        },
      };

      const data = await postRequest(`/api/sendcloud/parcel/new`, payload, setToast);

      if (data.parcel) {
        setSendcloudOrder(data);
        setSendcloudId(data.parcel.id);
        getSale();
      } else {
        setSendcloudError(data.error.message);
      }
    }
  };

  async function setSendcloudId(sendcloud_id) {
    await patchRequest(`/api/sales/${id}`, { sendcloud_id: sendcloud_id }, setToast);
    getSale();
  }

  function resetSendcloudID() {
    setSendcloudId("NULL");
    setSendcloudOrder(undefined);
  }

  useEffect(() => {
    if (sale.order.sendcloud_id) {
      getSendcloudOrder();
    } else {
      setSendcloudDataLoaded(true);
    }
  }, []);

  return (
    loaded &&
    sendcloudDataLoaded && (
      <Card>
        <CardHeading title="Shipping" />

        <BlockStack gap="400">
          {sale.order.contact.contact_id !== null && (
            <>
              <BlockStack>
                <CardHeading title="Contact" />
                <BlockStack>
                  <Link target="_blank" removeUnderline url={`https://admin.schuilenburg.be/contacts/${sale.order.contact.contact_id}`}>
                    {sale.order.contact.contact_name}
                  </Link>
                  <Text>{sale.order.contact.contact_gsm}</Text>
                  <Text>{sale.order.contact.contact_email}</Text>
                </BlockStack>
              </BlockStack>
              <Divider />
            </>
          )}

          <BlockStack>
            <CardHeading title="Shipping address" />
            {sale.order.delivery_address !== 0 && (
              <BlockStack>
                <Link target="_blank" removeUnderline url={`https://admin.schuilenburg.be/companies/${sale.order.customer.company_id}`}>
                  {sale.order.delivery_address.company_name}
                </Link>
                <Text>{sale.order.delivery_address.company_street_number}</Text>
                {sale.order.delivery_address.company_zip && sale.order.delivery_address.company_city && (
                  <Text>{`${sale.order.delivery_address.company_zip} ${sale.order.delivery_address.company_city}`}</Text>
                )}
                <Text>{sale.order.delivery_address.company_country}</Text>
              </BlockStack>
            )}
          </BlockStack>

          <Divider />

          <BlockStack>
            <CardHeading title="Weight" />
            {sale.lines !== 0 && `Estimated weight: ${formatRoundedWeight(getEstimatedWeight(sale.lines))}`}
          </BlockStack>

          <Divider />

          <BlockStack>
            <CardHeading title="Shipping via Sendcloud" actions={[{ content: "Info", onAction: infoPopup, variant: 'secondary' }]} />

            {sendcloudOrder && sendcloudOrder.error ? (
              <BlockStack gap="200">
                <Text variant="headingSm" as="h5" color="critical">
                  {sendcloudOrder.error.message}
                </Text>
                <Box>
                  <Button variant="primary" tone="critical" onClick={resetSendcloudID}>
                    Reset Sendcloud ID
                  </Button>
                </Box>
              </BlockStack>
            ) : sale.order.sendcloud_id !== null && sendcloudOrder ? (
              <BlockStack gap="200">
                <Text>{`Order is shipped by Sendcloud ${sendcloudOrder.parcel?.hasOwnProperty("carrier") ? `with ${sendcloudOrder.parcel?.carrier.code} as carrier. ` : ""}`}</Text>
                <Text>
                  Status: <Badge tone="attention">{sendcloudOrder.parcel?.status.message}</Badge>
                </Text>
                <Text>{`Tracking number: ${sendcloudOrder.parcel?.tracking_number || "not available yet"}`}</Text>
                <div style={{ height: "10px" }}></div>
                <ButtonGroup>
                  <Button variant="primary" tone="critical" onClick={resetSendcloudID}>
                    Reset Sendcloud ID
                  </Button>
                  <Button
                    external
                    url={`https://perfectmoose.shipping-portal.com/tracking/?country=${String(sale.order.delivery_address.company_country_ISO2).toLocaleLowerCase()}&tracking_number=${
                      sendcloudOrder.parcel?.tracking_number
                    }&postal_code=${sale.order.delivery_address.company_zip}`}>
                    Open order in Track and Trace
                  </Button>
                  <Button variant="primary" external url={`https://app.sendcloud.com/v2/shipping/${sendcloudOrder.parcel?.external_order_id}/details`}>
                    Open order in Sendcloud
                  </Button>
                </ButtonGroup>
              </BlockStack>
            ) : (
              <BlockStack gap="200">
                <Text>Create the order in Sendcloud and then make a label for it:</Text>
                {sendcloudError ||
                  (sendcloudOrder?.error && (
                    <Text variant="headingSm" as="h5" tone="critical">
                      Error: {sendcloudError || sendcloudOrder?.error.message}
                    </Text>
                  ))}
                <Box>
                  <Button onClick={setupSendcloudOrder} variant="primary">
                    Shoot to Sendcloud
                  </Button>
                </Box>
              </BlockStack>
            )}
          </BlockStack>
        </BlockStack>
      </Card>
    )
  );
}
