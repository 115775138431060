import { useLocation, useNavigate } from "react-router-dom";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../../shared/filters/filterfunctions";
import { useCallback, useEffect, useState } from "react";
import { Filters, Card, DataTable, Link, Spinner, Button } from "@shopify/polaris";
import { formatDate, getRequest, getTableFooter, truncate } from "../../../shared/components/functions";
import { AddChecklistModal } from "./addChecklistModal";
import { useLookupStore } from "../../../utils/useStore";
import { PageSpinner } from "../../../shared/components/spinner/PageSpinner";

export const ChecksPage = () => {
  const url = useUrl();
  const location = useLocation();
  const units = useLookupStore((state) => state.units);
  const navigate = useNavigate();
  const [modalActive, setModalActive] = useState(false);
  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
    ])
  );

  const unitOptions = units.map((d) => {
    return { value: d.EenheidID.toString(), label: d.Eenheid };
  });

  const fetchItems = useCallback(async () => {
    let filterString = filtersToApiUrl(filters);
    const data = await getRequest(`/api/checks?${filterString}`);
    setItems(data);
    if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    setLoaded(true);
  }, [filters]);

  useEffect(() => {
    fetchItems();
  }, [filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        return [
          <Link removeUnderline url={`/checks/${item.id}`}>
            {truncate(item.name, 20, false)}
          </Link>,
          truncate(item.description, 20, false),
          item.type?.charAt(0).toUpperCase() + item.type.slice(1),
          item.unitId ? unitOptions?.find((u) => item.unitId.toString() === u.value)?.label : "",
          item.acceptValueMin,
          item.acceptValueMax,
          item.pictureJson !== "null" ? "✔️" : "",
          item.Afkorting,
          formatDate(item.createdAt),
          formatDate(item.updatedAt),
        ];
      })
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Card>
      <AddChecklistModal active={modalActive} setActive={setModalActive} fetchList={fetchItems} checkList={false} unitOptions={unitOptions} />
      <Filters
        queryValue={filters.get("q").value}
        filters={addFilters(filters, setFilters)}
        appliedFilters={applyFilters(filters, setFilters)}
        onClearAll={() => removeAllFilters(filters, setFilters)}
        onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
        onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
        autoComplete={false}
        queryPlaceholder="Filter checks">
        <Button variant="primary" onClick={() => setModalActive(true)}>
          Add check
        </Button>
      </Filters>

      <DataTable
        verticalAlign="middle"
        columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text", "text"]}
        headings={[<b>Name</b>, <b>Description</b>, <b>Type</b>, <b>Unit</b>, <b>Min value</b>, <b>Max value</b>, <b>Pictures</b>, <b>Created by</b>, <b>Created at</b>, <b>Updated at</b>]}
        rows={rows}
        footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "checks")}
      />
    </Card>
  );
};
