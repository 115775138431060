import { Card, DropZone, Spinner } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { postRequest, uploadFormDataToS3 } from "../functions";
import { createPortal } from "react-dom";
import { CardHeading } from "../CardHeading";

/*
    This component can be used to upload images to S3 and display them
    ! syncing state with db needs to be done with callbacks onUploadImages and onRemoveImages
*/
export function S3ImageUploader({ S3ObjectKeys, onUploadImages, onRemoveImages, S3ObjectNamePrefix, customActionTitle, handleCustomAction, hasCustomRemoveAction }) {
  const AwsS3OjbectKeys = JSON.parse(S3ObjectKeys === "null" ? "[]" : S3ObjectKeys);
  const [syncingS3, setSyncingS3] = useState(false);

  const handleDropZoneDrop = async (_dropFiles, acceptedFiles, _rejectedFiles) => {
    setSyncingS3(true);
    const formData = new FormData();

    acceptedFiles = acceptedFiles.filter((file) => file.type.includes("image"));
    if (acceptedFiles.length !== _dropFiles.length) {
      setSyncingS3(false);
      return alert("Only image files are allowed.");
    }

    const uploadedKeys = [];

    acceptedFiles.forEach((file) => {
      const randomString = Math.random().toString(36).substring(2, 15);
      const newFileName = `${S3ObjectNamePrefix}_${new Date().getTime().toString()}_${randomString}`;
      formData.append("file", new File([file], newFileName, { type: file.type }));
      uploadedKeys.push(newFileName);
    });

    const response = await uploadFormDataToS3(formData);
    if (!response.ok) return alert("Something went wrong uploading the images to S3");

    const updatedKeys = [...(AwsS3OjbectKeys || []), ...uploadedKeys];
    await onUploadImages(updatedKeys);
    setSyncingS3(false);
  };

  return (
    <Card>
      <CardHeading title="Images" />
      {syncingS3 && (
        <div>
          <Spinner size="small" /> Syncing images...
        </div>
      )}
      <DropZone onDrop={handleDropZoneDrop}>
        <DropZone.FileUpload />
      </DropZone>
      <S3ImageViewer
        S3ObjectKeys={S3ObjectKeys}
        onRemoveImages={onRemoveImages}
        viewOnly={false}
        thumbNails={false}
        displayDirection="vertical"
        customActionTitle={customActionTitle}
        handleCustomAction={handleCustomAction}
        hasCustomRemoveAction={hasCustomRemoveAction}
      />
    </Card>
  );
}

/**

        Renders a component for displaying a list of images with options to remove them.
        @param {Array<Object>} S3ObjectUrls - An array of objects with keys: url, key.
        @param {Function} onRemoveImages - A callback function to remove images from the database.
        @param {boolean} viewOnly - A boolean value indicating whether the delete button should be disabled.
        @param {boolean} thumbNails - A boolean value indicating whether to display images as thumbnails.
        @param {string} displayDirection - A string value indicating the direction to display images, either 'horizontal' or 'vertical'.
        @returns {Component} - The rendered component for displaying the list of images.
*/
export const S3ImageViewer = ({ S3ObjectKeys, onRemoveImages, viewOnly, thumbNails, displayDirection, customActionTitle, handleCustomAction, hasCustomRemoveAction }) => {
  const AwsS3OjbectKeys = JSON.parse(S3ObjectKeys === "null" ? "[]" : S3ObjectKeys);

  const [isHovered, setIsHovered] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [S3ObjectUrls, setS3ObjectUrls] = useState([]);

  const handleRemove = async (S3Object) => {
    const confirmed = window.confirm("Are you sure you want to delete this image?");
    if (!confirmed) return;

    const S3ObjectKey = S3Object.key;

    // make api call to remove the key from the database
    let keysToRemove = [S3ObjectKey];
    await postRequest(`/api/aws/s3/delete`, { keys: keysToRemove });

    const updatedKeys = S3ObjectUrls.filter((item) => item.key !== S3ObjectKey).map((item) => item.key);
    onRemoveImages(updatedKeys);
  };

  const openModal = (index) => {
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (!modalIsOpen || !S3ObjectUrls || S3ObjectUrls.length < 1) return;
      if (event.keyCode === 27) {
        closeModal();
      } else if (event.keyCode === 37) {
        // left arrow key
        setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : S3ObjectUrls.length - 1));
      } else if (event.keyCode === 39) {
        // right arrow key
        setCurrentIndex((prevIndex) => (prevIndex < S3ObjectUrls.length - 1 ? prevIndex + 1 : 0));
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [modalIsOpen, S3ObjectUrls]);

  useEffect(() => {
    async function fetchS3ObjectUrls() {
      if (!AwsS3OjbectKeys) return setS3ObjectUrls([]);
      if (AwsS3OjbectKeys.length === 0) return setS3ObjectUrls([]);
      const data = await postRequest(`/api/aws/s3/download`, {
        keys: AwsS3OjbectKeys,
      });
      setS3ObjectUrls(data);
    }
    fetchS3ObjectUrls();
  }, [S3ObjectKeys]);

  const handleClickOutside = (event) => {
    if (event.target.nodeName !== "IMG") closeModal();
  };

  const pictureStyle = thumbNails ? { width: "auto", height: "150px" } : { width: "100%", height: "auto" };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: displayDirection === "horizontal" ? "row" : "column",
          flexWrap: "wrap",
        }}>
        {S3ObjectUrls?.map((item, index) => (
          <div
            key={index}
            style={{
              position: "relative",
              ...pictureStyle,
              marginTop: "10px",
              marginRight: displayDirection === "horizontal" ? "10px" : "0", // Add right margin for horizontal layout
              marginBottom: displayDirection === "vertical" ? "10px" : "0", // Add bottom margin for vertical layout
            }}
            onMouseEnter={() => setIsHovered(index)}
            onMouseLeave={() => setIsHovered(null)}
            onClick={() => openModal(index)}>
            <img src={item.url} alt="product" style={pictureStyle} />
            {isHovered === index && (
              <div
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  background: "rgba(0, 0, 0, 0.5)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "20px",
                  flexDirection: "column",
                  cursor: "zoom-in",
                  color: "white",
                  padding: "10px",
                }}>
                {!viewOnly && (
                  <>
                    {handleCustomAction ? (
                      <button
                        style={{
                          fontSize: "2em",
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          color: "white",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCustomAction(item);
                        }}>
                        ⭐ {customActionTitle} ⭐
                      </button>
                    ) : null}
                    <button
                      style={{
                        fontSize: "2em",
                        background: "transparent",
                        border: "none",
                        cursor: "pointer",
                        color: "white",
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (hasCustomRemoveAction) {
                          onRemoveImages(item);
                        } else {
                          handleRemove(item);
                        }
                      }}>
                      ❌ delete ❌
                    </button>
                  </>
                )}
              </div>
            )}
          </div>
        ))}
      </div>
      {modalIsOpen &&
        createPortal(
          <div
            onClick={handleClickOutside}
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: "rgba(0, 0, 0, 0.7)",
              zIndex: 9999,
            }}>
            <img
              src={S3ObjectUrls[currentIndex].url}
              alt="modal"
              style={{
                height: "100vh",
              }}
            />
          </div>,
          document.body
        )}
    </>
  );
};
