import { jsPDF } from "jspdf";
import qrcode from "qrcode-generator";

export const printAllQCLabels = (qcEntries, orderNumber) => {
  const doc = new jsPDF({
    orientation: "p",
    unit: "mm",
    format: "a6",
  });

  for (let i = 0; i < qcEntries.length; i++) {
    const label = {
      qcId: qcEntries[i].ID,
      orderNumber: orderNumber,
      supplier: qcEntries[i].stockchange.product.supplier.name,
      partId: qcEntries[i].stockchange.product.id,
      partName: qcEntries[i].stockchange.product.name,
      quantity: qcEntries[i].stockchange.quantity,
      qcDate: qcEntries[i].Timestamp.substring(0, 10),
      status: qcEntries[i].QualityPassed
        ? "OK"
        : `× FAILED: ${qcEntries[i].classification_type.classification} `,
    };
    getQCLabel(doc, label);
    if (i < qcEntries.length - 1) {
      doc.addPage();
    }
  }
  doc.save(`qc_labels_order_${orderNumber}.pdf`);
};

export const printQCLabel = (label) => {
  const doc = new jsPDF({
    orientation: "p",
    unit: "mm",
    format: "a6",
  });
  getQCLabel(doc, label);
  doc.save(`qc_${label.qcId}.pdf`);
};

export const getQCLabel = (doc, label) => {
  doc
    .setFontSize(12)
    .setFont("helvetica", "bold")
    .text("Purchase order number:", 5, 10)
    .setFont("helvetica", "normal")
    .text(`${label.orderNumber}`, 5, 16)

    .setFont("helvetica", "bold")
    .text("QC date:", 70, 10)
    .setFont("helvetica", "normal")
    .text(`${label.qcDate}`, 70, 16)

    .setFont("helvetica", "bold")
    .text("Supplier:", 5, 25)
    .setFont("helvetica", "normal")
    .text(`${label.supplier}`, 5, 31)

    .setFont("helvetica", "bold")
    .text("QC result:", 5, 40)
    .setFont("helvetica", "normal")
    .text(`${label.status}`, 5, 46)

    .setFont("helvetica", "bold")
    .text("Quantity:", 5, 55)
    .setFont("helvetica", "normal")
    .text(`${label.quantity}`, 5, 61)

    .setFont("helvetica", "bold")
    .text("Part ID:", 5, 70)
    .setFont("helvetica", "normal")
    .text(`${label.partId}`, 5, 76)

    .setFont("helvetica", "bold")
    .text("Part:", 5, 85)
    .setFont("helvetica", "normal")
    .text(`${label.partName}`, 5, 91, { maxWidth: 95 });

  var typeNumber = 0;
  var errorCorrectionLevel = "L";
  var qr = qrcode(typeNumber, errorCorrectionLevel);
  qr.addData(`https://production.perfectmoose.com/qc/${label.qcId}`);
  qr.make();

  var qrCodeImageData = qr.createDataURL(10, 10);

  doc.addImage(qrCodeImageData, "PNG", 5, 103, 40, 40, '', 'FAST');

  return doc;
};
