import { Page, Tabs } from "@shopify/polaris";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckListsPage } from "./components/checklists";
import { ChecksPage } from "./components/checks";

export default function CheckOverviewPage() {
  const navigate = useNavigate();
  const [selected, setSelected] = useState(window.location.pathname === "/checkoverview/checks" ? 1 : 0);

  const handleTabChange = useCallback(
    (selectedTabIndex) => {
      setSelected(selectedTabIndex);
      navigate({ pathname: `/checkoverview/${tabs[selectedTabIndex].id}` }, { replace: true });
    },
    [navigate]
  );

  const tabs = [
    { id: "checklists", content: "Checklists", component: <CheckListsPage /> },
    { id: "checks", content: "Checks", component: <ChecksPage /> },
  ];

  return (
    <Page title="Checks and checklists" fullWidth>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        {tabs[selected].component}
      </Tabs>
    </Page>
  );
}
