import { Button, Modal, FormLayout, TextField, Text, ButtonGroup, InlineStack, BlockStack } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { getRequest, putRequest } from "../../../shared/components/functions";
import { Toasts } from "../../../shared/components/toasts/toasts";
import { S3ImageUploader } from "../../../shared/components/s3_image_display/s3ImageUploader";
import { FeedbackHistory } from "./feedbackHistory";
import { useAuthStore } from "../../../utils/useStore";

export const WorkstationFeedbackModal = ({ id, workstation, workstations, users }) => {
  const user = useAuthStore();
  const [active, setActive] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [saving, setSaving] = useState(false);
  const [toast, setToast] = useState(null);
  const [serial, setSerial] = useState({});
  const [interactions, setInteractions] = useState();
  const [feedback, setFeedback] = useState([]);
  const [comment, setComment] = useState("");
  const [files, setFiles] = useState("null");

  const fetchSerial = useCallback(async () => {
    const data = await getRequest(`/api/serials/${id}`);
    if (data) {
      setSerial(data);
      const operatorInteractions = JSON.parse(data.OperatorInteractions);
      if (operatorInteractions) {
        setInteractions(operatorInteractions);
        const items = operatorInteractions.feedback.sort((a, b) => (a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0));
        setFeedback(items);
      }
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    fetchSerial();
  }, []);

  const handleSave = async () => {
    setSaving(true);
    const filesArr = files !== "null" ? JSON.parse(files) : [];
    const newFeedback = {
      WorkStationId: workstation,
      timestamp: Date.now(),
      GebruikerID: user.user.id,
      text: comment,
      pictures: filesArr.map((f) => {
        return { key: f };
      }),
    };

    const feedbackArr = [...feedback, newFeedback];
    const operatorInteractions = { ...interactions, feedback: feedbackArr };
    const interactionsString = JSON.stringify(operatorInteractions);
    const payload = { ...serial, OperatorInteractions: interactionsString };

    const result = await putRequest(`/api/serials/${id}`, payload, setToast);
    if ((result[1] = 1)) {
      fetchSerial();
      setComment("");
      setFiles("null");
      setActive(false);
    }
    setSaving(false);
  };

  const activator = (
    <Button variant="primary" onClick={() => setActive(true)} size="large">
      <Text variant="headingLg" as="h4">
        Feedback
      </Text>
    </Button>
  );

  return (
    <>
      <Toasts toast={toast} setToast={setToast} toastContent="Feedback saved" />
      <Modal size="large" fullScreen activator={activator} open={active} onClose={() => setActive(false)} title="Feedback">
        <Modal.Section>
          <BlockStack gap="400">
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField labelHidden label="Comment" value={comment} onChange={(e) => setComment(e)} multiline={7} autoComplete="off" placeholder={"✏️"} />
                <S3ImageUploader
                  S3ObjectKeys={files}
                  S3ObjectNamePrefix="feedback_images"
                  onUploadImages={async (updatedS3ObjectKeys) => setFiles(JSON.stringify(updatedS3ObjectKeys))}
                  onRemoveImages={async (updatedS3ObjectKeys) => setFiles(JSON.stringify(updatedS3ObjectKeys))}
                />
              </FormLayout.Group>
            </FormLayout>

            <InlineStack gap="200" align="end">
              <ButtonGroup>
                <Button onClick={() => setActive(false)}>Close</Button>
                <Button variant="primary" type="submit" onClick={handleSave} loading={saving}>
                  Save
                </Button>
              </ButtonGroup>
            </InlineStack>
          </BlockStack>
        </Modal.Section>

        {loaded && feedback && feedback.length > 0 ? (
          <Modal.Section>
            <FeedbackHistory feedback={feedback} setFeedback={setFeedback} loaded={loaded} workstations={workstations} users={users} />
          </Modal.Section>
        ) : null}
      </Modal>
    </>
  );
};
