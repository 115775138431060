import { useState, useEffect } from "react";
import { Card } from "@shopify/polaris";
import { formatDate, getRequest } from "../../../shared/components/functions";

export default function FulfilmentCalendar() {
  const [items, setItems] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/fulfilment/calendar`);
      setItems(data);
    }
    fetchData();
  }, []);

  const rows = items.map((item) => {
    return [formatDate(item.due_date), item.schuil_part_id, item.aha_part_id, item.aha_product_name, item.ordered, item.fulfilled, item.to_fulfill];
  });

  return (
    <Card>
      WIP
      {/* <DataTable
        columnContentTypes={[
          "text",
          "text",
          "text",
          "text",
          "numeric",
          "numeric",
          "numeric",
        ]}
        headings={[
          <b>{"Date"}</b>,
          <b>{"Code Schuilenburg"}</b>,
          <b>{"Code Aha"}</b>,
          <b>{"Name"}</b>,
          <b>{"Ordered"}</b>,
          <b>{"Fulfilled"}</b>,
          <b>{"To Fulfilled"}</b>,
        ]}
        rows={rows}
      /> */}
    </Card>
  );
}
