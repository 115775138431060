import { Text } from "@shopify/polaris";
import { useEffect, useState } from "react";

export const Timer = ({ timestamp }) => {
  if (!timestamp) {
    return null;
  }

  const [timeDiff, setTimeDiff] = useState();

  useEffect(() => {
    const interval = setInterval(() => {
      const currentTimeStamp = Date.now();
      const currentSeconds = Math.floor(currentTimeStamp / 1000);
      setTimeDiff(currentSeconds - timestamp);
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Text variant="headingLg" as="h5">{`
    ${formatTime(Math.floor(timeDiff / 60 / 60), "h") || '00:'}${formatTime(Math.floor(timeDiff / 60)) || '00'}:${formatTime(timeDiff) || '00'}`}</Text>
  );
};

const formatTime = (time, h) => {
  let formatted;
  if (h) {
    formatted = time > 0 ? `${time < 10 ? `0${time}` : time}:` : null;
  } else {
    const remainder = time % 60;
    formatted = remainder < 10 ? `0${remainder}` : remainder;
  }
  return formatted;
};
