import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfigTableRow from "./components/designerTableRow";
import PartModal from "./components/partModal";
import NewVersionModal from "./components/newVersionModal";
import { Box, Button, ButtonGroup, InlineStack, Spinner, Text } from "@shopify/polaris";
import { deleteRequest, getRequest, getRequestWithSignal, postRequest } from "../../shared/components/typedFunctions";
import { configurator, getDecodedMachineVersion } from "./utils/modelConfig";
import { XIcon } from "@shopify/polaris-icons";
import CheckModal from "./components/checkModal";
import ManualModal from "./components/manualModal";

export type GregVersionValue = "PMG20_FI01_1" | "PMG20_FI01_2" | "PMG20_FI02_1" | "PMG20_FI02_2"
  | "PMG20_LI01_1" | "PMG20_LI01_2" | "PMG20_LI02_1" | "PMG20_LI02_2"
  | "PMG20_LF01_1" | "PMG20_LF02_1" | "PMG20_LF01_2" | "PMG20_LF02_2"
  | "PMG20_LJ01_1" | "PMG20_LJ02_1" | "PMG20_LJ01_2" | "PMG20_LJ02_2"
  | "PMG20_LG01_1" | "PMG20_LG02_1" | "PMG20_LG01_2" | "PMG20_LG02_2"
  | "PMG20_LM01_1" | "PMG20_LM02_1";
export type JackVersionValue = "PMJ20_FI01_2";
export type VersionValue = GregVersionValue | JackVersionValue
export type Version = { version: GregVersionValue | JackVersionValue }

export type VersionRecord = {
  ID: number,
  Parent: number,
  Lft: number,
  Rgt: number,
  Quantity: number,
  NodeNumber: number,
  PartId: number,
  [key: string]: any
}

export default function ProductDesigner() {
  const product = window.location.pathname === ('/designer/jack') ? 'jack' : 'greg'
  const navigate = useNavigate();
  const [versions, setVersions] = useState<Version[]>([]);
  const [data, setData] = useState([]);
  const [parts, setParts] = useState<VersionRecord[]>([]);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [checkModalOpen, setCheckModalOpen] = useState<boolean>(false);
  const [manualChangeModalOpen, setManualChangeModalOpen] = useState<boolean>(false)
  const [selectedPart, setSelectedPart] = useState<VersionRecord>({} as VersionRecord);
  const [isEditModal, setIsEditModal] = useState<boolean>(false)
  const [hoveredVersion, setHoveredVersion] = useState<VersionValue>(product === 'greg' ? "PMG20_FI01_1" : "PMJ20_FI01_2");
  const [loaded, setLoaded] = useState<boolean>(false)
  const [forcastedQty, setForcastedQty] = useState<number>(300)
  const [changelogOn, setChangelogOn] = useState<boolean>(true)
  const [isDeleteModal, setIsDeleteModal] = useState<boolean>(false)
  const [log, setLog] = useState({ version: '', idToToggle: '', product, part: '', parentRowId: '', exists: true })

  const fetchData = async (abortControllerSignal: AbortSignal) => {
    setVersions(await getRequestWithSignal(`/api/${product}/versions`, abortControllerSignal))
    setData(await getRequestWithSignal(`/api/${product}`, abortControllerSignal))
    setParts(await getRequestWithSignal(`/api/${product}/parts`, abortControllerSignal))
    setLoaded(true)
    setHoveredVersion(product === 'greg' ? "PMG20_FI01_1" : "PMJ20_FI01_2")
  }

  async function getSimpleVersions() { setVersions(await getRequest(`/api/${product}/versions`)) }
  async function getSimpleData() { setData(await getRequest(`/api/${product}`)) }
  async function fetchSimpleData() {
    getSimpleData()
    getSimpleVersions()
    setParts(await getRequest(`/api/${product}/parts`))
    setHoveredVersion(product === 'greg' ? "PMG20_FI01_1" : "PMJ20_FI01_2")
  }

  const toggleCell = async (version: string, id: number, part: number, parent: number, exists: boolean) => {
    if (changelogOn) {
      setLog({ ...log, product, version, idToToggle: String(id), part: String(part), parentRowId: String(parent), exists })
      return setCheckModalOpen(true)
    }
    await getRequest(`/api/${product}/toggle/${version}/${id}`);
    getSimpleData();
  };

  useEffect(() => {
    setLoaded(false)
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => { await fetchData(abortController.signal) }
    fetchDataWithAbort();
    return () => abortController.abort(); // Cleanup, abort controller when greg/jack change
  }, [product]);

  const removeVersion = async (version: string) => {
    if (window.confirm(`You are going to remove version ${version} from ${product}. Are you sure? You can not revert this!`)) {
      await deleteRequest(`/api/${product}/versions/${version}`);
      getSimpleData();
      getSimpleVersions();
    } else {
      console.log("Decided not to remove though...");
    }
  };

  const addVersion = async (version: string) => {
    await postRequest(`/api/${product}/versions/${version}`, {});
    getSimpleData();
    getSimpleVersions();
  };

  const openModal = (part: VersionRecord, edit: boolean, isDelete: boolean) => {
    setSelectedPart(part);
    setModalOpen(true);
    setIsEditModal(edit)
    setIsDeleteModal(isDelete)
  };

  const hideModal = () => {
    setModalOpen(false);
    setCheckModalOpen(false);
    setManualChangeModalOpen(false)
    getSimpleData();
  };

  if (!loaded) return <Box padding='400'><Spinner size='small' /></Box>

  return (
    <div>
      <Box paddingBlockStart='400' paddingInlineStart='400'>
        <InlineStack gap='400'>
          <NewVersionModal existing_versions={versions} addVersion={addVersion} product={product} />
          <Button onClick={() => navigate(`/changelog?structure=${product.toLowerCase()}`)}>Go to changelog</Button>
          <Button onClick={() => setManualChangeModalOpen(true)}>Add manual changelog</Button>
          <ButtonGroup variant="segmented">
            <Button pressed={changelogOn} onClick={() => setChangelogOn(true)}>
              Changelog on
            </Button>
            <Button pressed={!changelogOn} onClick={() => { if (window.confirm('By turning the changelog off, changes to the variants will not be documented in the changelog!')) return setChangelogOn(false) }}>
              Changelog off
            </Button>
          </ButtonGroup>
        </InlineStack>
      </Box>

      {modalOpen && <PartModal modalOpen={modalOpen} part={selectedPart} hideModal={hideModal} fetchData={fetchSimpleData} product={product} isEdit={isEditModal} isDelete={isDeleteModal} />}
      {checkModalOpen && <CheckModal modalOpen={checkModalOpen} hideModal={hideModal} fetchData={fetchSimpleData} data={log} />}
      {manualChangeModalOpen && <ManualModal modalOpen={manualChangeModalOpen} hideModal={hideModal} product={product} />}

      <Box width="500px" paddingBlockStart='400' paddingInlineStart='400'>
        <Text variant="headingSm" as="h6">Forcasted qty for procurement calculations</Text>
        <input value={forcastedQty} onChange={(e) => setForcastedQty(parseInt(e.target.value))} />
      </Box>

      <table className="customTable">
        <thead>
          <tr>
            <th colSpan={3} className="nothing">
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="" style={{ maxWidth: '300px', whiteSpace: "pre-wrap", alignContent: "left", alignItems: "left" }}>
                  {getDecodedMachineVersion(hoveredVersion + "")}
                </div>
              </div>
            </th>

            {versions.map((version) => {
              const kleur = configurator.get("cable_type")?.get(version.version.substring(7, 8))
                // @ts-ignore
                ? configurator?.get("cable_type")?.get(version?.version?.substring(7, 8))?.color
                : "white";
              return (
                <th className="vertical" key={version.version} style={{ position: "sticky", backgroundColor: kleur }} onMouseEnter={() => setHoveredVersion(version.version)}>
                  <div className="vertical" style={{ height: '180px' }}>
                    <InlineStack align='space-between' blockAlign="center">
                      {version.version}
                      <Button onClick={() => removeVersion(version.version)} size="micro" icon={XIcon} />
                    </InlineStack>
                  </div>
                </th>
              );
            })}
            <th className="vertical" style={{ position: "sticky", backgroundColor: "lightgray" }}>
              <div className="vertical" style={{ height: '180px' }}>
                Stock Warheouse
              </div>
            </th>
            <th className="vertical" style={{ position: "sticky", backgroundColor: "lightgray" }}>
              <div className="vertical" style={{ height: '180px' }}>
                Stock Trianval
              </div>
            </th>
            <th className="vertical" style={{ position: "sticky", backgroundColor: "lightgray" }}>
              <div className="vertical" style={{ height: '180px' }}>
                Stock used in parent assemblies
              </div>
            </th>
            <th className="vertical" style={{ position: "sticky", backgroundColor: "lightgreen" }}>
              <div className="vertical" style={{ height: '180px' }}>
                Stock Total
              </div>
            </th>
            <th className="vertical" style={{ position: "sticky", backgroundColor: "lightgray" }}>
              <div className="vertical" style={{ height: '180px' }}>
                Total qty in tree raw material
              </div>
            </th>
            <th className="vertical" style={{ position: "sticky", backgroundColor: "lightgray" }}>
              <div className="vertical" style={{ height: '180px' }}>
                Needed for forcast
              </div>
            </th>
            <th className="vertical" style={{ position: "sticky", backgroundColor: "lightgray" }}>
              <div className="vertical" style={{ height: '180px' }}>
                To buy raw material
              </div>
            </th>
          </tr>

          <tr>
            <th className="no-vertical">ID</th>
            <th className="no-vertical">Part name</th>
            <th className="no-vertical">#</th>
            {versions.map((version) => <th className="no-vertical" key={`version_checkboxk_${version.version}`}><input type="checkbox" /></th>)}
          </tr>
        </thead>

        <tbody>
          {Array.isArray(data) && data.length > 0 && data.map((row, idx) => (
            <ConfigTableRow
              key={idx}
              openModal={openModal}
              row_data={row}
              versions={versions}
              parts={parts}
              setHoverText={(text: VersionValue) => setHoveredVersion(text)}
              toggleCell={toggleCell}
              forcastedQty={forcastedQty}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
}