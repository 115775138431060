import { Toast } from "@shopify/polaris";

export const Toasts = ({ toast, setToast, toastContent, errorToastContent }) => {
  if (!toast) return;
  return toast === "ok" ? (
    <Toast content={toastContent || "Saved successfully"} onDismiss={() => setToast(null)} />
  ) : toast === "error" ? (
    <Toast content={errorToastContent || "Server error"} error onDismiss={() => setToast(null)} />
  ) : null;
};
