import { Card, Button, InlineStack } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { BooleanCheck, PictureCheck, TextCheck, ValueCheck } from "./checkComponents";
import { Toasts } from "../../../shared/components/toasts/toasts";
import { putRequest } from "../../../shared/components/functions";
import { useAuthStore } from "../../../utils/useStore";

export const ChecksCard = ({ workstationId, checks, setChecksActive, serial, fetchRFIDStations }) => {
  const openChecks = checks.filter((c) => c.passed === null);
  const user = useAuthStore();
  const [toast, setToast] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!openChecks || openChecks.length === 0) setChecksActive(false);
  }, [openChecks]);

  const handleSave = async (check, updatedFields) => {
    setIsLoading(true);
    let serialChecks = JSON.parse(serial.Checks);
    serialChecks[workstationId].checks = serialChecks[workstationId].checks.map((obj) => {
      if (obj.checkListId === check.checkListId && obj.checkId === check.checkId) {
        obj = Object.assign(obj, updatedFields);
        obj.checkedAt = Date.now();
        obj.userId = user.user.id;
      }
      return obj;
    });
    let payload = { ...serial, Checks: JSON.stringify(serialChecks) };
    await putRequest(`/api/serials/${serial.serialnumber}`, payload, setToast);
    fetchRFIDStations();
    setIsLoading(false);
  };

  const componentCollection = openChecks.map((c, idx) => {
    if (c.type === "value") return <ValueCheck key={idx} check={c} isLoading={isLoading} handleSave={handleSave} />;
    if (c.type === "text") return <TextCheck key={idx} check={c} isLoading={isLoading} handleSave={handleSave} />;
    if (c.type === "picture") return <PictureCheck key={idx} check={c} isLoading={isLoading} handleSave={handleSave} />;
    if (c.type === "boolean") return <BooleanCheck key={idx} check={c} isLoading={isLoading} handleSave={handleSave} />;
  });

  return (
    <>
      <Toasts toast={toast} setToast={setToast} />
      <Card sectioned>
        <div style={{ display: "flex", flexShrink: 1, marginBottom: "20px" }}>
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>{componentCollection[0]}</div>
        </div>

        <InlineStack align='end'>
          <Button size="large" onClick={() => setChecksActive(false)}>
            Return to workstation
          </Button>
        </InlineStack>
      </Card>
    </>
  );
};
