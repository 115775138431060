import { useState, useEffect } from "react";
import { Layout, TextField, Card, Banner, Select, BlockStack } from "@shopify/polaris";
import { formatDate, getRequest, putRequest } from "../../../shared/components/functions";
import { CardHeading } from "../../../shared/components/CardHeading";

export default function QCResolver({ QC, reloadQC }) {
  const [types, setTypes] = useState([]);
  const [selectedType, setSelectedType] = useState("1");
  const [resolutionNote, setResolutionNote] = useState("");
  const [resolutionBtnLoading, setResolutionBtnLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/qc/types/resolution`);
      setTypes(data);
    }
    fetchData();
  }, []);

  async function resolveQCDb(payload) {
    setResolutionBtnLoading(true);
    await putRequest(`/api/qc/resolve/${QC.ID}`, payload);
    reloadQC();
  }

  function resolveQC() {
    //resolution of quality control TODOTODTODO: change user id to logged in user
    const adj_stock =
      types.length > 0
        ? types.filter((type) => {
            if (String(type.id) === selectedType) return type;
          })[0]["adjust_stock"]
          ? true
          : false
        : null;
    const payload = {
      timestamp: formatDate(new Date()),
      note: resolutionNote,
      resolution_type_id: parseInt(selectedType),
      user_id: 10,
      adjust_stock: adj_stock,
      stockchange_id: QC.stockchange.id,
      part_id: QC.stockchange.product.id,
      stockdiff: QC.stockchange.quantity,
    };
    if (payload.adjust_stock) {
      if (window.confirm(`This will increase the stock with +${payload.stockdiff} for part #${payload.part_id} ${QC.stockchange.product.name}`)) {
        resolveQCDb(payload);
      }
    } else {
      resolveQCDb(payload);
    }
  }

  const resolverHelpText =
    types.length > 0
      ? types.filter((type) => {
          if (String(type.id) === selectedType) return type;
        })[0]["adjust_stock"]
        ? `This will adjust the stock with +${QC.stockchange.quantity} pieces for part #${QC.stockchange.product.id} ${QC.stockchange.product.name}`
        : "This does not adjust stock"
      : null;

  return (
    <Layout.Section>
      <Card>
        <CardHeading title="Resolve QC" actions={[{ content: "Resolve QC", loading: resolutionBtnLoading, onAction: resolveQC, variant: "secondary" }]} />
        <BlockStack gap="400">
          <Banner>This quality control needs a resolution. Parts are not yet received as inventory.</Banner>
          <Select
            value={selectedType}
            label="How would you like to resolve this failed quality control?"
            helpText={resolverHelpText}
            onChange={(value) => setSelectedType(value)}
            options={types.map((type) => {
              return { label: type.resolutiontype, value: String(type.id) };
            })}
          />
          <TextField multiline={3} value={resolutionNote} onChange={(value) => setResolutionNote(value)} label="Provide additional resolution information" />
        </BlockStack>
      </Card>
    </Layout.Section>
  );
}
