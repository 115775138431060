import { Select, Checkbox, TextField, FormLayout, Form, Box, Divider } from "@shopify/polaris";
import { getCurrenctDatetimeYYYMMDD } from "../../../shared/components/functions";
import { CardHeading } from "../../../shared/components/CardHeading";

export const ProductEdit = ({ product, setProduct, setIsDirty, units }) => {
  const getDateHelpText = (date) => {
    return date !== null ? "Updated: " + String(date).substring(0, 10) + "" : null;
  };

  const handleChange = (value, id) => {
    if (id === "Eenheidsaankoopprijs") {
      setProduct(
        (product) => ({
          ...product,
          [id]: value,
          ["Datum bijw_AP"]: getCurrenctDatetimeYYYMMDD(),
        }),
        setIsDirty(true)
      );
    } else if (id === "Eenheids verkoopprijs") {
      setProduct(
        (product) => ({
          ...product,
          [id]: value,
          ["Datum  bijw_ VP"]: getCurrenctDatetimeYYYMMDD(),
        }),
        setIsDirty(true)
      );
    } else {
      setProduct((product) => ({ ...product, [id]: value }), setIsDirty(true));
    }
  };

  return (
    <Form>
      <FormLayout>
        <TextField id="Productref" maxLength={50} showCharacterCount autoComplete="off" label="Reference" value={product.Productref || ""} onChange={handleChange} />
        <TextField id="ProductNameNL" maxLength={60} autoComplete="off" label="Product name NL" value={product.ProductNameNL || ""} onChange={handleChange} />
        <TextField id="ProductNameFR" maxLength={60} autoComplete="off" label="Product name FR" value={product.ProductNameFR || ""} onChange={handleChange} />
        <TextField id="ProductNameDE" maxLength={60} autoComplete="off" label="Product name DE" value={product.ProductNameDE || ""} onChange={handleChange} />
        <TextField id="ProductNameUK" maxLength={60} autoComplete="off" label="Product name EN" value={product.ProductNameUK || ""} onChange={handleChange} />
        <FormLayout.Group>
          <Select
            id="EenheidID"
            label="Unit of measure"
            value={parseInt(product.EenheidID) || ""}
            options={units.map((unit, idx) => {
              return { key: idx, label: unit.Eenheid, value: unit.EenheidID };
            })}
            onChange={handleChange}
          />
          <TextField id="EenheidAantal" label="Qty per unit" autoComplete="off" type="number" value={String(product.EenheidAantal)} onChange={handleChange} />
        </FormLayout.Group>
        <TextField id="Omschrijving" autoComplete="off" value={product.Omschrijving || ""} onChange={handleChange} multiline={7} />
        <Checkbox id="Discontinued" label="Product discontinued" checked={product.Discontinued} onChange={handleChange} />

        <Divider />
        <Box paddingBlockStart="400">
          <CardHeading title="Pricing" />
          <FormLayout.Group>
            <TextField
              id="Eenheidsaankoopprijs"
              autoComplete="off"
              label={"Purchase price"}
              helpText={getDateHelpText(product["Datum bijw_AP"])}
              prefix="€"
              type="currency"
              value={String(product.Eenheidsaankoopprijs)}
              onChange={handleChange}
            />
            <TextField
              id="Eenheids verkoopprijs"
              autoComplete="off"
              label={"Selling price"}
              helpText={getDateHelpText(product["Datum  bijw_ VP"])}
              prefix="€"
              type="currency"
              value={String(product["Eenheids verkoopprijs"])}
              onChange={handleChange}
            />
          </FormLayout.Group>
        </Box>

        <Divider />
        <Box paddingBlockStart="400">
          <CardHeading title="Shipping & export" />
          <FormLayout.Group>
            <TextField
              id="Kg"
              autoComplete="off"
              label={"Weight"}
              // helpText={getDateHelpText(product["])}
              prefix="kg"
              type="number"
              value={product.Kg ? product.Kg : 0}
              onChange={handleChange}
            />
            <TextField
              id="HSCode"
              autoComplete="off"
              label={"HS code"}
              // helpText={getDateHelpText(product[""])}
              type="text"
              value={product.HSCode || ""}
              onChange={handleChange}
            />
          </FormLayout.Group>
        </Box>
      </FormLayout>
    </Form>
  );
};
