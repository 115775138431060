import { deleteRequest, getRequest, patchRequest, postRequest, putRequest } from "../shared/components/functions";

export async function removeSerialFromStation(serialNumberId) {
  console.log("removeSerialFromStation", serialNumberId);
  await patchRequest(`/api/serials/deletefromstation/${serialNumberId}`);
}

/*
    BILL OF MATERIALS
*/

export async function getProductBom(productId) {
  return await getRequest(`/api/products/bom/${productId}`);
}

export async function deleteBomEntry(entryId) {
  await deleteRequest(`/api/onderdelenbomentries/${entryId}`);
}

export async function updateBomEntryQty(entryId, newQty) {
  await putRequest(`/api/onderdelenbomentries/${entryId}`, {
    qty: newQty,
  });
}

export async function addBomEntry(productId, onderdeelId, qty) {
  await postRequest(`/api/onderdelenbomentries`, { productId, onderdeelId, qty });
}

/*
    Workorders
*/
export async function createWorkOrder(workOrder) {
  await postRequest(`/api/workorders`, workOrder);
}

/*
    inventory: array of objects with properties: productId, qty
*/
export async function finishDbWorkOrder(workOrderId, inventory) {
  await postRequest(`/api/workorders/${workOrderId}/finish`, {
    inventory: inventory,
  });
}

// cancel work order
export async function cancelDbWorkOrder(workOrderId) {
  await patchRequest(`/api/workorders/${workOrderId}/cancel`);
}

/*
    WorkStations
*/

export async function getWorkStations() {}

export async function getProducedProductInForWorkStation(workStationId) {
  const result = await getRequest(`/api/workstations/${workStationId}/productsproduced`);
  return result;
}
