import React, { useState, useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { DataTable, Layout, Page, Card, Link, Badge, Tooltip, Banner, Text, BlockStack } from "@shopify/polaris";
import InventoryReceivingModal from "./components/inventoryReceivingModal";
import { formatToCurrency, getRequest, truncate } from "../../shared/components/functions";
import { Toasts } from "../../shared/components/toasts/toasts";
import { useToggle } from "../../shared/hooks/useToggle";
import { QCLabelPrintModal } from "../../shared/components/modals/qcLabelPrintModal";
import { printAllQCLabels } from "../../shared/dymo/labels/qc_labels/qcLabels";
import { CardHeading } from "../../shared/components/CardHeading";
import { getQcStatusBadge, getStillOnOrderBadge } from "./components/orderLineBadges";

export default function PurchasePage() {
  let { id } = useParams();
  const [purchase, setPurchase] = useState({ purchase: "", lines: [], qcs: [] });
  const [inventoryRecivingModalActive, setInventoryRecivingModalActive] = useToggle(false);
  const [inventoryReceived, setInventoryReceived] = useState(false);
  const [toast, setToast] = useState(null);

  const fetchPurchase = useCallback(async () => {
    const data = await getRequest(`/api/purchases/${id}`);
    setPurchase(data);
    if (data.lines.reduce((a, line) => +a + +line.StillOnOrder, 0) === 0) setInventoryReceived(true);
  }, []);

  useEffect(() => {
    fetchPurchase();
  }, []);

  const qc_card =
    purchase.qcs.length > 0 ? (
      <Layout.Section>
        <Card>
          <CardHeading
            title="Quality control timeline"
            actions={[{ content: "Print all labels", onAction: () => printAllQCLabels(purchase.qcs, purchase.purchase.Bestellingnummer), variant: "secondary" }]}
          />
          <DataTable
            verticalAlign="middle"
            increasedTableDensity
            columnContentTypes={["text", "text", "text", "text", "numeric", "text", "text", "numeric"]}
            headings={[<b>ID</b>, <b>User</b>, <b>Date</b>, <b>Category</b>, <b>Quantity</b>, <b>Part</b>, <b>Status</b>, <b>Print</b>]}
            rows={purchase.qcs.map((item) => {
              const product_name =
                String(item.stockchange.product.name).length > 30 ? (
                  <Tooltip content={item.stockchange.product.name}>
                    <Link url={`/products/${item.stockchange.product.id}`} removeUnderline>
                      {truncate(item.stockchange.product.name, 30)}
                    </Link>
                  </Tooltip>
                ) : (
                  <Link removeUnderline url={`/products/${item.stockchange.product.id}`}>
                    {String(item.stockchange.product.name)}
                  </Link>
                );

              return [
                <Link removeUnderline url={`/qc/${item.ID}`}>
                  {item.ID}
                </Link>,
                <Badge>{item.user.abbreviation}</Badge>,
                String(item.Timestamp).substring(0, 10),
                <Badge>{item.classification_type.classification}</Badge>,
                item.stockchange.quantity,
                product_name,
                getQcStatusBadge(item),
                <QCLabelPrintModal qc={item} purchaseNumber={purchase.purchase.Bestellingnummer} />,
              ];
            })}
          />
        </Card>
      </Layout.Section>
    ) : null;

  const banner =
    purchase.lines.reduce((a, line) => +a + +line.StillOnOrder, 0) > 0 ? (
      purchase.lines.reduce((a, line) => +a + +line.StillOnOrder, 0) < purchase.lines.reduce((a, line) => +a + +line.aantal, 0) ? (
        <Banner tone="warning" title="Still waiting for delivery of some items, not everything is received yet." />
      ) : (
        <Banner title="No items taken in inventory yet." />
      )
    ) : (
      <Banner tone="success" title="All items are taken into inventory, order is closed." />
    );

  const rows = Array.isArray(purchase.lines)
    ? purchase.lines.map((line) => {
        const numberFormatter = new Intl.NumberFormat("en-US");

        const line_ref =
          String(line.Productref).length > 15 ? (
            <Tooltip content={line.Productref}>
              <Link removeUnderline monochrome>
                {truncate(line.Productref, 15)}
              </Link>
            </Tooltip>
          ) : (
            line.Productref
          );

        const line_text =
          String(line.tekstlijn).length > 50 ? (
            <Tooltip content={line.tekstlijn}>
              <Link removeUnderline monochrome>
                {truncate(line.tekstlijn, 50)}
              </Link>
            </Tooltip>
          ) : (
            line.tekstlijn
          );

        return [
          line_ref,
          <Link removeUnderline url={`/products/${line.produktnr}`}>
            {line.produktnr}
          </Link>,
          line_text,
          line.Rack,
          line.Legplank,
          numberFormatter.format(line.aantal),
          getStillOnOrderBadge(line.StillOnOrder),
        ];
      })
    : [];

  return (
    <Page
      backAction={{ content: "Purchases", url: "/purchases" }}
      title={`Purchase order #${purchase.purchase.Bestellingnummer}`}
      subtitle={String(purchase.purchase.Datum).substring(0, 10)}
      fullWidth
      primaryAction={{ content: "Inventory receiving", onClick: setInventoryRecivingModalActive, disabled: inventoryReceived }}>
      <Toasts toast={toast} setToast={setToast} toastContent="Purchase saved" />
      <InventoryReceivingModal
        active={inventoryRecivingModalActive}
        getPurchase={fetchPurchase}
        toggleModal={setInventoryRecivingModalActive}
        lines={purchase.lines.filter((line) => {
          if (line.StillOnOrder > 0) return line;
        })}
      />
      <Layout>
        <Layout.Section>{banner}</Layout.Section>

        <Layout.Section>
          <Card>
            <CardHeading title="Summary" />
            <BlockStack gap="200">
              <BlockStack>
                <Text fontWeight="bold">Supplier</Text>
                <Link removeUnderline url={`/companies/${purchase.purchase.LeverancierID}`}>
                  {purchase.purchase.supplier_name}
                </Link>
              </BlockStack>
              <BlockStack>
                <Text fontWeight="bold">Value</Text>
                {formatToCurrency(purchase.purchase.total_value)}
              </BlockStack>
              <BlockStack>
                <Text fontWeight="bold">Delivery term</Text>
                {purchase.purchase.Leveringstermijn}
              </BlockStack>
              <BlockStack>
                <Text fontWeight="bold">Offer</Text>
                {purchase.purchase.Aanbod}
              </BlockStack>
            </BlockStack>
          </Card>
        </Layout.Section>

        <Layout.Section>
          <Card>
            <CardHeading title="Order lines" />
            <DataTable
              verticalAlign="middle"
              increasedTableDensity
              columnContentTypes={["text", "text", "text", "numeric", "numeric", "numeric", "numeric"]}
              headings={[<b>Reference</b>, <b>Code</b>, <b>Description</b>, <b>Rack</b>, <b>Shelf</b>, <b>Ordered</b>, <b>Incoming</b>]}
              rows={rows}
            />
          </Card>
        </Layout.Section>
        {qc_card}
      </Layout>
    </Page>
  );
}
