import { useState, useEffect, useCallback } from "react";
import { Page, Card, Button, DataTable, ProgressBar, Banner, BlockStack } from "@shopify/polaris";
import { printLabel } from "../../shared/dymo/labels/box_labels/pdfBox";
import { deleteRequest, getRequest } from "../../shared/components/functions";
import { CardHeading } from "../../shared/components/CardHeading";
import { getBoxNotValidBadge, getBoxPitcherBadge, getBoxStatusBadge } from "./components/boxBadges";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function BoxPage() {
  const [box, setBox] = useState({ data: "", schema: [], pitchers_added: [] });
  const [nextBox, setNextBox] = useState();
  const [metrics, setMetrics] = useState("");
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    getBox();
    getMetrics();
  }, []);

  const getBox = useCallback(async () => {
    const box = await getRequest(`/api/boxes/in_production`);
    setBox(box);
    setLoaded(true);
  }, []);

  const getMetrics = useCallback(async () => {
    const metrics = await getRequest("/api/boxes/metrics");
    setMetrics(metrics[0]);
  }, []);

  const getNextBox = useCallback(async () => {
    const nextBox = await getRequest(`/api/boxes/production_done`);
    setNextBox(nextBox);
    getBox();
  }, []);

  const removePitcherFromBox = useCallback(async (pitcher_id) => {
    await deleteRequest(`/api/boxes/pitcher/${pitcher_id}`);
    getBox();
  }, []);

  const rows = Array.isArray(box.schema)
    ? box.schema.map((pitcher) => {
        return [getBoxPitcherBadge(pitcher), pitcher.Qty, pitcher.Added, getBoxStatusBadge(pitcher)];
      })
    : [];

  const rows_added = Array.isArray(box.pitchers_added)
    ? box.pitchers_added.map((pitcher) => {
        return [
          pitcher.ID,
          getBoxNotValidBadge(box, pitcher.PitcherType),
          pitcher.RfidSn,
          pitcher.Line2,
          pitcher.Line3,

          <Button variant="primary" tone="critical" size="slim" onClick={() => removePitcherFromBox(pitcher.ID)}>
            Remove from box
          </Button>,
        ];
      })
    : [];

  function checkContent() {
    let check = true;
    for (var i = 0; i < box.schema.length; i++) {
      if (box.schema[i].Qty - box.schema[i].Added !== 0) check = false;
    }
    if (
      box.schema.reduce((total, item) => {
        return total + item.Qty;
      }, 0) !== box.pitchers_added.length
    ) {
      check = false;
    }
    return check;
  }

  function checkError() {
    let check = false;
    for (var i = 0; i < box.schema.length; i++) {
      if (box.schema[i].Qty < box.schema[i].Added) check = true;
    }
    return check;
  }

  var check_weight = box.data.WeightFullLowerGram < box.data.WeightGram && box.data.WeightFullUpperGram > box.data.WeightGram ? true : false;
  var check_content = checkContent();
  var check_error = checkError();

  const box_style_color = check_weight && check_content ? "#ADE8D0" : check_error ? "#F70100" : "#FFD69C";
  // console.log("planning", metrics.boxesPlanning);

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="Pitcher box production" fullWidth>
      <BlockStack gap="400">
        <Banner title={`Still ${metrics.boxesPlanning} boxes in the production planning after the production of this box.`} tone="info" />

        {box.data && metrics.boxesPlanning > 0 ? (
          <Card>
            <CardHeading title={`BOX #${box.data.ID} ${box.data.Name}`} />

            <div style={{ backgroundColor: box_style_color, padding: "20px", borderRadius: "5px" }}>
              <BlockStack gap="400">
                <Card>
                  <CardHeading title={`Weight: ${box.data.WeightFullLowerGram}gr << ${box.data.WeightGram}gr << ${box.data.WeightFullUpperGram}gr`} />
                  <ProgressBar size="large" tone={check_weight ? "success" : "critical"} progress={(box.data.WeightGram / box.data.WeightFullLowerGram) * 100} />
                </Card>

                <Card>
                  <CardHeading
                    title="Pitcher needed for box"
                    actions={[
                      { content: "Print label", onAction: () => printLabel(box), disabled: !check_weight && !check_content, variant: "secondary" },
                      { content: "NEXT BOX", onAction: getNextBox, disabled: !check_weight && !check_content, variant: "secondary" },
                    ]}
                  />
                  <DataTable verticalAlign="middle" columnContentTypes={["text", "numeric", "numeric", "numeric"]} headings={["Pitcher", "Qty needed", "Qty added", "Status"]} rows={rows} />
                </Card>

                <Card>
                  <CardHeading title="Added pitchers to the box" />
                  <DataTable
                    verticalAlign="middle"
                    columnContentTypes={["text", "text", "text", "text", "text", "text"]}
                    headings={["Pitcher ID", "Pitcher Type", "RFID", "Line2", "Line3", "Remove"]}
                    rows={rows_added}
                  />
                </Card>
              </BlockStack>
            </div>
          </Card>
        ) : null}
      </BlockStack>
    </Page>
  );
}
