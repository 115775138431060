import { Card, DataTable, Link } from "@shopify/polaris";
import { getRequest, formatToCurrency } from "../../../shared/components/functions";
import { useEffect, useState } from "react";

export default function ProductsCompany({ FIRNR }) {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/products?FIRNR=${FIRNR}`);
      setProducts(data);
    }
    fetchData();
  }, []);

  const rows = products.map((item) => {
    return [
      <Link removeUnderline url={`/products/${item.ProductID}`}>
        {item.ProductID}
      </Link>,
      item.ProductNameNL,
      formatToCurrency(item.Eenheidsaankoopprijs),
      formatToCurrency(item["Eenheids verkoopprijs"]),
    ];
  });

  return (
    <Card>
      <DataTable
        verticalAlign="middle"
        increasedTableDensity
        columnContentTypes={["text", "text", "numeric", "numeric"]}
        headings={[<b>Number</b>, <b>Name</b>, <b>Purchase price</b>, <b>Selling price</b>]}
        rows={rows}
      />
    </Card>
  );
}
