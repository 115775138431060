import { Banner, BlockStack, Box, DropZone, Form, FormLayout, InlineStack, Modal, Select, Text, TextField, Thumbnail } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { handleFileChange, validImageTypes } from "./filefunctions";
import { NoteIcon } from "@shopify/polaris-icons";

export const FileUploadModal = ({ modalActive, setModalActive, handleFiles, productFiles, setUrlsLoaded }) => {
  const getDate = () => {
    const date = new Date();
    return `${
      date.getFullYear() +
      ("0" + (date.getMonth() + 1)).slice(-2) +
      ("0" + date.getDate()).slice(-2) +
      ("0" + date.getHours()).slice(-2) +
      ("0" + date.getMinutes()).slice(-2) +
      ("0" + date.getSeconds()).slice(-2)
    }`;
  };

  const [isUploading, setIsUploading] = useState(false);
  const [fileToUpload, setFileToUpload] = useState();
  const [newFile, setNewFile] = useState({ type: "certification", name: "", date: getDate() });
  const [documentWarning, setDocumentWarning] = useState(false);

  const handleChange = (value, id) => {
    setNewFile((newFile) => ({ ...newFile, [id]: value }));
  };

  const handleDropZoneDrop = useCallback((_dropFiles, acceptedFiles, _rejectedFiles) => setFileToUpload(acceptedFiles[0]), []);

  const handleSave = async () => {
    setUrlsLoaded(false);
    setIsUploading(true);
    const AwsFileKeys = JSON.parse(productFiles === "null" ? "[]" : productFiles) || [];
    await handleFileChange(true, newFile, fileToUpload, handleFiles, AwsFileKeys);
    setIsUploading(false);
    handleClose();
  };

  const handleClose = () => {
    setModalActive(false);
    setFileToUpload();
    setNewFile({ type: "certification", name: "", date: getDate() });
    setIsUploading(false);
  };

  useEffect(() => {
    if (fileToUpload && fileToUpload.type !== "application/pdf" && newFile && newFile.type === "work_instruction") return setDocumentWarning(true);
    setDocumentWarning(false);
  }, [fileToUpload, newFile]);

  const fileUpload = !fileToUpload && <DropZone.FileUpload />;
  const uploadedFile = fileToUpload && (
    <Box padding="200">
      <InlineStack gap="200">
        <Thumbnail size="small" alt={fileToUpload.name} source={validImageTypes.includes(fileToUpload.type) ? window.URL.createObjectURL(fileToUpload) : NoteIcon} />
        <BlockStack>
          {fileToUpload.name}
          <Text variant="bodySm" as="p">
            {fileToUpload.size} bytes
          </Text>
        </BlockStack>
      </InlineStack>
    </Box>
  );

  return (
    <Modal
      open={modalActive}
      size="large"
      onClose={handleClose}
      title="Upload a new file"
      primaryAction={{ content: "Upload file", disabled: !fileToUpload, loading: isUploading, onAction: handleSave }}
      secondaryActions={[{ content: "Close", disabled: isUploading, onAction: handleClose }]}>
      <Modal.Section>
        <Form>
          <FormLayout>
            {documentWarning && <Banner tone="warning">The recommended format for work instructions is PDF.</Banner>}
            <Select
              id="type"
              label="File type"
              options={[
                { label: "Certification", value: "certification" },
                { label: "Part specification", value: "part_specification" },
                { label: "Quality control", value: "quality_control" },
                { label: "Work instruction", value: "work_instruction" },
                { label: "Other", value: "other" },
              ]}
              onChange={handleChange}
              value={newFile.type}
            />
            <TextField id="name" autoComplete="off" label="File name" value={newFile.name} onChange={handleChange} prefix={`${newFile.type}_` || ""} suffix={`_${newFile.date}`} />
            <DropZone allowMultiple={false} onDrop={handleDropZoneDrop}>
              {uploadedFile}
              {fileUpload}
            </DropZone>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};
