import { useState, useEffect } from "react";
import { Modal, Button, DataTable } from "@shopify/polaris";
import { MaximizeIcon } from "@shopify/polaris-icons";
import { getRequest } from "../../../shared/components/functions";
import { useToggle } from "../../../shared/hooks/useToggle";

export function BoxModal({ id }) {
  const [active, setActive] = useToggle(false);
  const [box, setBox] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/boxes/detail/${id}`);
      setBox(data);
      setDataLoaded(true);
    }
    if (active) fetchData();
  }, [active]);

  const activator = <Button icon={MaximizeIcon} size="slim" onClick={setActive} />;

  return (
    <Modal size="large" activator={activator} open={active} onClose={setActive} title="Content box">
      <Modal.Section>
        {dataLoaded ? (
          <DataTable
            verticalAlign="middle"
            increasedTableDensity
            columnContentTypes={["text", "text", "text", "text", "text"]}
            headings={["Pitcher ID", "Pitcher type", "RFID", "Line2", "Line3"]}
            rows={box.pitchers_added.map((pitcher) => [pitcher.ID, pitcher.PitcherType, pitcher.RfidSn, pitcher.Line2, pitcher.Line3])}
          />
        ) : null}

        {/* All Data:
          <div>
            <pre>{JSON.stringify(box, null, 2)}</pre>
          </div> */}
      </Modal.Section>
    </Modal>
  );
}
