import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Banner, BlockStack, Box, Checkbox, Modal, Spinner, Text, TextField } from "@shopify/polaris";
import { deleteRequest, getRequest, patchRequest, postRequest } from "../../../shared/components/typedFunctions";
import { VersionRecord } from "..";
import { Product } from "../../../types/mainTypes";
import { useAuthStore } from '../../../utils/useStore'

type EditPartModalProps = {
  modalOpen: boolean;
  hideModal: () => void;
  part: VersionRecord;
  fetchData: () => void;
  product: string;
  isEdit: boolean;
  isDelete: boolean;
};

export default function PartModal({ modalOpen, hideModal, part, fetchData, product, isEdit, isDelete }: EditPartModalProps) {
  const { user } = useAuthStore();
  const [products, setProducts] = useState<Product[]>([]);
  const [selectedPart, setSelectedPart] = useState<{ label: string, value: string } | null>(null);
  const [initialSelectedPart, setInitialSelectedPart] = useState<{ label: string, value: string } | null>(null);
  const [quantity, setQuantity] = useState<string>('1');
  const [initialQuantity, setInitialQuantity] = useState<string>('1');
  const [loaded, setLoaded] = useState<boolean>(false);
  const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(false);
  const [log, setLog] = useState(
    {
      isLogged: true,
      userlog: '',
      product: product.toLowerCase(),
      user: user?.id,
      changeType: isDelete ? 'delete' : isEdit ? 'edit' : 'add',
      parentRowId: (isEdit || isDelete) ? String(part.Parent) : String(part.NodeNumber)
    }
  )

  useEffect(() => {
    async function fetchData() {
      const products = await getRequest(`/api/products?page=1&limit=10000`);
      setProducts(products);
      setLoaded(true)
    }
    fetchData();
  }, []);

  useEffect(() => {
    if (!products || products.length < 1 || !isEdit) return

    if (part) {
      const filteredPart = products.filter((product) => product?.ProductID === part.PartId)[0];
      const selected = { label: `#${filteredPart?.ProductID} ${filteredPart?.ProductNameNL}`, value: filteredPart?.ProductID + "" }
      setSelectedPart(selected);
      setInitialSelectedPart(selected);
      setQuantity(String(part.Quantity));
      setInitialQuantity(String(part.Quantity))
    }
  }, [products, isEdit]);

  const handleLogInput = (value: string | boolean, id: string) => {
    setLog((log) => ({ ...log, [id]: value }));
  }

  const handleSave = async () => {
    if (!selectedPart) return alert("Please select a part")
    setAddButtonDisabled(true);
    const payload = { Quantity: quantity, PartID: selectedPart.value }
    if (isDelete) {
      await deleteRequest(`/api/${product}/node/remove/${part.NodeNumber}`);
    } else if (!isEdit) {
      if (part.Rgt - part.Lft > 1) await postRequest(`/api/${product}/nodes/${part.NodeNumber}`, payload);
      else await postRequest(`/api/${product}/nodes/new/${part.NodeNumber}`, payload);
    } else {
      await patchRequest(`/api/${product}/nodes/${part.ID}`, payload);
    }

    if (log.isLogged) {
      const loggingPayload = {
        ...log,
        date: new Date(),
        initialProductName: initialSelectedPart?.label,
        productName: selectedPart.label,
        productId: selectedPart.value,
        initialQty: initialQuantity,
        qty: quantity,
      }
      await postRequest('/api/changelog', loggingPayload)
    }
    setAddButtonDisabled(false);
    fetchData();
    hideModal();
  }

  if (!loaded) return (<Modal open={modalOpen} onClose={hideModal} title="Loading..."><Modal.Section><Spinner size='small' /></Modal.Section></Modal>)

  return (
    <Modal
      open={modalOpen}
      onClose={hideModal}
      title={isDelete ? 'Delete part' : isEdit ? 'Edit existing part' : 'Pick part to add'}
      primaryAction={{ content: "Save", onAction: handleSave, disabled: addButtonDisabled }}
      secondaryActions={[{ content: "Cancel", onAction: hideModal }]}
    >
      <Modal.Section>
        {isDelete ? (
          <Banner tone='warning'><Text variant="bodyLg" as='p'>Are you sure you want to delete this part out of the tree? It will be removed for all different versions!</Text></Banner>
        ) : (
          <BlockStack gap='300'>
            <Box>
              <Box paddingBlockEnd="100">
                <Text as='p'>{isEdit ? 'Edit part' : 'Pick part to add'}</Text>
              </Box>
              <Select
                styles={{
                  valueContainer: (base) => ({ ...base, height: "32px" }),
                  indicatorsContainer: (base) => ({ ...base, height: "32px" }),
                  control: (base) => ({ ...base, height: "32px", minHeight: "32px", borderRadius: "8px", borderColor: "#919191" }),
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 })
                }}
                menuPortalTarget={document.body}
                options={products.filter((pr) => !pr.Discontinued).map((pr) => ({ label: `#${pr?.ProductID} ${pr.ProductNameNL}`, value: String(pr?.ProductID) }))}
                value={selectedPart}
                onChange={setSelectedPart}
              />
            </Box>
            <TextField autoComplete="off" label="Quantity" type="number" value={quantity} onChange={setQuantity} />
          </BlockStack>
        )}
      </Modal.Section>

      <Modal.Section>
        <BlockStack gap='200'>
          <Checkbox label="Add to changelog" checked={log.isLogged} id='isLogged' onChange={handleLogInput} />
          <TextField label='Additional information (optional)' id='userlog' onChange={handleLogInput} value={log.userlog} multiline={2} autoComplete="off" disabled={!log.isLogged} />
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
}
