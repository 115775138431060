import { useState, useEffect } from "react";
import { Avatar, ResourceItem, ResourceList, Text } from "@shopify/polaris";
import { filtersToApiUrl, useUrl } from "../../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest } from "../../../shared/components/functions";

export default function ContactsTable({ id }) {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Results", value: url.get("limit") || "100" }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: `KLANR:${id}` }],
    ])
  );

  useEffect(() => {
    async function fetchData() {
      let filterString = filtersToApiUrl(filters);
      const result = await getRequest(`/api/contacts?${filterString}`);
      var mapped_result = result.map((item) => {
        return {
          id: item.CONNR,
          url: "/contacts/" + item.CONNR,
          name: item.NAAM,
          location: item.DEPT,
        };
      });
      setItems(mapped_result);
      if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    }
    fetchData();
  }, [filters]);

  return (
    <ResourceList
      resourceName={{ singular: "customer", plural: "customers" }}
      items={items}
      renderItem={(item) => {
        return (
          <ResourceItem verticalAlignment="center" id={item.id} media={<Avatar customer size="md" name={item.name} />} accessibilityLabel={`View details for ${item.name}`}>
            <Text fontWeight="bold">{item.name}</Text>
          </ResourceItem>
        );
      }}
    />
  );
}
