import { Badge } from "@shopify/polaris";

export const getStatusBadge = (item) => {
  if (item.ResolutionDate)
    return (
      <Badge progress="complete" tone="warning">
        QC Resolved
      </Badge>
    );
  if (item.QualityPassed)
    return (
      <Badge progress="complete" tone="success">
        QC Passed
      </Badge>
    );

  return (
    <Badge progress="incomplete" tone="critical">
      QC Failed
    </Badge>
  );
};
