import { Card } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { postRequest } from "../../../../shared/components/functions";
import { FileUploadModal } from "./fileCardModal";
import { FileTable } from "./fileTable";
import { CardHeading } from "../../../../shared/components/CardHeading";
import { useToggle } from "../../../../shared/hooks/useToggle";

export const ProductFiles = ({ isDirty, handleFiles, productFiles }) => {
  let { id } = useParams();
  const [modalActive, setModalActive] = useToggle(false);
  const [urlsLoaded, setUrlsLoaded] = useState(false);
  const [S3ObjectUrls, setS3ObjectUrls] = useState([]);

  const AwsFileKeys = JSON.parse(productFiles === "null" ? "[]" : productFiles) || [];

  const typeSortedFiles = {
    certification: AwsFileKeys.filter((a) => a.type === "certification"),
    part_specification: AwsFileKeys.filter((a) => a.type === "part_specification"),
    quality_control: AwsFileKeys.filter((a) => a.type === "quality_control"),
    work_instruction: AwsFileKeys.filter((a) => a.type === "work_instruction"),
    other: AwsFileKeys.filter((a) => a.type === "other"),
  };

  useEffect(() => {
    async function fetchS3ObjectUrls() {
      if (!AwsFileKeys) return setS3ObjectUrls([]);
      if (AwsFileKeys.length === 0) return setS3ObjectUrls([]);
      const data = await postRequest(`/api/aws/s3/download`, { keys: AwsFileKeys.map((key) => key.key) });
      setS3ObjectUrls(data);
      setUrlsLoaded(true);
    }
    fetchS3ObjectUrls();
  }, [productFiles]);

  return (
    <Card>
      <CardHeading title="Files" actions={[{ content: "Upload file", onAction: setModalActive, variant: "secondary", disabled: id === "new" || isDirty }]} />
      <FileUploadModal modalActive={modalActive} setModalActive={setModalActive} productFiles={productFiles} handleFiles={handleFiles} setUrlsLoaded={setUrlsLoaded} />

      {urlsLoaded ? (
        <>
          {AwsFileKeys.length > 0 ? <div style={{ height: "30px" }}></div> : null}

          <div style={{ margin: "-15px -20px" }}>
            {typeSortedFiles.certification.length > 0 ? (
              <FileTable title={"Certification"} typeSortedFiles={typeSortedFiles.certification} AwsFileKeys={AwsFileKeys} S3ObjectUrls={S3ObjectUrls} handleFiles={handleFiles} />
            ) : null}
            {typeSortedFiles.part_specification.length > 0 ? (
              <FileTable title={"Part specification"} typeSortedFiles={typeSortedFiles.part_specification} AwsFileKeys={AwsFileKeys} S3ObjectUrls={S3ObjectUrls} handleFiles={handleFiles} />
            ) : null}
            {typeSortedFiles.quality_control.length > 0 ? (
              <FileTable title={"Quality Control"} typeSortedFiles={typeSortedFiles.quality_control} AwsFileKeys={AwsFileKeys} S3ObjectUrls={S3ObjectUrls} handleFiles={handleFiles} />
            ) : null}
            {typeSortedFiles.work_instruction.length > 0 ? (
              <FileTable title={"Work instructions"} typeSortedFiles={typeSortedFiles.work_instruction} AwsFileKeys={AwsFileKeys} S3ObjectUrls={S3ObjectUrls} handleFiles={handleFiles} />
            ) : null}
            {typeSortedFiles.other.length > 0 ? (
              <FileTable title={"Other"} typeSortedFiles={typeSortedFiles.other} AwsFileKeys={AwsFileKeys} S3ObjectUrls={S3ObjectUrls} handleFiles={handleFiles} />
            ) : null}
          </div>
        </>
      ) : null}
    </Card>
  );
};
