import { useState, useEffect, useCallback } from "react";
import { Layout, Page, Card, Banner, Button, ButtonGroup, Badge, BlockStack, InlineStack, Box } from "@shopify/polaris";
import { EditIcon, LabelPrinterIcon, PrintIcon } from "@shopify/polaris-icons";
import { useParams } from "react-router-dom";
import { getRequest, putRequest } from "../../shared/components/functions";
import { Toasts } from "../../shared/components/toasts/toasts";
import { StockHistory } from "../stock/StockHistory";
import { ProductInfo } from "./components/infoProductComponent";
import { ProductEdit } from "./components/editProductComponent";
import { ExternalInventoryTable } from "./components/ExternalInventoryTable";
import { S3ImageUploader, S3ImageViewer } from "../../shared/components/s3_image_display/s3ImageUploader";
import { useLookupStore } from "../../utils/useStore";
import { printProductLabel } from "../../shared/dymo/labels/product_labels/productLabel";
import { ProductFiles } from "./components/files/fileComponent";
import { BomManager } from "./bom/billofmaterials";
import { LabelModal } from "./components/LabelModal";
import { CurrentStock } from "../stock/CurrentStock";
import { CardHeading } from "../../shared/components/CardHeading";

export default function ProductPage() {
  let { id } = useParams();
  const units = useLookupStore((state) => state.units);
  const [initialProduct, setInitialProduct] = useState("");
  const [product, setProduct] = useState("");
  const [inventory, setInventory] = useState([]);
  const [externalInventory, setExternalInventory] = useState([]);
  const [stockHistory, setStockHistory] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [toast, setToast] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [labelModalOpen, setLabelModalOpen] = useState(false);

  const fetchStockHistory = useCallback(async () => {
    const data = await getRequest(`/api/stockchanges/${id}?limit=100`);
    setStockHistory(data);
  }, [id]);

  const fetchInventory = useCallback(async () => {
    const data = await getRequest(`/api/inventory/${id}`);
    if (data && data.length > 0) {
      setInventory(data.filter((d) => d.Stockplaats === 1)[0] || []);
      setExternalInventory(data);
    }
  }, [id]);

  const fetchData = useCallback(async () => {
    const data = await getRequest(`/api/products/${id}`);
    let companyName, schuilProductName;
    if (data[0]) {
      const company = await getRequest(`/api/companies/${data[0].SupplierID}`);
      companyName = { supplierName: company[0].NAAM };

      const schuil = await getRequest(`/api/translations/?schuilProdId=${data[0].produktnr}`);
      schuilProductName = { schuilProductName: schuil[0]?.schuil_name || null };
    }
    setProduct(Object.assign(data[0], companyName, schuilProductName));
    setInitialProduct(data[0]);
  }, [id]);

  useEffect(() => {
    fetchData();
    fetchStockHistory();
    fetchInventory();
  }, [id]);

  const handleSave = async () => {
    setIsSaving(true);
    await putRequest(`/api/products/${id}`, product, setToast);
    setIsSaving(false);
    setIsEdit(false);
    fetchData();
  };

  const handleDiscard = () => {
    setProduct(initialProduct);
    setIsDirty(false);
    setIsEdit(false);
  };

  const discontinued_banner = product.Discontinued ? <Banner tone="critical" title="This product is discontinued!" /> : null;

  const translation_banner = !product.produktnr ? <Banner tone="warning" title="This product is missing a translation." /> : null;

  const handlePictures = async (updatedImageKeys, key) => {
    const updatedProduct = { ...product, [key]: JSON.stringify(updatedImageKeys) };

    if (key === "S3ObjectKeys" && updatedImageKeys.length === 1 && (!product.Image || product.Image === "[]" || product.Image === "null")) {
      updatedProduct.Image = JSON.stringify(updatedImageKeys);
    }
    setProduct(updatedProduct);
    await putRequest(`/api/products/${id}`, updatedProduct);
  };

  const deletePicFromKeysAndImage = async (updatedImageKeys) => {
    const updatedProduct = { ...product, S3ObjectKeys: JSON.stringify(updatedImageKeys), Image: null };
    setProduct(updatedProduct);
    await putRequest(`/api/products/${id}`, updatedProduct);
  };

  return (
    <Page
      backAction={{ content: "Products", url: "/products" }}
      title={product.ProductNameNL}
      subtitle={"#" + id}
      fullWidth
      primaryAction={
        <ButtonGroup>
          <Button
            disabled={!product.produktnr > 50}
            icon={PrintIcon}
            onClick={() => {
              if (product.produktnr > 50) setLabelModalOpen(true);
              else alert("Vraag aan Thomas om een vertaling toe te voegen voor dit onderdeel in de schuilenburg database.");
            }}>
            {product.produktnr > 50 ? "Label" : "THOMAS - vertaling onderdeel mist!"}
          </Button>
          <Button icon={LabelPrinterIcon} onClick={() => printProductLabel(product, inventory)}>
            Inventory
          </Button>
        </ButtonGroup>
      }>
      <LabelModal isOpen={labelModalOpen} setOpen={setLabelModalOpen} product={product} />
      <Toasts toast={toast} setToast={setToast} toastContent="Product saved" />

      <BlockStack gap="400">
        {discontinued_banner}
        {translation_banner}

        <Layout>
          <Layout.Section variant="oneHalf">
            <BlockStack gap="400">
              <Card>
                <CardHeading title="Inventory" />
                <CurrentStock stockData={inventory} fetchInventory={fetchInventory} fetchStockHistory={fetchStockHistory} productID={id} />
              </Card>

              <Card>
                <CardHeading
                  title={
                    <InlineStack gap="200">
                      Product info
                      <Box>
                        <Badge tone={product.Discontinued ? "critical" : "success"}>{product.Discontinued ? "Discontinued" : "Active"}</Badge>
                      </Box>
                    </InlineStack>
                  }
                  actions={
                    isEdit
                      ? [
                          { content: "Cancel", onAction: handleDiscard, variant: "primary", tone: "critical", disabled: isSaving },
                          { content: "Save", onAction: handleSave, variant: "primary", loading: isSaving, disabled: !isDirty },
                        ]
                      : [{ icon: EditIcon, onAction: () => setIsEdit(true), variant: "secondary" }]
                  }
                />

                {isEdit ? <ProductEdit product={product} setProduct={setProduct} setIsDirty={setIsDirty} units={units} /> : <ProductInfo product={product} units={units} />}
              </Card>
            </BlockStack>
          </Layout.Section>

          <Layout.Section variant="oneHalf">
            <BlockStack gap="400">
              <ExternalInventoryTable fullInventory={externalInventory} fetchInventory={fetchInventory} fetchStockHistory={fetchStockHistory} setToast={setToast} />

              {!product.Image || product.Image === "[]" || product.Image === "null" ? null : (
                <Card>
                  <CardHeading title="Product picture" />
                  <S3ImageViewer S3ObjectKeys={product.Image} onRemoveImages={async () => await handlePictures(null, "Image")} thumbNails hasCustomRemoveAction />
                </Card>
              )}

              <BomManager productId={id} />

              {product && (
                <S3ImageUploader
                  S3ObjectKeys={product.S3ObjectKeys}
                  S3ObjectNamePrefix="product_images"
                  onUploadImages={async (updatedImageKeys) => await handlePictures(updatedImageKeys, "S3ObjectKeys")}
                  onRemoveImages={async (updatedImageKeys) => {
                    const productImage = JSON.parse(product.S3ObjectKeys);
                    if (!updatedImageKeys.includes(productImage[0])) {
                      await deletePicFromKeysAndImage(updatedImageKeys);
                    } else {
                      await handlePictures(updatedImageKeys, "S3ObjectKeys");
                    }
                  }}
                  hasCustomRemoveAction={false}
                  customActionTitle="select as product picture"
                  handleCustomAction={async (selectedImage) => {
                    const productPicArr = [];
                    productPicArr.push(selectedImage.key);
                    await handlePictures(productPicArr, "Image");
                  }}
                />
              )}

              {product && <ProductFiles isDirty={isDirty} handleFiles={handlePictures} productFiles={product.Files} />}

              <StockHistory stockHistory={stockHistory} />
            </BlockStack>
          </Layout.Section>
        </Layout>
      </BlockStack>
    </Page>
  );
}
