import { useRef, useEffect, useState } from "react";
import { BrowserMultiFormatReader, NotFoundException } from "@zxing/library";
import { BlockStack, Box, Button, Select } from "@shopify/polaris";

export function Scanner({ onBarcodeRead }) {
  const videoRef = useRef(null);
  const [videoDevices, setVideoDevices] = useState([]);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [isScanning, setIsScanning] = useState(false);
  const [scannedCode, setScannedCode] = useState(null);
  const codeReader = new BrowserMultiFormatReader();

  // Fetch available video devices when component mounts
  useEffect(() => {
    const getVideoDevices = async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((device) => device.kind === "videoinput");
      setVideoDevices(videoDevices);
      const backCamera = videoDevices.find((device) => device.label.toLowerCase().includes("back"));
      setCurrentDevice(backCamera?.deviceId || videoDevices[0]?.deviceId);
    };

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      getVideoDevices();
    }
  }, []);

  // Start camera stream when current device changes
  useEffect(() => {
    if (!currentDevice || !videoRef.current) return;

    const video = videoRef.current;
    navigator.mediaDevices
      .getUserMedia({
        video: {
          deviceId: currentDevice,
          width: { ideal: 200 },
          height: { ideal: 200 },
        },
      })
      .then((stream) => {
        video.srcObject = stream;
        video.setAttribute("playsinline", true);
        video.play();
      });

    return () => {
      if (video.srcObject) {
        video.srcObject.getTracks().forEach((track) => {
          track.stop();
        });
      }
    };
  }, [currentDevice]);

  // Start or stop scanning when isScanning changes
  useEffect(() => {
    if (!isScanning || !videoRef.current) return;

    const video = videoRef.current;

    codeReader.reset();
    codeReader.decodeFromVideoDevice(undefined, video, (result, err) => {
      if (result) {
        console.log(result.text);
        setScannedCode(result.text);
        if (onBarcodeRead) {
          onBarcodeRead(result.text);
        }
        setIsScanning(false);
      }

      if (err && !(err instanceof NotFoundException)) {
        console.error(err);
      }
    });

    return () => {
      codeReader.reset();
    };
  }, [isScanning]);

  return (
    <BlockStack gap="200">
      <Select
        onChange={(e) => setCurrentDevice(e.target.value)}
        value={currentDevice || ""}
        options={videoDevices.map((device) => ({ label: device.label || device.deviceId, value: device.deviceId }))}
      />
      <Box>
        <Button
          onClick={() => {
            setIsScanning(!isScanning);
            setScannedCode(null);
          }}>
          Start scanning
        </Button>
      </Box>
      {!isScanning && !scannedCode && <p>No video to display</p>}
      {!isScanning && scannedCode && <p>Scanned code: {scannedCode}</p>}
      {<video id="video" width="300" height="200" ref={videoRef} style={{ display: isScanning ? "block" : "none" }}></video>}
    </BlockStack>
  );
}
