import { useState, useEffect } from "react";
import { DataTable, Page, Card, Badge, Link, Filters, Text, InlineStack, BlockStack, Box } from "@shopify/polaris";
import { getRequest, getTableFooter, epochToLocal } from "../../shared/components/functions";
import { SerialsModal } from "../../shared/components/serialHelpers/serialsModal";
import { useToggle } from "../../shared/hooks/useToggle.js";
import { Toasts } from "../../shared/components/toasts/toasts";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { useStationStore } from "../../utils/useStore";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner.jsx";

export default function RfidEventsPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const [stationData, setStationData] = useStationStore((state) => [state.stationData, state.setStationData]);
  const [items, setItems] = useState([]);
  const [printModalActive, setPrintModalActive] = useToggle();
  const [serialToast, setSerialToast] = useState(false);
  const [labelToast, setLabelToast] = useState(false);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
    ])
  );

  async function fetchData() {
    let filterString = filtersToApiUrl(filters);
    setItems(await getRequest(`/api/rfid_events?${filterString}`));
    setLoaded(true);
    if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
  }

  useEffect(() => {
    fetchData();
    if (!printModalActive) {
      const interval = setInterval(() => setStationData(), 2000);
      return () => clearInterval(interval);
    }
  }, [filters, printModalActive]);

  const rows = Array.isArray(items)
    ? items.map((item) => [
        item.id,
        <Link removeUnderline url={`/serials/${item.serialNumberId}`}>
          {item.serialNumberId}
        </Link>,
        item.Location,
        <Badge tone={item.type === "Entry" ? "success" : ""}>{item.type}</Badge>,
        epochToLocal(item.timestamp),
      ])
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="RFID Events" fullWidth primaryAction={{ content: "PRINT RFID LABEL", onAction: setPrintModalActive }} subtitle="Last received entry/update/exit events">
      {printModalActive ? (
        <SerialsModal
          modalActive={printModalActive}
          closeModal={setPrintModalActive}
          isEditSerial={false}
          isRFID={true}
          serialToEdit={null}
          setSerialToast={setSerialToast}
          setLabelToast={setLabelToast}
        />
      ) : null}
      <Toasts toast={serialToast} setToast={setSerialToast} toastContent="Serial number saved" errorToastContent="Error saving serial number" />
      <Toasts toast={labelToast} setToast={setLabelToast} toastContent="RFID label sent" errorToastContent="Error sending RFID label" />
      <Card>
        <Box padding="300">
          <InlineStack align="space-between">
            {stationData.map((station, idx) => {
              return (
                <BlockStack key={idx} gap="200">
                  <Text variant="headingLg" as="h5">
                    {station.workstation_name}
                  </Text>
                  {station.entries?.length > 0 ? (
                    station.entries.map((e, idx) => {
                      return (
                        <Text key={idx} variant="headingMd" as="h6">
                          {e.serialnumber}
                        </Text>
                      );
                    })
                  ) : (
                    <Text>Station empty</Text>
                  )}
                </BlockStack>
              );
            })}
          </InlineStack>
        </Box>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter RFID events"
        />
        <DataTable
          verticalAlign="middle"
          columnContentTypes={["text", "number", "text", "text", "text"]}
          headings={[<b>ID</b>, <b>Serial number</b>, <b>Location</b>, <b>Type</b>, <b>Timestamp</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "RFID events")}
        />
      </Card>
    </Page>
  );
}
