import { useState } from "react";
import { Button, ButtonGroup, Modal, DataTable, Text, Link, BlockStack, Box, Banner } from "@shopify/polaris";
import { BomForm } from "./bomForm";
import { useAuthStore } from "../../../utils/useStore";
import { getRequest, postRequest } from "../../../shared/components/functions";
import { TreeForm } from "./treeForm";
import { getProductBom } from "../../../database/db";

export const NewWorkorderModal = ({ modalActive, setModalActive, setToast }) => {
  const user = useAuthStore((state) => state.user);

  const workOrderInterface = {
    OnderdelenId: { value: null, label: null },
    CreatedByUserId: user.id,
    Qty: 1,
    OrderbevestigingLijnId: null,
    VariantName: "",
    StructureName: "",
    AssignmentDate: new Date(),
    CreatedAt: new Date(),
    Status: "OPEN",
    DueDate: new Date().toISOString().substring(0, 10),
  };

  const [loading, setLoading] = useState(false);
  const [treeSelected, setTreeSelected] = useState(false);
  const [partsToBeConsumed, setPartsToBeConsumed] = useState([]);
  const [workOrder, setWorkOrder] = useState(workOrderInterface);
  const [bomError, setBomError] = useState(false)

  const handleChange = (value, id) => {
    setWorkOrder((workOrder) => ({ ...workOrder, [id]: value }));
  };

  const changeType = (value) => {
    setTreeSelected(value);
    if (!value) return setWorkOrder((workOrder) => ({ ...workOrder, VariantName: "", StructureName: "" }));
    return handleChange("Greg", "StructureName");
  };

  const handleClose = () => {
    setModalActive(false);
    setLoading(false);
    setWorkOrder(workOrderInterface);
    setTreeSelected(false);
    setPartsToBeConsumed([]);
    setBomError(false)
  };

  const getBomEntries = async (productId, version, product) => {
    setBomError(false)
    let rows = [];
    if (version) {
      const data = await getRequest(`/api/${product.toLowerCase()}/versions/${version}/assemblybom/${productId}`);
      rows = data.map((d) => ({ id: d.PartId, name: d.ProductNameNL, qty: d.Quantity }));
    } else {
      const data = await getProductBom(productId);
      rows = data.map((d) => ({ id: d.SubOnderdelenId, name: d.subOnderdelenName, qty: d.Qty }));
    }
    if (rows.length === 0) setBomError(true)
    setPartsToBeConsumed(rows);
  };

  return (
    <Modal
      size="large"
      title="New work order"
      open={modalActive}
      onClose={handleClose}
      primaryAction={{
        content: "Add work order",
        loading: loading,
        disabled: bomError,
        onAction: async () => {
          if (partsToBeConsumed.length === 0) return setBomError(true)
          setLoading(true);
          await postRequest(`/api/workorders`, workOrder, setToast);
          handleClose();
        },
      }}>
      <Modal.Section>
        <BlockStack gap="400">
          <ButtonGroup variant="segmented">
            <Button
              size="large"
              pressed={!treeSelected}
              onClick={() => {
                changeType(false);
                setPartsToBeConsumed([]);
              }}>
              <Text as="p" fontWeight={!treeSelected ? "bold" : "regular"} tone={!treeSelected ? "success" : ""}>
                SPARE PARTS
              </Text>
            </Button>
            <Button
              size="large"
              pressed={treeSelected}
              onClick={() => {
                changeType(true);
                setPartsToBeConsumed([]);
              }}>
              <Text as="p" fontWeight={treeSelected ? "bold" : "regular"} tone={treeSelected ? "success" : ""}>
                DEVICE
              </Text>
            </Button>
          </ButtonGroup>

          {!treeSelected && <BomForm workOrder={workOrder} handleChange={handleChange} getBomEntries={getBomEntries} />}
          {treeSelected && <TreeForm workOrder={workOrder} handleChange={handleChange} getBomEntries={getBomEntries} />}

          {bomError && <Banner tone="warning">The selected part does not contain a bill of material, please choose a different part</Banner>}

          {partsToBeConsumed && partsToBeConsumed.length > 0 && (
            <BlockStack>
              <Box paddingBlockEnd="100">
                <Text variant="headingMd" as="h5">
                  BOM entries:
                </Text>
              </Box>
              <DataTable
                headings={["ID", "Name", "Qty per assembly", "Qty total"]}
                columnContentTypes={["text", "text", "numeric", "numeric"]}
                rows={partsToBeConsumed.map((part) => [
                  <Link removeUnderline url={`/products/${part.id}`}>
                    #{part.id}
                  </Link>,
                  part.name,
                  part.qty,
                  part.qty * workOrder.Qty,
                ])}
              />
            </BlockStack>
          )}
        </BlockStack>
      </Modal.Section>
    </Modal>
  );
};
