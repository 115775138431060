import { Form, FormLayout, InlineError, Modal, TextField } from "@shopify/polaris";
import { useEffect } from "react";

export const AddEditTranslationModal = ({ active, setActive, handleSave, translation, setTranslation, setValid, savingResult }) => {
  const handleChange = (e, id) => {
    setTranslation((translation) => ({
      ...translation,
      [id]: e !== "" ? parseInt(e) : e,
    }));
  };

  function isValueInvalid(content) {
    if (!content) return true;
    let arr = [];
    for (const [key, value] of Object.entries(content)) {
      if (key !== "pm_name" && key !== "schuil_name") {
        if (isNaN(value) || value === "" || value === null) {
          arr.push(key);
        }
      }
    }
    return arr.length > 0;
  }

  const isInvalid = isValueInvalid(translation);
  const errorMessage = isInvalid ? "All fields are required" : "";

  useEffect(() => {
    isInvalid ? setValid(false) : setValid(true);
  }, [isInvalid]);

  return (
    <Modal
      open={active}
      onClose={setActive}
      title={translation.OnderdelenTranslationID ? `Edit translation #${translation.OnderdelenTranslationID}` : "Add translation"}
      primaryAction={{ content: "Save", onAction: () => handleSave(translation), loading: savingResult }}>
      <Modal.Section>
        <Form onSubmit={() => handleSave(translation)}>
          <FormLayout>
            <FormLayout.Group condensed>
              <TextField value={translation.OnderdeelSchuilenburgID} onChange={handleChange} label="Schuild_ID" id="OnderdeelSchuilenburgID" type="number" error={isInvalid} />
              <TextField value={translation.ratio_schuilenburg} onChange={handleChange} label="Schuil_ratio" id="ratio_schuilenburg" type="number" error={isInvalid} />
              <TextField value={translation.ratio_aha} onChange={handleChange} label="PM_ratio" id="ratio_aha" type="number" error={isInvalid} />
              <TextField value={translation.OnderdeelAhaID} onChange={handleChange} label="PM_ID" id="OnderdeelAhaID" type="number" error={isInvalid} />
            </FormLayout.Group>
            <div style={{ marginTop: "4px" }}>
              <InlineError message={errorMessage} />
            </div>
          </FormLayout>
        </Form>
      </Modal.Section>
    </Modal>
  );
};
