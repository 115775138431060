import { BlockStack, InlineStack, Text } from "@shopify/polaris";

export const OperationSteps = () => {
  // const pdfLink = "wwww.placeholder.com";
  // if (!pdfLink) return <div>Loading...</div>;

  return (
    <BlockStack>
      <InlineStack align="center">
        <Text variant="heading2xl" as="h3">
          Operation steps:
        </Text>
      </InlineStack>

      {/* <div
        style={{
          height: "1000px",
          cursor: "help",
          width: "100%",
          marginTop: "20px",
        }}
      >
        <object
          style={{ cursor: "help" }}
          data={pdfLink}
          type="application/pdf"
          width="100%"
          height="100%"
        >
          <Text variant="bodyLg" as="p" alignment="center">
            It appears you don't have a PDF plugin for this browser.
          </Text>
          <br></br>
          <Text variant="bodyLg" as="p" alignment="center">
            <Link url={pdfLink}>Click here to download the PDF file.</Link>
          </Text>
        </object>
      </div> */}
    </BlockStack>
  );
};
