import { Badge, Box, InlineStack, Text } from "@shopify/polaris";

export const getBoxPitcherBadge = (pitcher) => {
  const type = pitcher.PitcherType;
  const pitcher_color_schema = { D: "⚪ White", V: "🟢 Green", C: "⚫ Black" };
  const pitcher_size_schema = { S: "35cl", M: "50cl", L: "75cl", X: "100cl" };

  return (
    <InlineStack gap="200">
      <Text>{type}</Text>
      <Box>
        <Badge>{`${pitcher_color_schema[type.substring(0, 1)]} ${pitcher_size_schema[type.substring(1, 2)]}`}</Badge>
      </Box>
    </InlineStack>
  );
};

export const getBoxStatusBadge = (pitcher) => {
  if (pitcher.Qty === pitcher.Added) return <Badge tone="success">✅ OK ✅</Badge>;
  if (pitcher.Qty > pitcher.Added) return <Badge tone="warning">➕ Add pitcher(s)</Badge>;
  return <Badge tone="critical">❌ TOO MANY ❌</Badge>;
};

export const getBoxNotValidBadge = (box, pitcherType) => {
  const allowed_types = box.schema.map((type) => {
    return type.PitcherType;
  });

  const not_valid = allowed_types.includes(pitcherType) ? null : (
    <Box>
      <Badge tone="critical">
        <b>NOT VALID</b>
      </Badge>
    </Box>
  );

  return (
    <InlineStack gap="100">
      {pitcherType}
      {not_valid}
    </InlineStack>
  );
};
