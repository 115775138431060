import { Text, ResourceList, ResourceItem, InlineStack } from "@shopify/polaris";
import { useState } from "react";
import { S3ImageViewer } from "../../../shared/components/s3_image_display/s3ImageUploader";

export const FeedbackHistory = ({ feedback, setFeedback, loaded, workstations, users }) => {
  const [sortValue, setSortValue] = useState("timestamp_desc");

  function renderItem(item) {
    const { id, WorkStationId, GebruikerID, text, pictures, timestamp } = item;
    const date = new Date(timestamp);

    const s3arr = pictures && pictures.length > 0 ? pictures.map((item) => item.key) : [];

    return (
      <ResourceItem id={id}>
        <InlineStack align="space-between">
          <Text variant="bodyLg" fontWeight="bold" as="h2">
            {workstations.length > 0 ? workstations.find((w) => w.id === WorkStationId).Name : ""}
          </Text>
          <Text variant="bodyLg" fontWeight="bold" as="h2">
            {date.toLocaleString("nl-BE")}
          </Text>
        </InlineStack>

        <InlineStack align="space-between">
          <p style={{ whiteSpace: "pre-wrap" }}>{text}</p>
          <Text variant="bodyMd" as="h3">
            {users.length > 0 && users.find((u) => u.GebruikerID === GebruikerID).Afkorting}
          </Text>
        </InlineStack>

        <S3ImageViewer S3ObjectKeys={JSON.stringify(s3arr)} viewOnly thumbNails displayDirection="horizontal" />
      </ResourceItem>
    );
  }

  return (
    <ResourceList
      loading={!loaded}
      resourceName={{ singular: "comment", plural: "comments" }}
      items={feedback}
      renderItem={renderItem}
      sortValue={sortValue}
      sortOptions={[
        { label: "Newest comment", value: "timestamp_asc" },
        { label: "Oldest comment", value: "timestamp_desc" },
      ]}
      onSortChange={(selected) => {
        setSortValue(selected);
        if (selected === "timestamp_desc") {
          let desc = feedback.sort((a, b) => (a.timestamp > b.timestamp ? 1 : b.timestamp > a.timestamp ? -1 : 0));
          setFeedback(desc);
        } else {
          let asc = feedback.sort((a, b) => (a.timestamp < b.timestamp ? 1 : b.timestamp < a.timestamp ? -1 : 0));
          setFeedback(asc);
        }
      }}
    />
  );
};
