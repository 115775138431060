import { Button, TextField, InlineStack } from "@shopify/polaris";
import { useState } from "react";
import { postRequest, putRequest } from "../../../shared/components/functions";
import { getProductBom } from "../../../database/db";

export const FulFillInput = ({ line, getSale, ahaInventory, ahaProductId, remaingToFulfill }) => {
  const [loading, setLoading] = useState(false);
  const [fullfillingQty, setFullfillingQty] = useState(remaingToFulfill);

  const maxToFulFill = ahaInventory < line.aantal ? ahaInventory : line.aantal;
  const qtyToProduce = ahaInventory >= line.aantal ? 0 : remaingToFulfill - ahaInventory - line.workorder_qty;

  const handleFulfillment = async () => {
    setLoading(true);
    const stockchange_payload = {
      part_id: ahaProductId,
      stock_diff: fullfillingQty,
      timestamp: new Date(),
      order_id: line.OrderbevestigingID,
    };
    await putRequest(`/api/fulfilment/schuilenburg-partialfulfill/${line.OrderbevestigingLijnID}`, stockchange_payload);
    getSale();
    setLoading(false);
  };

  return (
    <InlineStack gap="100">
      <TextField disabled={loading} type="number" onChange={setFullfillingQty} value={fullfillingQty} max={maxToFulFill} />
      <Button disabled={ahaInventory === 0 || remaingToFulfill === 0 || loading} variant="secondary" onClick={handleFulfillment}>{`FF (${fullfillingQty})`}</Button>
      {qtyToProduce > 0 && ahaProductId !== 833 && ahaProductId !== 834 && (
        <ProductionOrderButton qtyToProduce={qtyToProduce} salesOrderLineId={line.OrderbevestigingLijnID} ahaProductId={ahaProductId} inWorkOrder={line.workorder_qty} getSale={getSale} />
      )}
    </InlineStack>
  );
};

const ProductionOrderButton = ({ qtyToProduce, salesOrderLineId, ahaProductId, inWorkOrder, getSale }) => {
  const [loading, setLoading] = useState(false);
  /*
        CREATE NEW PRODUCTION
        -> insert the production order, link SALES_ORDER_LINE
        -> DO NOT assign yet to user
        -> do not yet assign to station_id
    */

  if (qtyToProduce === 0 && inWorkOrder > 0) return <span>{`${inWorkOrder} pcs in working orders`}</span>;
  if (qtyToProduce === 0) return null;

  const createProductionOrder = async () => {
    const confirmReply = confirm(`Do you want to create a work order for ${qtyToProduce} pieces?`);

    if (confirmReply) {
      const rows = await getProductBom(ahaProductId);
      if (rows.length === 0) return alert("❌ BOM for this part is missing, work order cannot be created!");

      setLoading(true);
      const body = {
        OnderdelenId: ahaProductId,
        CreatedByUserId: 1,
        Qty: qtyToProduce,
        OrderbevestigingLijnId: salesOrderLineId,
        Status: "OPEN",
        DueDate: new Date(),
        AssignmentDate: new Date(),
        CreatedAt: new Date(),
        VariantName: "",
        StructureName: "",
      };
      await postRequest(`/api/workorders`, body);
      await getSale();
      setLoading(false);
    }
  };

  return (
    <Button loading={loading} variant="primary" onClick={createProductionOrder}>
      {`PROD (${qtyToProduce})`}
    </Button>
  );
};
