import React, { useState, useCallback } from "react";
import { Button, TextField, Select, BlockStack } from "@shopify/polaris";
import { formatDate, patchRequest, postRequest } from "../../../shared/components/functions";
import { formatDateTime } from "../../../shared/components/functions";

export default function InventoryReceiving({ isOk, getPurchase, line, active, setActive, classTypes, ordered, reminderChecklist, toggleModal, S3ObjectKeys, setToasts }) {
  const [remarks, setRemarks] = useState("");
  const [amount, setAmount] = useState(String(ordered));
  const [loading, setLoading] = useState(false);
  const [classification, setClassification] = useState("1");

  const handleChange = useCallback((newValue) => setAmount(newValue), []);

  const reminder = isOk
    ? reminderChecklist
      ? "Make sure you read the checklist! Optionally provide some remarks here for this quality control:"
      : "Optionally provide some remarks here for this quality control:"
    : "Please explain what was wrong with the parts:";

  const stockReceivingDB = async () => {
    /*
          FIRST REGISTER THE STOCKCHANGE
          THEN REGISTER THE QUALITY CONTROL 
          DO NOT CHANGE THE STOCK
          TODOTODOTODO user_id change
      */
    const response = isOk ? window.confirm(`This will increase the stock with +${amount} pieces for part #${line.produktnr} ${line.ProductNameNL}`) : true;

    if (response) {
      var stockchange_payload = {
        part_id: line.produktnr,
        stock_diff: amount,
        timestamp: formatDate(new Date()),
        is_order: true,
        order_id: line.BestellingID,
        source: isOk ? `Stock in #${line.BestellingID} QC OK` : `Stock in ATTEMPT #${line.BestellingID} QC NOK`,
        stockchange_type_id: 4,
      };

      var qc_payload = {
        user_id: 69,
        timestamp: formatDateTime(new Date()),
        quality_passed: isOk ? true : false,
        note: remarks,
        stockchange_id: "",
        classification_id: isOk ? 10 : parseInt(classification), //general QC pass type id
        S3ObjectKeys: S3ObjectKeys,
      };

      setLoading(true);
      const stockchange = await postRequest(`/api/stockchanges`, stockchange_payload);
      if (!stockchange) return closeModal(stockchange);

      qc_payload.stockchange_id = stockchange.stockchange_id;
      const qcchange = await postRequest(`/api/qc`, qc_payload);
      if (!qcchange) return closeModal(stockchange, qcchange);

      let inventorychange;
      if (isOk) inventorychange = await patchRequest(`/api/inventory/${stockchange_payload.part_id}`, { stockdiff: stockchange_payload.stock_diff });
      closeModal("ok", "ok", inventorychange);
    }
  };

  const closeModal = (stockchange, qcchange, inventorychange) => {
    setToasts({
      stockToast: stockchange ? (stockchange === "ok" ? null : "ok") : "error",
      qcToast: qcchange ? (qcchange === "ok" ? null : "ok") : "error",
      inventoryToast: isOk ? (inventorychange ? "ok" : "error") : null,
    });
    getPurchase();
    setLoading(false);
    setActive(false);
    toggleModal(false);
  };

  return active ? (
    <BlockStack gap="300">
      <TextField label="Amount:" value={amount} type="number" autoComplete="off" onChange={handleChange} />
      {!isOk ? <Select label="Classification type:" value={classification} options={classTypes} onChange={(e) => setClassification(e)} /> : null}
      <TextField label={reminder} value={remarks} onChange={(e) => setRemarks(e)} placeholder="Extra remarks" multiline={3} />
      <BlockStack inlineAlign="end">
        <Button variant="primary" tone={isOk ? "" : "critical"} loading={loading} onClick={stockReceivingDB}>
          {isOk ? "Accept" : "Reject"} parts
        </Button>
      </BlockStack>
    </BlockStack>
  ) : null;
}
