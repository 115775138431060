import React, { useEffect, useState } from "react";
import { Card, Button, TextField, Form, Text, Modal } from "@shopify/polaris";
import { DeleteIcon } from "@shopify/polaris-icons";
import { handleSaveOnEnter, postRequest, putRequest } from "../../../shared/components/functions";
import { useParams } from "react-router-dom";
import Select from "react-select";
import { useLookupStore } from "../../../utils/useStore";
import { CardHeading } from "../../../shared/components/CardHeading";

export const ExternalInventoryTable = ({ fullInventory, fetchInventory, fetchStockHistory, setToast }) => {
  let { id } = useParams();
  const { pickingplaces } = useLookupStore();
  const [inventories, setInventories] = useState([]);
  const [isDirty, setIsDirty] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isFirstInventory, setIsFirstInventory] = useState(false);
  const [stockchangeModalOpen, setStockchangeModalOpen] = useState(false);
  const [stockchangeReason, setStockchangeReason] = useState("");
  const [stockchangeInhouse, setStockchangeInhouse] = useState(false);

  useEffect(() => {
    if (fullInventory.length > 0) setInventories(fullInventory);
  }, [fullInventory]);

  const inventoryInterface = {
    Productid: id,
    Stockplaats: null,
    aantal: 0,
    counter: null,
    Rack: 0,
    Legplank: 0,
    pickingplace: null,
  };

  const addEmptyLine = () => setInventories((old) => [...old, inventoryInterface]);

  const removeLineByIndex = (rowIndex) => {
    setInventories((old) => old.filter((row, index) => index !== rowIndex));
    setIsDirty(true);
  };

  const lineUpdate = (value, columnId, rowIndex, place) => {
    if (columnId === "aantal" && place === 1) {
      value = value.replace(/[^0-9.,-]/g, "").replace(/,/g, ".");
      if (place === 1) setStockchangeInhouse(true);
      const dotCount = value.split(".").length - 1;
      if (dotCount > 1) value = value.slice(0, -1);
    }
    if (columnId === "Stockplaats") value = value.value;
    setInventories((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
            lineIsDirty: true, //will be used to process in API
          };
        }
        return row;
      })
    );
    setIsDirty(true);
  };

  const handleDiscard = () => {
    setInventories(fullInventory);
    setIsDirty(false);
    setStockchangeModalOpen(false);
    setStockchangeReason("");
    setIsSaving(false);
  };

  const handleSave = async () => {
    setIsSaving(true);

    if (!inventories.find((i) => i.Stockplaats === 1)) {
      alert("Attention: the inventory line for Magazijn PM is missing. Please add it to the inventory (even if quantity is 0).");
      return setIsSaving(false);
    }

    if (stockchangeInhouse && stockchangeReason === "") {
      setStockchangeInhouse(false);
      return setStockchangeModalOpen(true);
    }
    setStockchangeModalOpen(false);
    setStockchangeReason("");

    let allInventories = [];
    if (inventories.find((i) => !i.aantal)) {
      allInventories = inventories.filter((i) => !i.aantal).map((i) => (i.aantal = 0));
    }

    let response;

    if (!isFirstInventory) {
      response = await putRequest(`/api/inventory/dump/lines/${id}`, inventories, setToast);
    } else {
      response = await postRequest("/api/inventory", {
        productId: id,
        stockplaats: inventories[0].Stockplaats,
        rack: inventories[0].Rack,
        legplank: inventories[0].Legplank,
        aantal: inventories[0].aantal || 0,
      });
    }

    if (response) {
      const mainInventoryStock = inventories.find((p) => p.Stockplaats === 1);

      if (mainInventoryStock) {
        const initialInventoryStock = fullInventory.find((p) => p.Stockplaats === 1);

        const mainStockChanges = !isFirstInventory ? initialInventoryStock.aantal !== Number(mainInventoryStock.aantal) : true;

        if (mainStockChanges) {
          const payload = {
            part_id: mainInventoryStock.Productid,
            stock_diff: !isFirstInventory ? Number(mainInventoryStock.aantal) - initialInventoryStock.aantal : Number(mainInventoryStock.aantal),
            timestamp: new Date(),
            stockchange_type_id: 1,
            source: stockchangeReason,
          };

          await postRequest("/api/stockchanges", payload);
          fetchStockHistory();
        }
      }

      setInventories(response);
      fetchInventory();
    }

    setIsDirty(false);
    setIsSaving(false);
    setIsFirstInventory(false);
  };

  const pickOptions = pickingplaces.map((place) => ({ value: place.counter, label: place.pickingplace }));

  let inventoryActions = [
    {
      content: "Add inventory place",
      onAction: () => {
        if (inventories.length === 0) setIsFirstInventory(true);
        addEmptyLine();
      },
      disabled: id === "new" || isFirstInventory,
      variant: "secondary",
    },
  ];
  if (isDirty) {
    inventoryActions.push(
      { content: "Cancel", onAction: handleDiscard, variant: "primary", tone: "critical", disabled: isSaving },
      { content: "Save", onAction: handleSave, variant: "primary", loading: isSaving, disabled: !isDirty }
    );
  }

  return (
    <Card>
      <CardHeading title="Product inventories" actions={inventoryActions} />
      <Modal
        open={stockchangeModalOpen}
        onClose={handleDiscard}
        title={`Add a reason for the ${pickingplaces.find((p) => p.counter === 1).pickingplace} stock change`}
        primaryAction={{ content: "Save", onAction: handleSave, disabled: !stockchangeReason }}>
        <Modal.Section>
          <div
            style={{ width: "500px" }}
            onKeyDown={(e) => {
              if (stockchangeReason) handleSaveOnEnter(e, handleSave);
            }}>
            <TextField focused label="Reason" labelHidden id="Source" value={stockchangeReason} onChange={(e) => setStockchangeReason(e)} />
          </div>
        </Modal.Section>
      </Modal>

      <Form>
        {inventories.length > 0 ? (
          <table style={{ width: "100%", margin: "20px auto auto -3px" }}>
            <thead>
              <tr>
                <th>
                  <TableHeader text="Place" />
                </th>
                <th>
                  <TableHeader text="Qty" />
                </th>
                <th>
                  <TableHeader text="Rack" />
                </th>
                <th>
                  <TableHeader text="Shelf" />
                </th>
                <th>
                  <TableHeader text="Remove" align="end" />
                </th>
              </tr>
            </thead>
            <tbody>
              {inventories.map((inventory, index) => {
                return (
                  <tr key={index}>
                    <td style={{ width: "40%" }}>
                      <Select
                        menuPortalTarget={document.body}
                        id="Stock"
                        isDisabled={inventory.Stockplaats === 1}
                        options={pickOptions.filter((p) => !inventories.map((i) => i.Stockplaats).includes(p.value))}
                        onChange={(value) => lineUpdate(value, "Stockplaats", index)}
                        value={pickOptions.find((pl) => pl.value === inventory.Stockplaats)}
                        styles={{
                          valueContainer: (base) => ({ ...base, height: "32px" }),
                          indicatorSeperator: (base) => ({ ...base, height: "32px" }),
                          indicatorsContainer: (base) => ({ ...base, height: "32px" }),
                          control: (base) => ({ ...base, height: "32px", minHeight: "32px", borderRadius: "8px", borderColor: "#919191" }),
                          menu: (provided) => ({ ...provided, zIndex: 9999 }),
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                    </td>
                    <td>
                      <TextField id="Quantity" value={inventory.aantal} onChange={(value) => lineUpdate(value, "aantal", index, inventory.Stockplaats)} />
                    </td>
                    <td>
                      <TextField id="Rack" onChange={(value) => lineUpdate(value, "Rack", index)} type="number" value={String(inventory.Rack)} />
                    </td>
                    <td>
                      <TextField id="Shelf" onChange={(value) => lineUpdate(value, "Legplank", index)} type="number" value={String(inventory.Legplank)} />
                    </td>
                    <td style={{ width: "20px" }}>
                      <Button icon={DeleteIcon} onClick={() => removeLineByIndex(index)} disabled={inventory.Stockplaats === 1} fullWidth />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : null}
      </Form>
    </Card>
  );
};

const TableHeader = ({ text, align }) => {
  return (
    <Text variant="headingMd" as="p" alignment={align || "start"}>
      {text}
    </Text>
  );
};
