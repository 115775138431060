import React, { useEffect, useState } from "react";
import { BlockStack, Modal, Spinner, TextField } from "@shopify/polaris";
import { getRequest, postRequest } from "../../../shared/components/typedFunctions";
import { useAuthStore } from '../../../utils/useStore'
import { Product } from "../../../types/mainTypes";

type CheckModalProps = {
    modalOpen: boolean;
    hideModal: () => void;
    fetchData: () => void;
    data: { product: string, version: string, idToToggle: string, part: string, parentRowId: string, exists: boolean };
};

export default function CheckModal({ modalOpen, hideModal, fetchData, data }: CheckModalProps) {
    const { user } = useAuthStore();
    const [loaded, setLoaded] = useState<boolean>(false)
    const [products, setProducts] = useState<Product[]>([])
    const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(false);
    const [log, setLog] = useState({ user: user?.id, changeType: 'variant', userlog: '', version: data.version, product: data.product.toLowerCase(), productName: '', productId: data.part, parentRowId: data.parentRowId, exists: data.exists })

    useEffect(() => {
        async function fetchData() {
            setProducts(await getRequest(`/api/products?page=1&limit=10000`));
            setLoaded(true)
        }
        fetchData();
    }, []);

    useEffect(() => {
        if (!products || products.length < 1) return
        const filteredPart = products.filter((product) => product?.ProductID === parseInt(data.part))[0];
        setLog({ ...log, productName: filteredPart?.ProductNameNL || '' })
    }, [products]);

    const handleSave = async () => {
        setAddButtonDisabled(true);
        await getRequest(`/api/${log.product}/toggle/${log.version}/${data.idToToggle}`);
        await postRequest('/api/changelog', { ...log, date: new Date() })
        setAddButtonDisabled(false);
        fetchData();
        hideModal();
    }

    if (!loaded) return (<Modal open={modalOpen} onClose={hideModal} title="Loading..."><Modal.Section><Spinner size='small' /></Modal.Section></Modal>)

    return (
        <Modal
            open={modalOpen}
            onClose={hideModal}
            title='Add changelog'
            primaryAction={{ content: "Save", onAction: handleSave, disabled: addButtonDisabled }}
            secondaryActions={[{ content: "Cancel", onAction: hideModal }]}
        >
            <Modal.Section>
                <BlockStack gap='200'>
                    <TextField label='Additional information (optional)' id='userlog' onChange={(value) => setLog((log) => ({ ...log, userlog: value }))} value={log.userlog} multiline={2} autoComplete="off" />
                </BlockStack>
            </Modal.Section>
        </Modal>
    );
}
