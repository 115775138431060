import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Filters, DataTable, Page, Card, Link } from "@shopify/polaris";
import PopoverComponent from "./components/popoverComponent";
import QuickViewPurchaseOrderComponent from "./components/quickViewComponent";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { formatToCurrency, getRequest, getTableFooter, pad_with_zeroes } from "../../shared/components/functions";
import { getAanbodBadge, getQcBadge, getStatusBadge } from "./components/badges";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function PurchasesPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const deliveryOptions = [
    { label: "Received", value: "0" },
    { label: "Awaiting", value: "1" },
    { label: "Cancelled", value: "2" },
  ];

  const qcOptions = [
    { label: "Done", value: "0" },
    { label: "Partially", value: "1" },
    { label: "Awaiting QC", value: "2" },
    { label: "Awaiting delivery", value: "3" },
    { label: "Canceled", value: "4" },
  ];

  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
      ["deliveryTerm", { type: "text", name: "Delivery term", value: url.get("deliveryTerm") || "" }],
      ["supplier", { type: "text", name: "Supplier", value: url.get("supplier") || "" }],
      ["delivery", { type: "multi_id", name: "Delivery status", value: url.get("delivery") || "", options: deliveryOptions }],
      ["qc", { type: "multi_id", name: "QC status", value: url.get("qc") || "", options: qcOptions }],
    ])
  );

  const fetchItems = useCallback(async () => {
    let filterString = `${filtersToApiUrl(filters)}`;
    const data = await getRequest(`/api/purchases?${filterString}`);
    setItems(data);
    setLoaded(true);
    if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
  }, [filters]);

  useEffect(() => {
    if (filters) fetchItems();
  }, [filters]);

  const SharePointFactuurTranslations = {
    1: "Bestelling",
    2: "Commande",
    3: "Order",
    4: "Bestellung",
  };

  const rows = Array.isArray(items)
    ? items.map((item) => {
        const date_today = new Date();
        const date_order = new Date(item.Datum);
        const diffTime = Math.abs(date_today - date_order);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return [
          <Link removeUnderline url={`/purchases/${item.BestellingID}`}>
            {item.Bestellingnummer}
          </Link>,
          <QuickViewPurchaseOrderComponent id={item.BestellingID} />,
          <a
            className="Polaris-Link Polaris-Link--removeUnderline"
            target="_blank"
            href={`https://schuilenburg.sharepoint.com/Aha%20Innovations//${pad_with_zeroes(item.Klantnummer, 5)}/Bestelling/${item.Bestellingnummer}/${SharePointFactuurTranslations[item.TaalID]}%20${
              item.Bestellingnummer
            }.pdf`}>
            {"PDF"}
          </a>,
          String(item.Datum).substring(0, 10),
          getAanbodBadge(item, diffDays),
          item.Leveringstermijn ? String(item.Leveringstermijn).substring(0, 10) : "",
          <Link removeUnderline url={`/companies/${item.LeverancierID}`}>
            {String(item.supplier_name).substring(0, 20)}
          </Link>,
          item.RefKlantDatum ? String(item.RefKlantDatum).substring(0, 10) : "",
          formatToCurrency(item.total_value),
          getStatusBadge(item),
          getQcBadge(item),
          <PopoverComponent id={item.BestellingID} delivered={item.Ontvangen} cancelled={item.canceled} reloadOrders={fetchItems} ordernumber={item.Bestellingnummer} />,
        ];
      })
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="Purchase orders" fullWidth>
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter purchases"
        />
        <DataTable
          verticalAlign="middle"
          increasedTableDensity
          columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "numeric", "text", "text", "text"]}
          headings={[
            <b>Number</b>,
            <b>View</b>,
            <b>PDF</b>,
            <b>Date</b>,
            <b>Offer</b>,
            <b>Delivery term</b>,
            <b>Supplier</b>,
            <b>Date workshop</b>,
            <b>Price</b>,
            <b>Delivery</b>,
            <b>QC</b>,
            <b>Actions</b>,
          ]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "purchases")}
        />
      </Card>
    </Page>
  );
}
