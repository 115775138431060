import { useState, useCallback, useEffect } from "react";
import { Modal, Button, Link, ButtonGroup, DataTable, Spinner } from "@shopify/polaris";
import { MaximizeIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { useToggle } from "../../../shared/hooks/useToggle";
import { getRequest, patchRequest, truncate } from "../../../shared/components/functions";
import { getStillOnOrderBadge } from "./badges";
// import RichLinkEditor from '../../../shared/components/editors/RichLinkEditor'

export default function QuickViewPurchaseOrderComponent({ id }) {
  const navigate = useNavigate();

  const [note, setNote] = useState("");
  const [initialNote, setInitialnote] = useState("");
  const [popoverActive, setPopoverActive] = useToggle(false);
  const [purchase, setPurchase] = useState({ purchase: "", lines: [], qcs: [] });
  const [dataLoaded, setDataLoaded] = useState(false);

  const getPurchase = useCallback(async () => {
    const data = await getRequest(`/api/purchases/${id}`);
    setPurchase(data);
    setDataLoaded(true);
  });

  useEffect(() => {
    if (popoverActive) getPurchase();
  }, [popoverActive]);

  async function updatePurchaseNote() {
    await patchRequest(`/api/purchases/${id}`, { note: JSON.stringify(note) });
  }

  const activator = <Button icon={MaximizeIcon} size="slim" onClick={setPopoverActive} />;

  const rows = Array.isArray(purchase.lines)
    ? purchase.lines.map((line) => {
        let numberFormatter = new Intl.NumberFormat("en-US");
        return [
          truncate(line.Productref, 15),
          <Link removeUnderline url={`/products/${line.produktnr}`}>
            {line.produktnr}
          </Link>,
          truncate(line.tekstlijn, 15),
          line.Rack,
          line.Legplank,
          numberFormatter.format(line.aantal),
          getStillOnOrderBadge(line.StillOnOrder),
        ];
      })
    : [];

  const save_button =
    JSON.stringify(note) !== JSON.stringify(initialNote) ? (
      <ButtonGroup>
        <Button
          variant="primary"
          size="slim"
          onClick={() => {
            updatePurchaseNote();
            getPurchase();
          }}>
          Save Changes
        </Button>
        <Button size="slim" onClick={() => setNote(initialNote)}>
          Cancel
        </Button>
      </ButtonGroup>
    ) : null;

  return (
    <Modal
      size="large"
      open={popoverActive}
      activator={activator}
      onClose={setPopoverActive}
      title={`Purchase order #${purchase.purchase.Bestellingnummer}`}
      primaryAction={{ content: "Open order", onAction: () => navigate(`/purchases/${purchase.purchase.BestellingID}`) }}>
      {/* <Modal.Section><RichLinkEditor content={note} setContent={setNote} />{save_button}</Modal.Section> */}
      <Modal.Section>
        {dataLoaded ? (
          <DataTable
            verticalAlign="middle"
            increasedTableDensity
            columnContentTypes={["text", "text", "text", "text", "text", "numeric", "numeric"]}
            headings={["Reference", "Code", "Description", "Rack", "Shelf", "Ordered", "Incoming"]}
            rows={rows}
          />
        ) : (
          <Spinner size="small" />
        )}
      </Modal.Section>
    </Modal>
  );
}
