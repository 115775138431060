import { useState, useEffect } from "react";
import { Badge, DataTable, Tooltip, Link, Page, Card, Filters, Text } from "@shopify/polaris";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, getTableFooter, truncate } from "../../shared/components/functions";
import { getStatusBadge } from "./components/qcbadges";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function QCPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const statusOptions = [
    { label: "All", value: "all" },
    { label: "QC passed", value: "0" },
    { label: "QC failed", value: "1" },
    { label: "QC resolved", value: "2" },
  ];

  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
      ["user", { type: "text", name: "User", value: url.get("user") || "" }],
      ["name", { type: "text", name: "Part", value: url.get("name") || "" }],
      ["supplier", { type: "text", name: "Supplier", value: url.get("supplier") || "" }],
      ["qcstatus", { type: "status", name: "Status", value: url.get("qcstatus") || "all", options: statusOptions }],
    ])
  );

  useEffect(() => {
    async function fetchData() {
      let filterString = filtersToApiUrl(filters);
      const data = await getRequest(`/api/qc?${filterString}`);
      setItems(data);
      setLoaded(true)
      if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    }
    fetchData();
  }, [filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        return [
          <Link removeUnderline url={`/qc/${item.ID}`}>
            {item.ID}
          </Link>,
          <Badge>{item.user.abbreviation}</Badge>,
          <Link removeUnderline url={`/purchases/${item.stockchange.order_id}`}>
            Order
          </Link>,
          String(item.Timestamp).substring(0, 10),
          <Badge>{item.classification_type.classification}</Badge>,
          item.stockchange.quantity,
          item.stockchange.product.id,
          <Link removeUnderline url={`/products/${item.stockchange.product.id}`}>
            {String(item.stockchange.product.name).substring(0, 50)}
          </Link>,
          <Link removeUnderline url={`/companies/${item.stockchange.product.id}`}>
            {String(item.stockchange.product.supplier.name).substring(0, 45)}
          </Link>,
          <Tooltip content={item.Note}>
            <Text>{truncate(item.Note, 5, false)}</Text>
          </Tooltip>,
          getStatusBadge(item),
        ];
      })
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="Quality control" fullWidth>
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter QC"
        />
        <DataTable
          verticalAlign="middle"
          columnContentTypes={["text", "text", "text", "text", "text", "numeric", "numeric", "text", "text", "text", "text", "text"]}
          headings={[<b>ID</b>, <b>User</b>, <b>Order</b>, <b>Date</b>, <b>Category</b>, <b>Quantity</b>, <b>Code</b>, <b>Part</b>, <b>Supplier</b>, <b>Note</b>, <b>Status</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "QCs")}
        />
      </Card>
    </Page>
  );
}
