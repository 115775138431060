import { useState, useEffect } from "react";
import { DataTable, Link, Button, Card, Text } from "@shopify/polaris";
import { getRequest } from "../../../shared/components/functions";
import { PageSpinner } from "../../../shared/components/spinner/PageSpinner";

export default function FulfilmentProduct() {
  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/fulfilment/product`);
      setItems(data);
      setLoaded(true);
    }
    fetchData();
  }, []);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        return [
          <Link url={`/products/${item.ahaProductID}`}>{item.ahaProductID}</Link>,
          item.ProductID,
          item.ProductNameNL,
          item.to_fulfill,
          <Text as="p" fontWeight="bold" tone={item.aantal > item.to_fulfill ? "success" : "critical"}>
            {Math.round(item.aantal * 100) / 100}
          </Text>,
          item.aantal < item.to_fulfill ? <Button>{`PROD (${item.to_fulfill - item.aantal})`}</Button> : "",
        ];
      })
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Card>
      <DataTable
        verticalAlign="middle"
        columnContentTypes={["text", "text", "text", "text"]}
        headings={[<b>Product ID</b>, <b>Schuilenburg</b>, <b>Product description</b>, <b>To fulfill</b>, <b>Physical stock</b>, <b>Actions</b>]}
        rows={rows}
      />
    </Card>
  );
}
