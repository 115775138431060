import { InlineStack, Text } from "@shopify/polaris";
import Select from "react-select";
import { cableOptions, colorOptions, modelInfo, modelOptions, variantOptions } from "./helpers";
import { getAlphabet } from "../functions";

export const ModelString = ({ modelString, setModelString, printLabel, setPrintLabel, disabled, updateSerialString, serialError, setModelStringTouched }) => {
  const alphabet = getAlphabet();

  const handleModelStringChange = (value, id, displayValueAsLabel) => {
    if (updateSerialString) setModelStringTouched(true);

    setModelString((s) => ({
      ...s,
      [id]: displayValueAsLabel ? { label: value.value, value: value.value } : value,
    }));

    if (id === "model" && printLabel) {
      printLabel = {
        ...printLabel,
        Model: value.value,
        RfidModelId: modelInfo[value.value].RfidModelId,
        TechnicalInfo: modelInfo[value.value].TechnicalInfo,
      };
      setPrintLabel(printLabel);
    }
  };

  const modelStringComponents = [
    { id: "model", displayValueAsLabel: false, options: modelOptions },
    { id: "cable", displayValueAsLabel: true, options: cableOptions },
    {
      id: "letter",
      displayValueAsLabel: false,
      options: alphabet.map((a) => {
        return { value: a, label: a };
      }),
    },
    { id: "color", displayValueAsLabel: true, options: colorOptions },
    { id: "variant", displayValueAsLabel: true, options: variantOptions },
  ];

  const ComboInput = ({ id, options, displayValueAsLabel, disabled }) => {
    return (
      <div style={{ width: "100%" }}>
        <Select
          id={id}
          value={modelString[id]}
          options={options}
          isDisabled={disabled}
          onChange={(e) => handleModelStringChange(e, id, displayValueAsLabel)}
          menuPortalTarget={document.body}
          styles={{
            valueContainer: (base) => ({ ...base, height: "36px" }),
            indicatorSeperator: (base) => ({ ...base, height: "36px" }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menuList: (base) => ({ ...base, zIndex: 9999 }),
            dropdownIndicator: (base) => ({ ...base, display: "none" }),
            indicatorsContainer: (base) => ({ ...base, display: "none" }),
            menu: (base) => ({ ...base, width: "120px", zIndex: 9999 }),
            control: (base) => ({
              ...base,
              minWidth: id === "model" ? "70px" : "40px",
              borderColor: serialError ? "red" : "darkgray",
            }),
          }}
        />
      </div>
    );
  };

  return (
    <div style={updateSerialString ? { height: "90px", marginBottom: "-20px" } : {}}>
      <div style={{ marginBottom: "3px" }}>Code</div>
      <InlineStack wrap={false} gap="200" blockAlign="center">
        {modelStringComponents.map((c, idx) => {
          return (
            <InlineStack key={idx} wrap={false} gap="200" blockAlign="center">
              <ComboInput id={c.id} options={c.options} displayValueAsLabel={c.displayValueAsLabel} disabled={disabled} />
              {c.id === "model" || c.id === "color" ? <Text>-</Text> : null}
            </InlineStack>
          );
        })}
      </InlineStack>
      {serialError ? <div style={{ marginTop: "3px", color: "red" }}>All fields are required</div> : null}
    </div>
  );
};
