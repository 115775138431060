import { Banner, Box, Button, Card, DataTable, FormLayout, Link, Modal, Text, TextField } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { addBomEntry, deleteBomEntry, getProductBom, updateBomEntryQty } from "../../../database/db";
import ProductSelect from "../../../shared/components/productSelect";
import { CardHeading } from "../../../shared/components/CardHeading";
import { useToggle } from "../../../shared/hooks/useToggle";
import { DeleteIcon } from "@shopify/polaris-icons";
import { getRequest } from "../../../shared/components/functions";

export const BomManager = ({ productId }) => {
  const [bom, setBom] = useState([]);
  const [modalOpen, setModalOpen] = useToggle(false);

  const fetchData = useCallback(async () => {
    let bom = await getProductBom(productId);
    if (Array.isArray(bom)) {
      for (let i = 0; i < bom.length; i++) {
        const product = bom[i];
        const inventoryData = await getRequest(`/api/inventory/${product.SubOnderdelenId}`);
        product.stockplaces = inventoryData.filter((d) => d.Stockplaats === 1 || d.Stockplaats === 9).map((d) => ({ place: d.Stockplaats, value: d.aantal }));
        product.total = product.SubOnderdelenId > 50 ? Math.round(100 * product.stockplaces.reduce((acc, curr) => acc + curr.value, 0)) / 100 : null;
        product.sufficiency = product.SubOnderdelenId > 50 ? Math.floor(product.total / product.Qty) : null;
      }
      setBom(bom);
    }
  }, [productId]);

  useEffect(() => {
    fetchData();
  }, [productId]);

  return (
    <Card>
      <CardHeading title="Bill of material" actions={[{ content: "+ Add product to bill of material", onAction: setModalOpen, variant: "secondary" }]} />
      <AddBomEntryModal active={modalOpen} setActive={setModalOpen} productId={productId} callBack={fetchData} />

      {bom.length > 0 && (
        <Box>
          <Banner>{`Current inventory is sufficient for producing ${Math.min(...bom.filter((b) => b.sufficiency).map((b) => b.sufficiency))} items.`}</Banner>
          <DataTable
            verticalAlign="middle"
            increasedTableDensity
            rows={bom.map((b) => {
              const getQtyPerPlace = (placeId) => {
                if (b.SubOnderdelenId > 50) {
                  const value = Math.round(100 * (b.stockplaces.find((s) => s.place === placeId)?.value || 0)) / 100;
                  return value.toString().replace(".", ",");
                }
              };
              return [
                <Link removeUnderline url={`/products/${b.SubOnderdelenId}`}>
                  #{b.SubOnderdelenId}
                </Link>,
                b.subOnderdelenName,
                <BomQtyEntry bomEntryId={b.id} qty={b.Qty} />,
                getQtyPerPlace(1),
                getQtyPerPlace(9),
                b.total?.toString().replace(".", ","),
                b.sufficiency,
                <Button
                  tone="critical"
                  size="slim"
                  icon={DeleteIcon}
                  onClick={async () => {
                    await deleteBomEntry(b.id);
                    await fetchData();
                  }}
                />,
              ];
            })}
            columnContentTypes={["text", "text", "text", "numeric", "numeric", "numeric", "numeric", "numeric"]}
            headings={["ID", "Name", "Qty", "Inv warehouse", "Inv Trianval", "Total", "Sufficient", "Actions"]}
          />
        </Box>
      )}
    </Card>
  );
};

const BomQtyEntry = ({ bomEntryId, qty }) => {
  const [value, setValue] = useState(qty);
  return (
    <div style={{ width: "100px" }}>
      <TextField size="slim" type="number" value={value} onChange={(newValue) => setValue(newValue)} onBlur={() => updateBomEntryQty(bomEntryId, value)} />
    </div>
  );
};

const AddBomEntryModal = ({ active, setActive, productId, callBack }) => {
  const [selectedProduct, setSelectedProduct] = useState({ label: "", value: null });
  const [qty, setQty] = useState(1);
  const [loading, setLoading] = useState(false);

  return (
    <Modal
      size="large"
      open={active}
      onClose={setActive}
      title="Add bill of material entry"
      primaryAction={{
        content: "Add product",
        loading: loading,
        onAction: async () => {
          setLoading(true);
          await addBomEntry(productId, selectedProduct, qty);
          await callBack();
          setQty(1);
          setActive(false);
          setLoading(false);
        },
      }}>
      <Modal.Section>
        <FormLayout>
          <Box>
            <Box paddingBlockEnd="100">
              <Text>Product to attach</Text>
            </Box>
            <ProductSelect value={selectedProduct.value} onChange={setSelectedProduct} isClearable={false} />
          </Box>
          <TextField label="Qty" type="number" value={qty} onChange={setQty} />
        </FormLayout>
      </Modal.Section>
    </Modal>
  );
};
