import { DescriptionList, TextField } from "@shopify/polaris";
import { formatDate } from "../../shared/components/functions";
import Select from "react-select";

export const CheckListEdit = ({ checkList, setCheckList, setIsDirty, workstationOptions }) => {
  const handleChange = (value, id) => {
    if (id === "workstationId") value = value.value;
    setCheckList((checkList) => ({ ...checkList, [id]: value }), setIsDirty(true));
  };

  return (
    <DescriptionList
      items={[
        {
          term: "Name",
          description: <TextInputField value={checkList.name} handleChange={handleChange} id="name" />,
        },
        {
          term: "Description",
          description: <TextField value={checkList.description} onChange={handleChange} id="description" multiline={5} label="" labelHidden />,
        },
        {
          term: "Workstation",
          description: (
            <div style={{ margin: "-5px auto" }}>
              <Select
                id="workstationId"
                options={workstationOptions}
                menuPortalTarget={document.body}
                onChange={(e) => handleChange(e, "workstationId")}
                value={workstationOptions.find((w) => w.value === checkList.workstationId)}
                styles={{
                  valueContainer: (base) => ({ ...base, height: "32px" }),
                  indicatorSeperator: (base) => ({ ...base, height: "32px" }),
                  indicatorsContainer: (base) => ({ ...base, height: "32px" }),
                  control: (base) => ({ ...base, height: "32px", minHeight: "32px", borderRadius: "8px", borderColor: "#919191" }),
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                }}
              />
            </div>
          ),
        },
        {
          term: "Variant",
          description: <TextInputField value={checkList.variantRegex} handleChange={handleChange} id="variantRegex" />,
        },
        { term: "Created by", description: checkList.Afkorting },
        { term: "Created at", description: formatDate(checkList.createdAt) },
        { term: "Updated at", description: formatDate(checkList.updatedAt) },
      ]}
    />
  );
};

const TextInputField = ({ value, handleChange, id }) => {
  return (
    <div style={{ margin: "-5px auto" }}>
      <TextField value={value} onChange={handleChange} label="" labelHidden id={id} />
    </div>
  );
};
