import { Pagination, BlockStack } from "@shopify/polaris";

async function getRequest(url) {
  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser")).token,
      "Content-Type": "application/json",
    }),
  });
  const data = await response.json();
  return data;
}

async function getRequestWithSignal(url, signal) {
  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser") || "{}").token, "Content-Type": "application/json" }),
    signal: signal,
  });
  if (!response.ok) return console.log(response);
  return response.json();
}

async function postRequest(url, payload, setToast = console.log) {
  const response = await fetch(url, {
    method: "POST",
    headers: new Headers({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser")).token,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(payload),
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    setToast("ok");
    return data;
  } else {
    return setToast("error");
  }
}

async function patchRequest(url, payload, setToast = console.log) {
  const response = await fetch(url, {
    method: "PATCH",
    headers: new Headers({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser")).token,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(payload),
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    setToast("ok");
    return data;
  } else {
    return setToast("error");
  }
}

async function putRequest(url, payload, setToast = console.log) {
  const response = await fetch(url, {
    method: "PUT",
    headers: new Headers({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser")).token,
      "Content-Type": "application/json",
    }),
    body: JSON.stringify(payload),
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    setToast("ok");
    return data;
  } else {
    return setToast("error");
  }
}

async function deleteRequest(url, setToast = console.log) {
  const response = await fetch(url, {
    method: "DELETE",
    headers: new Headers({
      Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser")).token,
      "Content-Type": "application/json",
    }),
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    setToast("ok");
    return data;
  } else {
    return setToast("error");
  }
}

function pad_with_zeroes(number, length) {
  var my_string = "" + number;
  while (my_string.length < length) {
    my_string = "0" + my_string;
  }
  return my_string;
}

function sum(a, b) {
  return a + b;
}

var currencyFormatter = new Intl.NumberFormat("nl-BE", { style: "currency", currency: "EUR" });

function formatToCurrency(value) {
  return currencyFormatter.format(value);
}

function formatDate(date) {
  //formats date to YYYY-MM-DD
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
}

//ROUNDING WEIGHT
var weightFormatter = new Intl.NumberFormat("nl-BE", {
  style: "unit",
  unit: "kilogram",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

const formatRoundedWeight = (weight) => {
  if (!weight && weight === null) weight = 0;
  return weightFormatter.format(weight);
};

Number.prototype.padLeft = function (base, chr) {
  var len = String(base || 10).length - String(this).length + 1;
  return len > 0 ? new Array(len).join(chr || "0") + this : this;
};

function formatDateTime(d) {
  //formats date to YYYY-MM-DD HH:MM:SS:00
  return [d.getFullYear(), (d.getMonth() + 1).padLeft(), d.getDate().padLeft()].join("-") + " " + [d.getHours().padLeft(), d.getMinutes().padLeft(), d.getSeconds().padLeft()].join(":");
}

export const getDateFromDatetime = (datetime) => {
  return datetime.slice(0, datetime.indexOf(","));
};

function formatDateFilter(datetime) {
  const date = getDateFromDatetime(datetime);
  return date.split("/").reverse().join("-");
}

function getTableFooter(rows, page, limit, setFilters, pluralResourceName) {
  if (rows.length > 0) {
    return (
      <BlockStack inlineAlign="center">
        <div style={{ marginBottom: "10px" }}>
          Displaying {rows.length} {pluralResourceName}
        </div>
        <Pagination
          hasPrevious={page.value > 1}
          onPrevious={() => {
            if (page.value > 1) {
              setFilters((map) => new Map(map.set("page", { ...page, value: Number(page.value) - 1, tagVisible: false })));
            }
          }}
          hasNext={rows.length > Number(limit.value) - 1}
          onNext={() => {
            setFilters((map) => new Map(map.set("page", { ...page, value: Number(page.value) + 1, tagVisible: false })));
          }}
        />
      </BlockStack>
    );
  } else {
    return `No ${pluralResourceName} found`;
  }
}

function truncate(str, n, useWordBoundary) {
  if (str == null) return "";
  if (str.length <= n) return str;
  const subString = str.substr(0, n - 1); // the original check
  return (useWordBoundary ? subString.substr(0, subString.lastIndexOf(" ")) : subString) + "...";
}

const alphabetize = (a, b, key) => {
  return a[key] < b[key] ? -1 : a[key] > b[key] ? 1 : 0;
};

const getLatestOption = (options) => {
  const ids = options.map((s) => s.value);
  return Math.max(...ids);
};

const getAlphabet = () => {
  const charCodes = Array.from(Array(26)).map((e, i) => i + 65);
  return charCodes.map((c) => String.fromCharCode(c));
};

function epochToLocal(epoch) {
  const date = new Date(epoch * 1000);
  return date.toLocaleString();
}

function getCurrenctDatetimeYYYMMDD() {
  const yourDate = new Date();
  let date = yourDate.toISOString().split("T")[0];
  date = date += ` ${yourDate.toLocaleTimeString()}`; //sets the date to locale time of the browser

  return date;
}

function getDifference(a, b) {
  if (a > b) return a - b;
  return b - a;
}

function compareTimeStamps(a, b) {
  if (a > b) {
    return a;
  } else if (a < b) {
    return b;
  }
}

async function uploadFormDataToS3(formData, query) {
  const response = await fetch(`/api/aws/s3/upload${query || ""}`, {
    method: "POST",
    headers: new Headers({ Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser")).token }),
    body: formData,
  });

  return response;
}

const handleSaveOnEnter = (event, handleSave) => {
  if (event.keyCode === 13) {
    event.preventDefault();
    handleSave();
  }
};

const formatFloatToString = (number) => {
  return number.toFixed(2).replace(".", ",");
};

export {
  getRequest,
  postRequest,
  patchRequest,
  putRequest,
  deleteRequest,
  sum,
  formatDate,
  formatDateTime,
  formatDateFilter,
  formatToCurrency,
  getTableFooter,
  pad_with_zeroes,
  truncate,
  formatRoundedWeight,
  alphabetize,
  getLatestOption,
  getAlphabet,
  epochToLocal,
  getCurrenctDatetimeYYYMMDD,
  getDifference,
  compareTimeStamps,
  uploadFormDataToS3,
  handleSaveOnEnter,
  formatFloatToString,
  getRequestWithSignal,
};
