import { useState, useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Badge, DataTable, Link, Page, Card, BlockStack } from "@shopify/polaris";
import { getRequest, getTableFooter } from "../../shared/components/functions";
import { filtersToApiUrl, useUrl } from "../../shared/filters/filterfunctions";
import { CardHeading } from "../../shared/components/CardHeading";

export default function ProductionPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [items, setRuns] = useState([]);
  const [itemsHistory, setItemsHistory] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "20" }],
    ])
  );

  useEffect(() => {
    fetchItems();
    fetchItemsHistory();
  }, [filters]);

  const fetchItems = useCallback(async () => {
    const items = await getRequest(`/api/production/runs/active`);
    setRuns(items);
  });

  const fetchItemsHistory = useCallback(async () => {
    let filterString = filtersToApiUrl(filters);
    const data = await getRequest(`/api/production/runs/history?${filterString}`);
    setItemsHistory(data);
    if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
  });

  const rowsActive = items
    .filter((item) => {
      if (String(item.status).localeCompare("assigned")) {
        return item;
      }
    })
    .map((item) => {
      const status =
        item.status === "in_progress" ? (
          <Badge progress="partiallyComplete" tone="success">
            In Progress
          </Badge>
        ) : (
          <Badge progress="partiallyComplete" tone="attention">
            Paused
          </Badge>
        );
      return [
        item.id,
        // <Link removeUnderline url={`/production/${item.id}`}>
        //   {item.id}
        // </Link>,
        <Badge>{item.owner}</Badge>,
        item.Aantal,
        <Link removeUnderline url={`/products/${item.OnderdeelID}`}>{`#${item.OnderdeelID} ${item.ProductNameNL}`}</Link>,
        String(item.start).substring(0, 10),
        status,
      ];
    });

  const rowsAssigned = items
    .filter((item) => {
      if (!String(item.status).localeCompare("assigned")) {
        return item;
      }
    })
    .map((item) => {
      return [
        <Link removeUnderline url={`/production/${item.id}`}>
          {item.id}
        </Link>,
        <Badge>{item.owner}</Badge>,
        item.Aantal,
        <Link removeUnderline url={`/products/${item.OnderdeelID}`}>{`#${item.OnderdeelID} ${item.ProductNameNL}`}</Link>,
        String(item.ExecutionDate).substring(0, 40).replace("T", " ").replace("Z", " ").replace(".000", ""),
        <Badge tone="info" progress="incomplete">
          Assigned
        </Badge>,
      ];
    });

  const rowsHistory = itemsHistory
    .filter((item) => {
      if (item.production_date !== null) {
        return item;
      }
    })
    .map((item) => {
      return [
        item.ProductionRun,
        // <Link removeUnderline url={`/production/${item.ProductionRun}`}>
        //   {item.ProductionRun}
        // </Link>,
        <Badge>{item.Afkorting}</Badge>,
        item.Aantal,
        <Link removeUnderline url={`/products/${item.OnderdeelID}`}>{`#${item.OnderdeelID} ${item.ProductNameNL}`}</Link>,
        String(item.start).substring(0, 10),
        <Badge progress="complete">Completed</Badge>,
      ];
    });

  return (
    <Page title="Production dashboard" fullWidth>
    <BlockStack gap='400'>
      <Card>
        <CardHeading title={"Production runs in progress"} />
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={[<b>{"Run"}</b>, <b>{"User"}</b>, <b>{"Qty"}</b>, <b>{"Assembley"}</b>, <b>{"Start"}</b>, <b>{"Status"}</b>]}
          rows={rowsActive}
        />
      </Card>
      <Card>
        <CardHeading title={"Production runs assigned"} />
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={[<b>{"Run"}</b>, <b>{"User"}</b>, <b>{"Qty"}</b>, <b>{"Assembley"}</b>, <b>{"Excecution"}</b>, <b>{"Status"}</b>]}
          rows={rowsAssigned}
        />
      </Card>
      <Card>
        <CardHeading title={"Production runs history"} />
        <DataTable
          columnContentTypes={["text", "text", "text", "text", "text", "text"]}
          headings={[<b>{"Run"}</b>, <b>{"User"}</b>, <b>{"Qty"}</b>, <b>{"Assembley"}</b>, <b>{"Start"}</b>, <b>{"Status"}</b>]}
          rows={rowsHistory}
          footerContent={getTableFooter(rowsHistory, filters.get("page"), filters.get("limit"), setFilters, "productions")}
        />
      </Card>
      </BlockStack>
    </Page>
  );
}
