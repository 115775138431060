import { useState, useEffect } from "react";
import { Modal, Button, DataTable, Spinner } from "@shopify/polaris";
import { MaximizeIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { formatToCurrency, getRequest } from "../../../shared/components/functions";
import { useToggle } from "../../../shared/hooks/useToggle";

export default function QuickViewSalesOrderComponent({ id }) {
  const navigate = useNavigate();

  const [popoverActive, setPopoverActive] = useToggle(false);
  const [sale, setSale] = useState({ sale: "", lines: [], qcs: [] });
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/sales/${id}`);
      setSale(data);
      setLoaded(true);
    }
    if (popoverActive) fetchData();
  }, [popoverActive]);

  const activator = <Button icon={MaximizeIcon} size="slim" onClick={setPopoverActive} />;

  const rows = Array.isArray(sale.lines)
    ? sale.lines.map((line) => {
        return [line.produktnr, line.tekstlijn, line.aantal, formatToCurrency(line.VP), formatToCurrency(line.aantal * line.VP)];
      })
    : [];

  return (
    <Modal
      size="large"
      open={popoverActive}
      activator={activator}
      onClose={setPopoverActive}
      title={loaded ? `Sales order #${sale.order.order_number}` : "Sale order"}
      primaryAction={{ content: "Go to fulfilment page", onAction: () => navigate(`/sales/${id}`) }}>
      <Modal.Section>
        {loaded ? (
          <DataTable verticalAlign='middle' increasedTableDensity columnContentTypes={["text", "text", "text", "numeric", "numeric"]} headings={[<b>Code</b>, <b>Description</b>, <b>Qty</b>, <b>Value item</b>, <b>Value total</b>]} rows={rows} />
        ) : (
          <Spinner size="small" />
        )}
      </Modal.Section>
    </Modal>
  );
}
