import { Button, ButtonGroup, Modal } from "@shopify/polaris";
import { printSinglePartLabel } from "../../../shared/dymo/labels/parts_labels/partLabel";

export const LabelModal = ({ isOpen, setOpen, product }) => {
  const handleLabelCreation = (isSmall) => {
    printSinglePartLabel(
      {
        produktnr: product.produktnr,
        ProductNameNL: product.schuilProductName,
        id: product.ProductID,
      },
      false,
      isSmall
    );
  };

  return (
    <Modal open={isOpen} onClose={() => setOpen(false)} title="Print label">
      <Modal.Section>
        <ButtonGroup variant="segmented" fullWidth>
          <Button onClick={() => handleLabelCreation(true)}>Small label</Button>
          <Button onClick={() => handleLabelCreation(false)}>Big label</Button>
        </ButtonGroup>
      </Modal.Section>
    </Modal>
  );
};
