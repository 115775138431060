import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Filters, DataTable, Page, Card, Link, Tooltip, Text } from "@shopify/polaris";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { alphabetize, formatDate, getRequest, getTableFooter, truncate } from "../../shared/components/functions";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";
import { useLookupStore } from "../../utils/useStore";

export default function ChangelogPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();
  const users = useLookupStore((state) => state.users);
  const userOptions = users
    .filter((item) => item.UserType !== 7 && !item.Inactief)
    .sort((a, b) => alphabetize(a, b, "Afkorting"))
    .map((item) => ({ value: item.GebruikerID, label: item.Afkorting }));

  const structureOptions = [
    { label: "all", value: "all" },
    { label: "Greg", value: "greg" },
    { label: "Jack", value: "jack" },
  ];

  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
      ["structure", { type: "status", name: "Structure", value: url.get("structure") || "all", options: structureOptions }],
      ["user", { type: "multi_id", name: "User", value: url.get("user") || "", options: userOptions }],
    ])
  );

  const fetchItems = useCallback(async () => {
    let filterString = `${filtersToApiUrl(filters)}`;
    const data = await getRequest(`/api/changelog?${filterString}`);
    setItems(data);
    setLoaded(true);
    if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
  }, [filters]);

  useEffect(() => {
    if (filters) fetchItems();
  }, [filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => [
        formatDate(item.Datetime),
        <Tooltip content={<div style={{ whiteSpace: "pre-wrap" }}>{item.ChangeLog}</div>} width="wide">
          <Text>{truncate(item.ChangeLog, 100, false)}</Text>
        </Tooltip>,
        <Tooltip content={<div style={{ whiteSpace: "pre-wrap" }}>{item.GebruikerLog}</div>} width="wide">
          <Text>{truncate(item.GebruikerLog, 100, false)}</Text>
        </Tooltip>,
        item.Product,
        item.Afkorting,
        item.OnderdelenId && (
          <Link removeUnderline url={`/products/${item.OnderdelenId}`}>
            #{item.OnderdelenId}
          </Link>
        ),
        item.ParentOnderdelenId && (
          <Link removeUnderline url={`/products/${item.ParentOnderdelenId}`}>
            #{item.ParentOnderdelenId}
          </Link>
        ),
      ])
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="Changelog" fullWidth>
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter changelogs"
        />
        <DataTable
          verticalAlign="middle"
          increasedTableDensity
          columnContentTypes={["text", "text", "text", "text", "text", "text", "text"]}
          headings={[<b>Date</b>, <b>Changelog</b>, <b>Userlog</b>, <b>Structure</b>, <b>User</b>, <b>Product</b>, <b>Assembly</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "changelog entries")}
        />
      </Card>
    </Page>
  );
}
