import { Box, Button, ButtonGroup, FormLayout, InlineStack, Text, TextField } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { getRequest } from "../../../shared/components/functions";
import { ReactSelect } from "../../../shared/components/input/ReactSelect";

export const TreeForm = ({ workOrder, handleChange, getBomEntries }) => {
  const [variantOptions, setVariantOptions] = useState([]);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    async function getVersions() {
      const data = await getRequest(`/api/${workOrder.StructureName.toLowerCase()}/versions`);
      const variants = data.map((d) => ({ value: d.version, label: d.version }));
      setVariantOptions(variants);
    }
    getVersions();
  }, [workOrder.StructureName]);

  useEffect(() => {
    async function getProducts() {
      const data = await getRequest(`/api/${workOrder.StructureName.toLowerCase()}/assemblies`);
      const assemblies = data.filter((d) => d.Lft !== 1 && d[workOrder.VariantName]).map((d) => ({ value: d.PartId, label: `#${d.PartId} | ${d.ProductNameNL}` }));
      setProductOptions(assemblies);
    }
    if (workOrder.VariantName) getProducts();
  }, [workOrder.VariantName]);

  return (
    <FormLayout>
      <InlineStack wrap={false} gap="400">
        <Box>
          <Box paddingBlockEnd="100">Structure name</Box>
          <ButtonGroup variant="segmented">
            <Button size="large" pressed={workOrder.StructureName === "Greg"} onClick={() => handleChange("Greg", "StructureName")}>
              <Text as="p" fontWeight={workOrder.StructureName === "Greg" ? "bold" : "regular"} tone={workOrder.StructureName === "Greg" ? "success" : ""}>
                GREG
              </Text>
            </Button>
            <Button size="large" pressed={workOrder.StructureName === "Jack"} onClick={() => handleChange("Jack", "StructureName")}>
              <Text as="p" fontWeight={workOrder.StructureName === "Jack" ? "bold" : "regular"} tone={workOrder.StructureName === "Jack" ? "success" : ""}>
                JACK
              </Text>
            </Button>
          </ButtonGroup>
        </Box>

        <ReactSelect id="Variantname" label="Variant name" options={variantOptions} handleChange={(v) => handleChange(v, "VariantName")} value={workOrder.VariantName} />
      </InlineStack>

      <ReactSelect
        disabled={!workOrder.VariantName}
        id="Onderdelen"
        label="Part to produce"
        options={productOptions}
        value={parseInt(workOrder.OnderdelenId)}
        handleChange={async (v) => {
          handleChange(v, "OnderdelenId");
          await getBomEntries(v, workOrder.VariantName, workOrder.StructureName);
        }}
      />

      <FormLayout.Group>
        <TextField label="Quantity" id="Qty" type="number" value={workOrder.Qty} onChange={handleChange} disabled={!workOrder.VariantName} />
        <TextField label="Due date" id="DueDate" type="date" value={workOrder.DueDate} onChange={handleChange} disabled={!workOrder.VariantName} />
      </FormLayout.Group>
    </FormLayout>
  );
};
