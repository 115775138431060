import { useState } from "react";
import { useAuthStore } from "../../utils/useStore";

export default function Login() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const { errorMessage, login } = useAuthStore();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const payload = { username, password };

      const loginResult = await login(payload);

      if (!loginResult) {
        return;
      }

      window.location.href = localStorage.getItem("prevLocation") || "/home";
      localStorage.removeItem("prevLocation");
    } catch (err) {
      console.error("An error occurred when logging in: ", err);
    }
  };

  return (
    <div className="login-wrapper">
      <div className="login_box">
        <p className="login_header">Log In</p>
        <form onSubmit={handleLogin}>
          <label>
            <p className="login_subheader">Username</p>
            <input type="text" className="input_login_username" autoFocus onChange={(e) => setUserName(e.target.value)} />
          </label>
          <label>
            <p className="login_subheader">Password</p>
            <input type="password" className="input_login_password" onChange={(e) => setPassword(e.target.value)} />
          </label>
          {errorMessage ? <p className="invalid_login">{errorMessage}</p> : null}
          <div>
            <button type="submit" className="button_login_submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
