import { useState, useEffect } from "react";
import { Badge, DataTable, Link, Page, Card, Filters, InlineStack } from "@shopify/polaris";
import QuickViewSalesOrderComponent from "./components/quickViewComponent";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { formatToCurrency, getRequest, getTableFooter } from "../../shared/components/functions";
import { useLocation, useNavigate } from "react-router-dom";

export default function SalesPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
    ])
  );

  useEffect(() => {
    async function fetchData() {
      let filterString = filtersToApiUrl(filters);
      const data = await getRequest(`/api/sales?${filterString}`);
      setItems(data);
      if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    }
    fetchData();
  }, [filters]);

  const rows = items.map((item) => {
    /*
       when translation error should be visible
       when order canceled should be visible
    */
    const reference = String(item.RefKlant).length > 20 ? String(item.RefKlant).substring(0, 17) + "..." : item.RefKlant;
    const invoice_status =
      item.status_payment === "paid" ? (
        <Badge progress="complete">Paid</Badge>
      ) : item.status_payment === "not invoiced" ? (
        <Badge tone="attention" progress="incomplete">
          Not invoiced
        </Badge>
      ) : item.status_payment === "invoiced awaiting" ? (
        <Badge tone="critical" progress="partiallyComplete">
          Invoiced awaiting
        </Badge>
      ) : item.status_payment === "invoiced authorized" ? (
        <Badge tone="success" progress="partiallyComplete">
          Invoiced authorized
        </Badge>
      ) : item.status_payment === "partially invoiced awaiting" ? (
        <Badge tone="critical" progress="partiallyComplete">
          Partially invoiced awaiting
        </Badge>
      ) : (
        <Badge tone="success">{item.status_payment}</Badge>
      );
    const fulfilment_status =
      item.status_fulfilment === "fulfilled" ? (
        <Badge progress="complete">Fulfilled</Badge>
      ) : item.status_fulfilment === "partially fulfilled" ? (
        <Badge progress="partiallyComplete" tone="warning">
          Partially fulfilled
        </Badge>
      ) : (
        <Badge progress="incomplete" tone="attention">
          Not fulfilled
        </Badge>
      );
    const shipment_status =
      item.status_delivery === "SendCloud" ? (
        <Badge progress="complete">Sendcloud</Badge>
      ) : item.status_delivery === "shipped" ? (
        <Badge progress="complete">Shipped</Badge>
      ) : item.status_delivery === "partially shipped" ? (
        <Badge tone="warning" progress="partiallyComplete">
          Partially shipped
        </Badge>
      ) : (
        <Badge tone="attention" progress="incomplete">
          Not Shipped
        </Badge>
      );

    const customer = String(item.NAAM).length > 30 ? String(item.NAAM).substring(0, 27) + "..." : item.NAAM;

    const dd = item.Leveringstermijn ? String(item.Leveringstermijn).substring(0, 10) : "";
    const due_date =
      item.status_fulfilment !== "fulfilled" ? (
        <div>
          {dd} <Badge>{item.due_diff}d</Badge>
        </div>
      ) : (
        dd
      );

    return [
      <Link removeUnderline url={`/sales/${item.OrderbevestigingID}`}>
        {item.Orderbevestigingnummer}
      </Link>,
      <QuickViewSalesOrderComponent id={item.OrderbevestigingID} />,
      <Badge>{item.Afkorting}</Badge>,
      String(item.Datum).substring(0, 10),
      reference,
      formatToCurrency(item.to_invoice_tax_excl),
      <InlineStack gap="100" blockAlign="center">
        <img
          src={`https://flagcdn.com/w20/${String(item.ISO2).toLowerCase()}.png`}
          srcSet={`https://flagcdn.com/w40/${String(item.ISO2).toLowerCase()}.png 2x,https://flagcdn.com/w60/${String(item.ISO2).toLowerCase()}.png 3x`}
          width="18"
          height="13"
          alt={`${String(item.ISO2).toLowerCase()}`}></img>
        {customer}
      </InlineStack>,
      invoice_status,
      fulfilment_status,
      shipment_status,
      due_date,
    ];
  });

  return (
    <Page title="Sales" fullWidth>
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter orders"
        />
        <DataTable
          verticalAlign="middle"
          increasedTableDensity
          columnContentTypes={["text", "text", "text", "text", "text", "numeric", "text", "text", "text", "text", "text", "text", "text"]}
          headings={[
            <b>{"Number"}</b>,
            <b>{"View"}</b>,
            <b>{"User"}</b>,
            <b>{"Date"}</b>,
            <b>{"Reference"}</b>,
            <b>{"Value"}</b>,
            <b>{"Customer"}</b>,
            <b>{"Payment"}</b>,
            <b>{"Fulfilment"}</b>,
            <b>{"Shipment"}</b>,
            <b>{"Due"}</b>,
          ]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "sales")}
        />
      </Card>
    </Page>
  );
}
