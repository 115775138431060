import { DescriptionList, Link } from "@shopify/polaris";
import { formatDate } from "../../shared/components/functions";

export const CheckListInfo = ({ checkList }) => {
  return (
    <DescriptionList
      items={[
        { term: "Name", description: checkList.name },
        {
          term: "Description",
          description: <div style={{ whiteSpace: "pre-wrap" }}>{checkList.description}</div>,
        },
        {
          term: "Workstation",
          description: (
            <Link removeUnderline url={`/workstations/${checkList.workstationId}`}>
              {checkList.workstationName}
            </Link>
          ),
        },
        { term: "Variant", description: checkList.variantRegex },
        { term: "Created by", description: checkList.Afkorting },
        { term: "Created at", description: formatDate(checkList.createdAt) },
        { term: "Updated at", description: formatDate(checkList.updatedAt) },
      ]}
    />
  );
};
