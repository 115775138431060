import { Card, Collapsible, Icon, Text, BlockStack, InlineStack } from "@shopify/polaris";
import { InventoryUpdatedIcon, ProductIcon, CashEuroIcon, ReceiptIcon, DeliveryIcon } from "@shopify/polaris-icons";
import { useToggle } from "../../../shared/hooks/useToggle";
import { formatDate } from "../../../shared/components/functions";
import { CardHeading } from "../../../shared/components/CardHeading";

export default function TimelineBox({ timeline, loaded }) {
  const [open, setOpen] = useToggle(false);

  const createTitleWithIcon = (eventType, eventDate) => {
    let eventIcon;
    switch (eventType) {
      case "order_creation":
        eventIcon = ProductIcon;
        break;
      case "order_fulfilment":
        eventIcon = InventoryUpdatedIcon;
        break;
      case "order_invoiced":
        eventIcon = ReceiptIcon;
        break;
      case "order_delivery":
        eventIcon = DeliveryIcon;
        break;
      case "order_paid":
        eventIcon = CashEuroIcon;
        break;
      default:
        break;
    }

    return (
      <InlineStack gap="200">
        <Icon source={InventoryUpdatedIcon} />
        <Text>{formatDate(eventDate)}</Text>
      </InlineStack>
    );
  };

  return (
    <Card>
      <CardHeading title="Timeline" actions={[{ content: open ? "Hide" : "Expand", onAction: setOpen, variant: "secondary" }]} />
      <Collapsible open={open} id="basic-collapsible" transition={{ duration: "500ms", timingFunction: "ease-in-out" }} expandOnPrint>
        {loaded ? (
          <BlockStack gap="200">
            {timeline.map((item, idx) => (
              <Card key={idx}>
                <CardHeading title={createTitleWithIcon(item.event_type, item.event_date)} />
                <BlockStack>
                  <Text>{item.event_description.split("-----")[0]}</Text>
                  <Text>{item.event_description.split("-----")[1]}</Text>
                </BlockStack>
              </Card>
            ))}
          </BlockStack>
        ) : null}
      </Collapsible>
    </Card>
  );
}
