import { Card, DataTable, Scrollable } from "@shopify/polaris";
import { CardHeading } from "../../shared/components/CardHeading";

export const StockHistory = ({ stockHistory }) => {
  return (
    <Card>
      <CardHeading title="Stock history" />
      <Scrollable style={stockHistory.length > 0 ? { maxHeight: "410px", margin: "auto -10px" } : null}>
        <DataTable
          columnContentTypes={["text", "numeric", "text"]}
          headings={[<b>Date</b>, <b>Quantity</b>, <b>Source</b>]}
          rows={stockHistory && stockHistory.length > 0 ? stockHistory.map((item) => [item.Datum, item.Aanpassing.toFixed(2).replace(".", ","), item.Source]) : []}
        />
      </Scrollable>
    </Card>
  );
};
