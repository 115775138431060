import React from "react"

type CellCheckboxProps = {
    version: string,
    row_id: number,
    part: number,
    exists: boolean,
    toggleCell: (version: string, id: number, part: number, parent: number, exists: boolean) => void,
    parent: number
}

export default function CellCheckbox(props: CellCheckboxProps) {
    const handleChange = () => { props.toggleCell(props.version, props.row_id, props.part, props.parent, props.exists) }
    return <input type="checkbox" checked={props.exists || false} onChange={handleChange} />
}