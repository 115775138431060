import { useState, useEffect } from "react";
import { DataTable, Page, Card, Filters, Link } from "@shopify/polaris";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, getTableFooter } from "../../shared/components/functions";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function StockAdjusterPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
    ])
  );

  useEffect(() => {
    async function fetchData() {
      let filterString = filtersToApiUrl(filters);
      const data = await getRequest(`/api/stockchanges?${filterString}`);
      setItems(data);
      setLoaded(true);
      if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    }
    fetchData();
  }, [filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => [
        item.Datum,
        item.Aanpassing,
        <Link removeUnderline url={`/products/${item.OnderdeelID}`}>
          # {item.OnderdeelID} {item.product_name}
        </Link>,
        item.Source,
      ])
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="Stock change history" fullWidth>
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter on product name"
        />
        <DataTable
          columnContentTypes={["text", "numeric", "text", "text"]}
          headings={[<b>Date</b>, <b>Quantity</b>, <b>Product</b>, <b>Source</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "stock changes")}
        />
      </Card>
    </Page>
  );
}
