import React, { useState, useCallback } from "react";
import { Modal, Button, TextField, Box } from '@shopify/polaris';
import { Version } from "..";

export default function NewVersionModal({ existing_versions, addVersion, product }: { existing_versions: Version[], addVersion: (version: string) => void, product: string }) {
  const [active, setActive] = useState(false);
  const handleChange = useCallback(() => setActive(!active), [active]);
  const [value, setValue] = useState('');
  const handleChangeInput = useCallback((newValue) => setValue(newValue), []);

  const activator = <Button variant='primary' onClick={handleChange}>Add version</Button>

  return (
    <Modal
      activator={activator}
      open={active}
      onClose={handleChange}
      title={`Add version for model ${product.charAt(0).toUpperCase() + product.slice(1)}`}
      primaryAction={{
        content: 'Add version',
        disabled: (existing_versions.filter(ver => ver.version === value).length > 0 || String(value).length !== 12) ? true : false,
        onAction: () => {
          addVersion(value)
          setActive(false)
          setValue('')
        },
      }}
    >
      <Modal.Section>
        <TextField
          autoComplete=""
          label="New version name"
          value={value}
          onChange={handleChangeInput}
          helpText={(existing_versions.filter(ver => ver.version === value).length > 0) ? 'This version already exists!' : (String(value).length === 12) ? '' : 'Version name has to be 12 chars long'}
        />
      </Modal.Section>
    </Modal>
  );
}