import { useState } from "react";
import { printSerialsLabels, printSerialLabel } from "../../../shared/dymo/labels/serials_labels/serialsLabels";
import { Banner, Box, Button, Card, DataTable, InlineStack, Text } from "@shopify/polaris";
import { PrintIcon } from "@shopify/polaris-icons";
import { SerialSelect } from "../../../shared/components/serialHelpers/serialSelect";
import { deleteRequest, postRequest } from "../../../shared/components/functions";
import { getCodeString } from "../../../shared/components/serialHelpers/helpers";
import { CardHeading } from "../../../shared/components/CardHeading";

export default function SerialsBox({ sale, getSale }) {
  const [serialToAdd, setSerialsToAdd] = useState(undefined);

  const serial_rows = Array.isArray(sale.serials)
    ? sale.serials.map((serial) => {
        const variantString = getCodeString(serial.remarks || "")?.replace(/\*/g, "");
        return [
          serial.serialnumber,
          variantString,
          serial.Software,
          <InlineStack gap="100">
            <Button
              size="slim"
              variant="primary"
              tone="critical"
              onClick={async () => {
                await deleteRequest(`/api/sales/${sale.order.order_id}/serials/${serial.serialnumber}`);
                await getSale();
              }}>
              Remove
            </Button>
            <Button icon={PrintIcon} size="slim" onClick={() => printSerialLabel(serial, sale.order, variantString)} />
          </InlineStack>,
        ];
      })
    : [];

  let qtyMachines = sale?.lines.reduce((acc, line) => {
    if (line.produktnr === 10956 || line.produktnr === 10957) acc += line.aantal;
    return acc;
  }, 0);

  return (
    <Card>
      <CardHeading title="Serial numbers" actions={sale.serials.length > 0 ? [{ content: "All labels", onAction: () => printSerialsLabels(sale.serials, sale.order), variant: 'secondary' }] : []} />

      <Banner tone={qtyMachines > sale?.serials.length ? "critical" : "success"}>
        {qtyMachines > sale?.serials.length ? `Total serials added: ${sale?.serials.length}/${qtyMachines}` : `All serials for order are added (${qtyMachines})`}
      </Banner>

      {sale.serials.length > 0 && (
        <DataTable columnContentTypes={["text", "text", "text", "text"]} headings={["Serial", "Product", "Software", "Label"]} increasedTableDensity verticalAlign="middle" rows={serial_rows} />
      )}

      <Box paddingBlockStart="200">
        <Text>Add serial number:</Text>
        <SerialSelect
          value={serialToAdd}
          onChange={async (serial) => {
            setSerialsToAdd(serial);
            const serialAlreadyAdded = sale.serials.find((s) => parseInt(s.serialnumber) === parseInt(serial));
            if (serialAlreadyAdded) return window.alert("Serial is already added to this order");
            await postRequest(`/api/sales/${sale.order.order_id}/serials`, { serial_id: serial });
            await getSale();
            setSerialsToAdd(undefined);
          }}
        />
      </Box>
    </Card>
  );
}
