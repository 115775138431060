import { BlockStack, Button, InlineStack, Text } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { getRequest } from "../../../shared/components/functions";
import { BarcodeIcon } from "@shopify/polaris-icons";

export const PartsScan = ({ id }) => {
  const [loaded, setLoaded] = useState(false);
  const [scans, setScans] = useState([]);

  const requiredParts = [{ name: "Loadcell" }, { name: "RFID reader" }, { name: "Valve block" }, { name: "Boiler" }];

  const fetchSerial = useCallback(async () => {
    const data = await getRequest(`/api/serials/${id}`);
    if (data) {
      const operatorInteractions = JSON.parse(data.OperatorInteractions);
      if (operatorInteractions) {
        const items = operatorInteractions.scannedParts;
        setScans(items);
      }
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    fetchSerial();
  }, []);

  return loaded ? (
    <BlockStack>
      <InlineStack align="center">
        <Text variant="heading2xl" as="h3">
          Parts ID:
        </Text>
      </InlineStack>

      <BlockStack gap="100">
        {scans && scans.length > 0
          ? requiredParts.map((r, idx) => {
              const id = scans.find((s) => s.name === r.name)?.id || null;
              return (
                <InlineStack key={idx} align="space-between" blockAlign="center">
                  <Text>{`${id ? "✅ " : "❌ "}${r.name}${id ? " - " + id : ""}`}</Text>
                  <Button size="slim" icon={BarcodeIcon} />
                </InlineStack>
              );
            })
          : null}
      </BlockStack>
    </BlockStack>
  ) : null;
};
