import { ChoiceList, DescriptionList, TextField } from "@shopify/polaris";
import { formatDate } from "../../shared/components/functions";
import Select from "react-select";

export const CheckEdit = ({ check, setCheck, setIsDirty, unitOptions }) => {
  const handleChange = (value, id) => {
    if (id === "unitId") value = value.value;
    setCheck((check) => ({ ...check, [id]: value }), setIsDirty(true));
  };

  const getDescriptionList = () => {
    let listItems = [
      {
        term: "Name",
        description: <TextInputField value={check.name} handleChange={handleChange} id="name" />,
      },
      {
        term: "Description",
        description: <TextField value={check.description} onChange={handleChange} id="description" multiline={5} label="" labelHidden />,
      },
      {
        term: "Type",
        description: (
          <ChoiceList
            title="Type"
            titleHidden
            id="type"
            choices={[
              { label: "Boolean", value: "boolean" },
              { label: "Value", value: "value" },
              { label: "Text", value: "text" },
              { label: "Picture", value: "picture" },
            ]}
            selected={check.type}
            onChange={(e) => handleChange(e, "type")}
          />
        ),
      },
      { term: "Created by", description: check.Afkorting },
      { term: "Created at", description: formatDate(check.createdAt) },
      { term: "Updated at", description: formatDate(check.updatedAt) },
    ];

    if (check.type.includes("value")) {
      listItems.splice(3, 0, {
        term: "Unit",
        description: (
          <div style={{ margin: "-5px auto" }}>
            <Select
              id="unitId"
              options={unitOptions}
              menuPortalTarget={document.body}
              onChange={(e) => handleChange(e, "unitId")}
              value={unitOptions.find((w) => w.value === check.unitId)}
              styles={{
                valueContainer: (base) => ({ ...base, height: "32px" }),
                indicatorSeperator: (base) => ({ ...base, height: "32px" }),
                indicatorsContainer: (base) => ({ ...base, height: "32px" }),
                control: (base) => ({ ...base, height: "32px", minHeight: "32px", borderRadius: "8px", borderColor: "#919191" }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
        ),
      });

      listItems.splice(4, 0, {
        term: "Minimum value",
        description: <TextInputField value={check.acceptValueMin || ""} type="number" handleChange={handleChange} id="acceptValueMin" />,
      });

      listItems.splice(5, 0, {
        term: "Maximum value",
        description: <TextInputField value={check.acceptValueMax || ""} type="number" handleChange={handleChange} id="acceptValueMax" />,
      });
    }
    return listItems;
  };

  return <DescriptionList items={getDescriptionList()} />;
};

const TextInputField = ({ value, handleChange, id }) => {
  return (
    <div style={{ margin: "-5px auto" }}>
      <TextField value={value} onChange={handleChange} label="" labelHidden id={id} />
    </div>
  );
};
