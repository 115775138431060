import { ArchiveIcon, PlusIcon, SettingsIcon } from '@shopify/polaris-icons';
import CellCheckbox from './cellCheckBox'
import { Icon, InlineStack, Link } from '@shopify/polaris';
import React from 'react';
import { VersionRecord, Version, VersionValue } from '..';

type ConfigTableRowProps = {
    row_data: any,
    versions: Version[],
    toggleCell: (version: string, id: number, part: number, parent: number, exists: boolean) => void,
    openModal: (part: VersionRecord, isEdit: boolean, isDelete: boolean) => void,
    parts: VersionRecord[],
    setHoverText: (text: VersionValue) => void,
    forcastedQty: number
}

export default function ConfigTableRow(props: ConfigTableRowProps) {
    const { versions, row_data, parts, setHoverText, toggleCell, openModal, forcastedQty } = props

    const getCheckboxRow = () => {
        return Array.isArray(versions) && versions.length > 0 && versions.map(version => {
            return (
                <td className="checkbox_cell" key={`${row_data.ID}__${version.version}`} onMouseEnter={() => setHoverText(version.version)}>
                    <CellCheckbox exists={row_data[version.version]} version={version.version || ''} row_id={row_data.ID} part={row_data.PartId} toggleCell={toggleCell} parent={row_data.Parent} />
                </td>
            )
        })
    }

    const trialit = (part_id: number) => {
        const x = parts?.find((part) => part.PartID == row_data.PartId)
        if (!x) return null

        return (
            <InlineStack blockAlign='center'>
                <div style={{ marginLeft: row_data.depth * 30 + 'px' }}><Link removeUnderline url={`/products/${part_id}`}>{x.ProductNameNL}</Link></div>
                <InlineStack align='center'>
                    <button className="row_options_button" onClick={() => openModal(row_data, false, false)}><Icon source={PlusIcon} /></button>
                    <button className="row_options_button" onClick={() => openModal(row_data, true, false)}><Icon source={SettingsIcon} /></button>
                    {((row_data.Rgt - row_data.Lft) == 1) && <button className="row_options_button" onClick={() => openModal(row_data, true, true)}><Icon source={ArchiveIcon} /></button>}
                </InlineStack>
            </InlineStack>
        )
    }

    const usedInParentAssemblies = row_data.total_used_in_assemblies > 0 ? row_data.total_used_in_assemblies : 0
    const totalStock = row_data.stock_warehouse + (row_data.stock_trianval < 0 ? 0 : row_data.stock_trianval) + usedInParentAssemblies
    const isLeaf = row_data.Rgt - row_data.Lft === 1
    const totalQtyNeeded = row_data.total_quantity * forcastedQty
    const toBuyRawMaterial = totalQtyNeeded - totalStock

    const getBackGroundColorToBuy = () => {
        if (!isLeaf) return "darkgray"
        if (toBuyRawMaterial < 0) return "lightgreen"
        else if (toBuyRawMaterial === 0) return "lightgray"
        else return "lightcoral"
    }

    const getBackgroundColorCell = (stockQty: number) => {
        if (stockQty < 0) return "lightcoral"
        if (!isLeaf) return "darkgray"
        return "transparent"
    }

    return (
        <tr>
            <td className="cell">#{row_data.PartId}</td>
            <td className="cell">{trialit(row_data.PartId)}</td>
            <td className="cell">{row_data.Quantity}</td>
            {getCheckboxRow()}
            <td className="cell" style={{ backgroundColor: getBackgroundColorCell(row_data.stock_warehouse) }}>{row_data.stock_warehouse}</td>
            <td className="cell" style={{ backgroundColor: getBackgroundColorCell(row_data.stock_trianval) }}>{row_data.stock_trianval}</td>
            <td className="cell" style={{ backgroundColor: getBackgroundColorCell(usedInParentAssemblies) }}>{usedInParentAssemblies}</td>
            <td className="cell" style={{ backgroundColor: getBackgroundColorCell(totalStock) }}>{totalStock}</td>
            <td className="cell" style={{ backgroundColor: getBackgroundColorCell(row_data.total_quantity) }}>{isLeaf ? row_data.total_quantity : "AS"}</td>
            <td className="cell" style={{ backgroundColor: getBackgroundColorCell(totalQtyNeeded) }}>{isLeaf ? totalQtyNeeded : "AS"}</td>
            <td className="cell" style={{ backgroundColor: getBackGroundColorToBuy() }}>{isLeaf ? toBuyRawMaterial : "AS"}</td>
        </tr>
    )
}