import { Badge, Banner, BlockStack, EmptyState, InlineStack, Text } from "@shopify/polaris";
import { ChecksOverviewModal } from "./checksOverviewModal";

export const Checklist = ({ checks, setChecksActive, allChecks, workstations }) => {
  const remainingChecks = checks ? checks.checks.filter((c) => c.passed !== null) : null;

  return (
    <BlockStack gap="400">
      <InlineStack blockAlign="center" align="space-between">
        <Text variant="heading2xl" as="h3">
          Checks:
        </Text>
        <ChecksOverviewModal checks={allChecks} workstations={workstations} isWorkstation={true} />
      </InlineStack>

      {checks ? (
        <Banner
          title={
            <InlineStack gap="400">
              <Text>
                Completed checks for this workstation: <Badge>{`${remainingChecks.length}/${checks.checks.length}`}</Badge>
              </Text>
            </InlineStack>
          }
          tone={remainingChecks.length === checks.checks.length ? "success" : "warning"}
          action={remainingChecks.length === checks.checks.length ? null : { content: "Start checks", onAction: () => setChecksActive(true) }}
        />
      ) : (
        <EmptyState heading="No checks" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
          <p>The machine requires no checks on this workstation.</p>
        </EmptyState>
      )}
    </BlockStack>
  );
};
