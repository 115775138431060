import { DescriptionList } from "@shopify/polaris";
import { formatDate } from "../../shared/components/functions";

export const CheckInfo = ({ check, unitOptions }) => {
  const getDescriptionList = () => {
    let listItems = [
      { term: "Name", description: check.name },
      {
        term: "Description",
        description: <div style={{ whiteSpace: "pre-wrap" }}>{check.description}</div>,
      },
      {
        term: "Type",
        description: Array.isArray(check.type) ? check.type[0].charAt(0).toUpperCase() + check.type[0].slice(1) : check.type?.charAt(0).toUpperCase() + check.type.slice(1),
      },
      { term: "Created by", description: check.Afkorting },
      { term: "Created at", description: formatDate(check.createdAt) },
      { term: "Updated at", description: formatDate(check.updatedAt) },
    ];

    if (check.unitId)
      listItems.splice(2, 0, {
        term: "Unit",
        description: unitOptions?.find((u) => u.value === check.unitId).label,
      });
    if (check.acceptValueMin)
      listItems.splice(check.unitId ? 3 : 2, 0, {
        term: "Min value accepted",
        description: check.acceptValueMin,
      });
    if (check.acceptValueMax)
      listItems.splice(check.unitId && check.acceptValueMin ? 4 : check.unitId || check.acceptValueMin ? 3 : 2, 0, {
        term: "Max value accepted",
        description: check.acceptValueMax,
      });
    return listItems;
  };

  return <DescriptionList items={getDescriptionList()} />;
};
