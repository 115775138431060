export var serialsWithMelexisV3 = [
    20233362,
    20233363,
    20233364,
    20233365,
    20233366,
    20233367,
    20233368,
    20233369,
    20233370,
    20233371,
    20233372,
    20233373,
    20233374,
    20233375,
    20233376,
    20233377,
    20233378,
    20233379,
    20233380,
    20233381,
    20233382,
    20233383,
    20233384,
    20233385,
    20233386,
    20233387,
    20233388,
    20233389,
    20233390,
    20233391,
    20233392,
    20233393,
    20233394,
    20233395,
    20233396,
    20233397,
    20233398,
    20233399,
    20233400,
    20233401,
    20233402,
    20233403,
    20233404,
    20233405,
    20233406,
    20233407,
    20233408,
    20233409,
    20233410,
    20233411,
    20233412,
    20233413,
    20233414,
    20233415,
    20233416,
    20233417,
    20233418,
    20233419,
    20233420,
    20233421,
    20233422,
    20233423,
    20233424,
    20233425,
    20233426,
    20233427,
    20233428,
    20233429,
    20233430,
    20233431,
    20233432,
    20233433,
    20233434,
    20233435,
    20233436,
    20233437,
    20233438,
    20233439,
    20233440,
    20233441,
    20233442,
    20233443,
    20233444,
    20233445,
    20233507,
    20233508,
    20233509,
    20233510,
    20233511,
    20233512,
    20233513,
    20233514,
    20233515,
    20233516,
    20233517,
    20233518,
    20233519,
    20233520,
    20233521,
    20233522
]