import {
  postRequest,
  uploadFormDataToS3,
} from "../../../../shared/components/functions";

export const handleFileChange = async (
  isAdd,
  file,
  fileToUpload,
  handleFiles,
  AwsFileKeys
) => {
  const newFileExtension = getFileType(fileToUpload.name);
  let fileKey = "";

  // ADD FILES
  if (isAdd) {
    const trimmedName = file.name.replace(/\s/g, "");
    file.name = trimmedName;
    if (!trimmedName) {
      return alert("File name is required!");
    }
    fileKey = `product_files/${file.type}_${file.name}_${file.date}.${newFileExtension}`;

    // UPDATE FILES
  } else {
    const oldFileExtension = getFileType(file.key);
    fileKey = file.key;

    if (oldFileExtension !== newFileExtension) {
      fileKey = `${file.key.slice(
        0,
        file.key.lastIndexOf(".")
      )}.${newFileExtension}`;
      await postRequest(`/api/aws/s3/delete`, {
        keys: [file.key],
      });
    }

    AwsFileKeys = AwsFileKeys.filter((obj) => {
      return obj.key !== file.key;
    });
  }

  // UPLOAD FILES AND UPDATE DB
  const formData = new FormData();
  formData.append(
    "file",
    new File([fileToUpload], fileKey, { type: fileToUpload.type })
  );

  const response = await uploadFormDataToS3(formData, '?path=product_files');
  if (!response.ok)
    return alert("Something went wrong uploading the images to S3");

  const fileObj = {
    type: file.type,
    name: file.name,
    key: fileKey,
  };

  const updatedKeys = [...(AwsFileKeys || []), fileObj];
  await handleFiles(updatedKeys, "Files");
};

export const getFileType = (fileName) => {
  return fileName.slice(fileName.lastIndexOf(".") + 1);
};

export const getFileColor = (fileType) => {
  switch (fileType) {
    case "pdf":
      return "red";
    case "docx":
    case "doc":
      return "#51b5ff";
    case "xlsx":
    case "xls":
      return "green";
    case "png":
    case "jpeg":
    case "jpg":
      return "orange";
    default:
      return "gray";
  }
};

export const validImageTypes = ["image/gif", "image/jpeg", "image/png"];
