import { DataTable, Spinner } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { postRequest } from "../../../shared/components/functions";
import { useRef, useState } from "react";
import { printFinishedProductLabel } from "../../../shared/dymo/labels/finished_product_labels/pdfFinishedProductBox";
import JsBarcode from "jsbarcode";
import { useLookupStore } from "../../../utils/useStore";
import { getCodeString } from "../../../shared/components/serialHelpers/helpers";

export const PartsToPick = ({ partToProduceId, bomItems, bomItemsStock, serialNumber, serial, checksForStation }) => {
  let { id } = useParams();
  const workstationId = id;

  return (
    <div>
      <DataTable verticalAlign="middle" increasedTableDensity columnContentTypes={["text", "text", "text"]} rows={bomItems} headings={["QTY", "CODE", "PART"]} />
      {workstationId === "6" && <FinishMachineProduction bomItemsStock={bomItemsStock} partToProduceId={partToProduceId} serialNumber={serialNumber} serial={serial} checks={checksForStation} />}
    </div>
  );
};

export const FinishMachineProduction = ({ bomItemsStock, partToProduceId, serialNumber, serial, checks }) => {
  const softwaretypes = useLookupStore((state) => state.softwaretypes);
  const recipetypes = useLookupStore((state) => state.recipetypes);
  const [submitting, setSubmitting] = useState(false);
  const barcodeRef = useRef();
  const barcode = <img id="machineBarcode" ref={barcodeRef}></img>;

  const remainingChecks = checks ? checks.checks.filter((c) => c.passed !== null) : null;

  async function finishMachineProduction() {
    setSubmitting(true);
    const result = await postRequest("/api/production/lineproduction/finish", { bomItemsStock, partToProduceId, serialNumber });
    if (!result) return alert("Problem with finishing production, write Matthias!");

    const serialString = getCodeString(serial?.remarks || "");
    const colorCode = serialString.substring(11, 13);

    JsBarcode("#machineBarcode", serialNumber.toString(), { width: 2, height: 90, fontSize: 10 });

    const product = {
      serialnumber: serialNumber.toString(),
      reference: serialString.replace(/\*/g, ""),
      color: colorCode === "01" ? "Black" : "White",
      software: softwaretypes.find((s) => s.SofwareTypeID === serial.softwareTypeID)?.Software,
      recipe: recipetypes.find((s) => s.RecipeTypeID === serial.recipeTypeID)?.Recipe,
      barcode: barcodeRef.current.src,
    };

    printFinishedProductLabel(product);
    setSubmitting(false);
  }

  return (
    <>
      <button
        style={{
          marginTop: "20px",
          width: "100%",
          height: "100px",
          backgroundColor: "#008060",
          color: "white",
          fontSize: "30px",
          fontWeight: "bold",
          borderRadius: "10px",
          border: "none",
          outline: "none",
          cursor: "pointer",
        }}
        onClick={async () => {
          if (remainingChecks.length !== checks.checks.length) return alert("Please complete all checks first");

          const userWantsToContinue = confirm(`
          Machine production will be finished:
          ✅ stock of first level will be decreased
          ✅ this machine will be taken into stock
          ✅ status of machine will be updated
          ✅ machine will be checked out from workstation
          ✅ sticker label will be printed`);

          if (userWantsToContinue) {
            await finishMachineProduction();
            console.log("Check out machine from production");
          }
        }}>
        {submitting ? (
          <div>
            <Spinner size="small" color="white" />
            <span style={{ paddingLeft: "10px" }}>FINISHING...</span>
          </div>
        ) : (
          "FINISH MACHINE"
        )}
      </button>
      <div style={{ display: "none" }}>{barcode}</div>
    </>
  );
};
