import { useState, useEffect, useCallback } from "react";
import { Badge, DataTable, Link, Page, Card, Button } from "@shopify/polaris";
import { alphabetize, getRequest, patchRequest } from "../../shared/components/functions";
import { NewWorkorderModal } from "./components/newWorkorderModal";
import { useLookupStore } from "../../utils/useStore";
import Select from "react-select";
import { Toasts } from "../../shared/components/toasts/toasts";
import { DeleteIcon } from "@shopify/polaris-icons";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

/*
  Fulfilement list: 
  - show all the goods that need to be fulfilled and show if we can produce them or not
  
  Sales order page:
    Erdem wants:
      -> reserve: 
          - reserve certain stock for an orderline

      -> fulfill:
          - fulfill stock for an orderline
            * mark orderline as (partially) fulfilled
            * decrease product stock
            * write stock change
      -> request:
          - request production for an orderline


    Main questions that need to be answered.
    - What do we need to produce/buy to fulfill all open orders?
    - What orders need to be (partially fulfilled) and by when?
    - What orders are ready to be shipped?
*/

export default function WorkOrdersPage() {
  const users = useLookupStore((state) => state.users);
  const [workOrders, setWorkOrders] = useState([]);
  const [newOrderModalOpen, setNewOrderModalOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [toast, setToast] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const filteredUsers = users
    .filter((item) => !item.Inactief)
    .sort((a, b) => alphabetize(a, b, "Afkorting"))
    .map((item) => ({ value: item.GebruikerID, label: item.Afkorting }));

  const fetchOrders = useCallback(async () => {
    const items = await getRequest(`/api/workorders`);
    setWorkOrders(items);
    setLoaded(true);
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [newOrderModalOpen]);

  const handleAssigneeChange = async (value, orderID) => {
    setIsSaving(true);
    patchRequest(`/api/workorders/${orderID}/assignee`, { user: value?.value || null }, setToast);
    fetchOrders();
    setIsSaving(false);
  };

  async function cancelWorkOrder(id) {
    if (confirm("Are you sure you want to cancel this work order? This will not consume any inventory.")) {
      await patchRequest(`/api/workorders/${id}/cancel`, {}, setToast);
      await fetchOrders();
    }
  }

  const rowsActive = Array.isArray(workOrders)
    ? workOrders.map((order) => [
        <Link removeUnderline url={`/workorders/${order.Id}`}>
          #{order.Id}
        </Link>,
        <Link removeUnderline url={`/products/${order.OnderdelenId}`}>
          #{order.OnderdelenId}
        </Link>,
        order.ProductNameNL,
        order.Qty,
        order.VariantName,
        order.StructureName,
        <Badge>{order.VariantName && order.StructureName ? "TREE" : "BOM"}</Badge>,
        order.CreatedAt?.substring(0, 10),
        order.AssignmentDate?.substring(0, 10),
        order.DueDate?.substring(0, 10),
        <Link removeUnderline url={`/sales/${order.OrderbevestigingID}`}>
          {order.OrderbevestigingID}
        </Link>,
        <Select
          id="CreatedByUserId"
          options={filteredUsers}
          onChange={(e) => handleAssigneeChange(e, order.Id)}
          value={filteredUsers.find((c) => parseInt(c.value) === parseInt(order.CreatedByUserId)) || ""}
          isDisabled={isSaving}
          menuPortalTarget={document.body}
          isClearable
          styles={{
            input: (base) => ({ ...base, marginTop: "-10px" }),
            indicatorSeperator: (base) => ({ ...base, height: "26px" }),
            indicatorsContainer: (base) => ({ ...base, height: "26px" }),
            control: (base) => ({ ...base, height: "26px", minHeight: "26px", borderRadius: "8px", borderColor: "#919191" }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          }}
        />,
        <Badge>{order.Status}</Badge>,
        <Button icon={DeleteIcon} onClick={() => cancelWorkOrder(order.Id)} size="slim" tone="critical" />,
      ])
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="Open work orders" fullWidth primaryAction={{ content: "New work order", onAction: () => setNewOrderModalOpen(true) }}>
      <Toasts toast={toast} setToast={setToast} />
      <Card>
        <NewWorkorderModal modalActive={newOrderModalOpen} setModalActive={setNewOrderModalOpen} setToast={setToast} />
        <DataTable
          verticalAlign="middle"
          columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "numeric"]}
          headings={[
            <b>ID</b>,
            <b>Product ID</b>,
            <b>Product name</b>,
            <b>Qty</b>,
            <b>Variant</b>,
            <b>Structure</b>,
            <b>Type</b>,
            <b>Created</b>,
            <b>Assignment</b>,
            <b>Due</b>,
            <b>Sale</b>,
            <b>Assignee</b>,
            <b>Status</b>,
            <b>Cancel</b>,
          ]}
          rows={rowsActive}
        />
      </Card>
    </Page>
  );
}
