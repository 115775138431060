import React, { useState } from "react";
import { BlockStack, Box, Modal, TextField, Text } from "@shopify/polaris";
import { postRequest } from "../../../shared/components/typedFunctions";
import { useAuthStore } from '../../../utils/useStore'
import ProductSelect from '../../../shared/components/productSelect/index'

type CheckModalProps = {
    modalOpen: boolean;
    hideModal: () => void;
    product: string
};

export default function ManualModal({ modalOpen, hideModal, product }: CheckModalProps) {
    const { user } = useAuthStore();
    const [addButtonDisabled, setAddButtonDisabled] = useState<boolean>(false);
    const [log, setLog] = useState({ user: user?.id, changeType: 'manual', userlog: '', productId: '', selectedParentId: '', product: product })
    const [logError, setLogError] = useState(false)

    const handleSave = async () => {
        if (!log.userlog) return setLogError(true)
        setAddButtonDisabled(true);
        await postRequest('/api/changelog', { ...log, date: new Date() })
        setAddButtonDisabled(false);
        hideModal();
    }

    return (
        <Modal
            open={modalOpen} onClose={hideModal} title='Add manual changelog'
            primaryAction={{ content: "Save", onAction: handleSave, disabled: addButtonDisabled }}
            secondaryActions={[{ content: "Cancel", onAction: hideModal }]}
        >
            <Modal.Section>
                <BlockStack gap='400'>
                    <TextField
                        label='Additional information' id='userlog' value={log.userlog} multiline={3} autoComplete="off"
                        onChange={(value) => setLog((log) => ({ ...log, userlog: value }))}
                        error={logError && "Required"}
                    />
                    <Box width='100%'>
                        <Box paddingBlockEnd="100">
                            <Text as='p'>Product</Text>
                        </Box>
                        <ProductSelect value={log.productId} onChange={(value: any) => setLog((log) => ({ ...log, productId: value }))} clearable />
                    </Box>
                    <Box width='100%'>
                        <Box paddingBlockEnd="100">
                            <Text as='p'>Assembly</Text>
                        </Box>
                        <ProductSelect value={log.selectedParentId} onChange={(value: any) => setLog((log) => ({ ...log, selectedParentId: value }))} clearable />
                    </Box>
                </BlockStack>
            </Modal.Section>
        </Modal>
    );
}
