import { useEffect, useState } from "react";
import { Button, Modal, Text, ResourceList, Banner, Badge, BlockStack, InlineStack } from "@shopify/polaris";
import { getRequest } from "../../../shared/components/functions";
import { S3ImageViewer } from "../../../shared/components/s3_image_display/s3ImageUploader";
import { useLookupStore } from "../../../utils/useStore";

const CheckItem = ({ check }) => {
  const units = useLookupStore((state) => state.units);
  const users = useLookupStore((state) => state.users);
  const date = new Date(check.checkedAt);
  const picArr = check.type === "picture" ? check.value?.map((v) => v.key) : [];

  return (
    <div style={{ marginTop: "7px", borderRadius: "10px", backgroundColor: check.passed === null ? "#F9F9F9" : check.passed ? "#F0FDF8" : "#FEF3F1" }}>
      <ResourceList.Item id={check.id}>
        <BlockStack>
          <div style={{ display: "flex", gap: 3 }}>
            <Text variant="bodyLg" as="h2">
              {check.passed === null ? "" : check.passed ? "✅" : "❌"}
            </Text>

            <div style={{ flex: 1 }}>
              <BlockStack>
                <Text variant="bodyLg" as="h2">
                  {check.name}
                </Text>
                {check.type !== "picture" ? <Text>{check.value ? `Input: ${check.value}` : ""}</Text> : null}
              </BlockStack>
            </div>

            <BlockStack inlineAlign="end">
              <Text variant="bodyLg" as="h2">
                {check.checkedAt ? date.toLocaleString("nl-BE") : null}
              </Text>
              {check.type === "value" ? (
                <Text variant="bodyMd" as="h3">
                  {`${check.acceptValueMin ? check.acceptValueMin : ""} - ${check.acceptValueMax ? check.acceptValueMax : ""} ${
                    check.unitId ? units.find((u) => u.EenheidID === check.unitId).Eenheid : ""
                  }`}
                </Text>
              ) : null}
              <Text variant="bodyMd" as="h3">
                {check.userId && users && users.length > 0 ? users.find((u) => u.GebruikerID === check.userId).Afkorting : ""}
              </Text>
            </BlockStack>
          </div>

          <div>{check.type === "picture" && check.value ? <S3ImageViewer S3ObjectKeys={JSON.stringify(picArr)} viewOnly thumbNails displayDirection={"horizontal"} /> : null}</div>
        </BlockStack>
      </ResourceList.Item>
    </div>
  );
};

const WorkstationSection = ({ workstation, checks, lists }) => {
  let checkLists, tableData;

  if (checks && checks.length > 0) {
    checkLists = checks.reduce((acc, obj) => {
      if (!acc[obj.checkListId]) {
        acc[obj.checkListId] = [];
      }
      acc[obj.checkListId].push(obj);
      return acc;
    }, []);

    tableData = Object.entries(checkLists).map(([listId, checks]) => ({ listId, checks }));
  }

  return (
    <Modal.Section>
      <Text variant="headingLg" as="h5">
        {workstation.Name}
      </Text>

      {tableData
        ? tableData.map(({ listId, checks }, idx) => (
            <div key={idx} style={{ marginTop: "20px" }}>
              <Text variant="headingMd" as="h6">
                Checklist: {lists.find((l) => l.id.toString() === listId).name}
              </Text>
              <ResourceList items={checks} renderItem={(check) => <CheckItem check={check} />} />
            </div>
          ))
        : null}
    </Modal.Section>
  );
};

export const ChecksOverviewModal = ({ checks, workstations, isWorkstation }) => {
  const [active, setActive] = useState(false);
  const [lists, setLists] = useState([]);
  const [overview, setOverview] = useState({ total: 0, pass: 0, fail: 0, remaining: 0 });
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    async function fetchLists() {
      const data = await getRequest("/api/checklists");
      if (data) setLists(data);
    }
    fetchLists();
  }, []);

  useEffect(() => {
    async function getCheckOverview() {
      let total = 0;
      let pass = 0;
      let fail = 0;
      for (const [key, value] of Object.entries(checks)) {
        total += value.checks.length;
        pass += value.checks.filter((c) => c.passed).length;
        fail += value.checks.filter((c) => c.passed !== null && !c.passed).length;
      }
      setOverview({ total, pass, fail, remaining: total - (pass + fail) });
      setDataLoaded(true);
    }
    if (Object.keys(checks).length) getCheckOverview();
  }, [checks]);

  const activator = (
    <Button variant="primary" onClick={() => setActive(true)}>
      Check history
    </Button>
  );

  const modal = (
    <Modal size="large" fullScreen activator={activator} open={active} onClose={() => setActive(false)} title="Check history">
      {workstations && workstations.length > 0
        ? workstations.map((w, idx) => {
            return checks && checks[w.id]?.checks.length > 0 ? <WorkstationSection key={idx} workstation={w} checks={checks[w.id]?.checks} lists={lists} /> : null;
          })
        : null}
    </Modal>
  );

  if (isWorkstation) return modal;

  return (
    dataLoaded && (
      <Banner tone={overview.remaining === 0 && overview.fail === 0 ? "success" : "warning"}>
        <BlockStack gap="200">
          <InlineStack blockAlign="center">
            <Text>Passed checks:</Text>
            <Badge tone="success">{overview.pass}</Badge>
          </InlineStack>
          <InlineStack blockAlign="center">
            <Text>Failed checks:</Text>
            <Badge tone="critical">{overview.fail}</Badge>
          </InlineStack>
          <InlineStack blockAlign="center" gap="200">
            <Text>Remaining checks:</Text>
            <Badge>{overview.remaining}</Badge>
          </InlineStack>
          {modal}
        </BlockStack>
      </Banner>
    )
  );
};
