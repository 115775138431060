import { useState, useCallback, useEffect } from "react";
import { Badge, Layout, TextField, Page, Card, Banner, Link, DescriptionList, BlockStack } from "@shopify/polaris";
import QCResolver from "./components/qcResolver";
import { useParams } from "react-router-dom";
import { SaveBar } from "../../shared/components/savebar.jsx/savebar";
import { Toasts } from "../../shared/components/toasts/toasts";
import { getRequest, patchRequest } from "../../shared/components/functions";
import { S3ImageUploader } from "../../shared/components/s3_image_display/s3ImageUploader";
import { CardHeading } from "../../shared/components/CardHeading";

export default function QCPage() {
  let { id } = useParams();
  let qc = {
    ID: null,
    Timestamp: null,
    QualityPassed: true,
    Note: null,
    ResolutionDate: null,
    ResolutionNote: null,
    ResolutionUserID: null,
    QCFileUrl: null,
    S3ObjectKeys: null,
    user: { id: null, abbreviation: null },
    stockchange: {
      order_id: null,
      id: null,
      quantity: null,
      product: {
        id: null,
        name: null,
        supplier: { id: null, name: null },
      },
    },
    classification_type: { id: null, classification: null, success: false },
    resolution_type: { type: null, id: null, adjust_stock: true },
  };

  /*
      Working on the qc page qc resolver
  */
  const [initialQC, setInitialQC] = useState("");
  const [isDirty, setIsDirty] = useState(false);
  const [toast, setToast] = useState(null);
  const [savingResult, setSavingResult] = useState(false);
  const [QC, setQC] = useState(qc);

  useEffect(() => {
    getQC();
  }, []);

  const getQC = useCallback(async () => {
    const data = await getRequest(`/api/qc/${id}`);
    setQC(data);
    setInitialQC(data);
  }, []);

  const handleSave = async () => {
    setSavingResult(true);
    await patchRequest(`/api/qc/${id}`, { Note: QC.Note }, setToast);
    setIsDirty(false);
    setSavingResult(false);
  };

  const handleDiscard = () => {
    setQC(initialQC);
    setIsDirty(false);
    setSavingResult(false);
  };

  const handleChange = (value, id) => {
    setQC((QC) => ({ ...QC, [id]: value }), setIsDirty(true));
  };

  var banner = QC.ID ? (
    QC.ResolutionDate ? (
      <Banner tone="warning" title={`QC resolved on ${String(QC.ResolutionDate).substring(0, 10)} with note: ${QC.ResolutionNote}`} />
    ) : QC.QualityPassed ? (
      <Banner tone="success" title="QC passed" />
    ) : (
      <Banner tone="critical" title="QC failed" />
    )
  ) : null;

  const resolver = QC.ID ? QC.ResolutionDate ? null : QC.QualityPassed ? null : <QCResolver QC={QC} reloadQC={getQC} /> : null;

  return (
    <Page backAction={{ content: "QC", url: "/QC" }} title={`QC #${QC.ID}`} fullWidth>
      <SaveBar isDirty={isDirty} handleSave={handleSave} handleDiscard={handleDiscard} savingResult={savingResult} />
      <Toasts toast={toast} setToast={setToast} toastContent="QC saved" />
      <Layout>
        <Layout.Section>{banner}</Layout.Section>
        {resolver}
        <Layout.Section>
          <BlockStack gap="400">
            <Card>
              <CardHeading title="Summary" />
              <DescriptionList
                items={[
                  { term: "Classification", description: QC.classification_type.classification },
                  {
                    term: "Part",
                    description: (
                      <Link url={`/products/${QC.stockchange.product.id}`} removeUnderline>
                        {`#${QC.stockchange.product.id} ${QC.stockchange.product.name}`}
                      </Link>
                    ),
                  },
                  { term: "Quantity", description: QC.stockchange.quantity },
                  { term: "User", description: <Badge>{QC.user.abbreviation}</Badge> },
                ]}
              />
            </Card>
            <Card>
              <TextField id="Note" label="Note" value={QC.Note || ""} onChange={handleChange} multiline={4} />
            </Card>
          </BlockStack>
        </Layout.Section>

        <Layout.Section variant="oneThird">
          <S3ImageUploader
            S3ObjectKeys={QC.S3ObjectKeys}
            S3ObjectNamePrefix="qc_images"
            onUploadImages={async (updatedS3ObjectKeys) => {
              setQC({ ...QC, S3ObjectKeys: JSON.stringify(updatedS3ObjectKeys) });
              await patchRequest(`/api/qc/${id}`, { S3ObjectKeys: JSON.stringify(updatedS3ObjectKeys) });
            }}
            onRemoveImages={async (updatedS3ObjectKeys) => {
              setQC({ ...QC, S3ObjectKeys: JSON.stringify(updatedS3ObjectKeys) });
              await patchRequest(`/api/qc/${id}`, { S3ObjectKeys: JSON.stringify(updatedS3ObjectKeys) });
            }}
          />
        </Layout.Section>
      </Layout>
    </Page>
  );
}
