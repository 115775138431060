import { useState, useEffect, useCallback } from "react";
import { Page, Layout, Spinner, Text, InlineError, Card, InlineStack } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { getRequest, putRequest } from "../../shared/components/functions";
import { S3ImageUploader } from "../../shared/components/s3_image_display/s3ImageUploader";
import { CheckInfo } from "./checkInfo";
import { EditIcon } from "@shopify/polaris-icons";
import { CheckEdit } from "./checkEdit";
import { Toasts } from "../../shared/components/toasts/toasts";
import { useLookupStore } from "../../utils/useStore";
import { CardHeading } from "../../shared/components/CardHeading";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function CheckPage() {
  const { id } = useParams();
  const { units } = useLookupStore();
  const [check, setCheck] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [savingResult, setSavingResult] = useState(false);
  const [toast, setToast] = useState(null);
  const [valueError, setValueError] = useState(false);
  const unitOptions = units.map((d) => {
    return { value: d.EenheidID, label: d.Eenheid };
  });

  const fetchCheck = useCallback(async () => {
    const data = await getRequest(`/api/checks/${id}`);
    if (data) setCheck(data[0]);
    setLoaded(true);
  }, []);

  useEffect(() => {
    fetchCheck();
  }, []);

  const handleSave = async () => {
    if ((check.type === "value" || check.type.includes("value")) && (!check.acceptValueMin || !check.acceptValueMax)) {
      return setValueError(true);
    }

    setValueError(false);
    setSavingResult(true);
    const payload = {
      name: check.name,
      description: check.description,
      type: check.type,
      unitId: check.type === "value" || check.type.includes("value") ? check.unitId : null,
      acceptValueMin: check.type === "value" || check.type.includes("value") ? check.acceptValueMin : null,
      acceptValueMax: check.type === "value" || check.type.includes("value") ? check.acceptValueMax : null,
      pictureJson: check.pictureJson,
      updatedAt: new Date(),
    };
    const response = await putRequest(`/api/checks/${id}`, payload, setToast);
    if (response) {
      fetchCheck();
    }
    setIsEdit(false);
    setSavingResult(false);
  };

  const handleDiscard = () => {
    setIsDirty(false);
    setIsEdit(false);
  };

  if (!loaded) return <PageSpinner />;

  return (
    <Page title={check.name} subtitle={`ID: #${id}`} fullWidth>
      <Toasts toast={toast} setToast={setToast} toastContent="Saved successfully" />
      <Layout>
        <Layout.Section>
          <Card sectioned>
            <CardHeading
              title={
                <InlineStack gap="600">
                  <Text variant="headingMd" as="h5">
                    Summary
                  </Text>
                  <Text variant="headingMd" as="h5">
                    {valueError && <InlineError message="Both min and max values are required." fieldID={["acceptValueMin", "acceptValueMax"]} />}
                  </Text>
                </InlineStack>
              }
              actions={
                isEdit
                  ? [
                      { content: "Cancel", onAction: handleDiscard, variant: "primary", tone: "critical", disabled: savingResult },
                      { content: "Save", onAction: handleSave, variant: "primary", loading: savingResult, disabled: !isDirty },
                    ]
                  : [{ icon: EditIcon, onAction: () => setIsEdit(true), variant: "secondary" }]
              }
            />

            {check && unitOptions && unitOptions.length > 0 ? (
              isEdit ? (
                <CheckEdit check={check} setCheck={setCheck} setIsDirty={setIsDirty} unitOptions={unitOptions} valueError={valueError} />
              ) : (
                <CheckInfo check={check} unitOptions={unitOptions} />
              )
            ) : (
              <Spinner size="small" color="green" />
            )}
          </Card>
        </Layout.Section>

        <Layout.Section variant="oneThird">
          {check && (
            <S3ImageUploader
              S3ObjectKeys={check.pictureJson}
              S3ObjectNamePrefix={"check_images"}
              onUploadImages={async (updatedS3ObjectKeys) => {
                const updatedCheck = { ...check, pictureJson: JSON.stringify(updatedS3ObjectKeys) };
                setCheck(updatedCheck);
                await putRequest(`/api/checks/${id}`, updatedCheck);
              }}
              onRemoveImages={async (updatedS3ObjectKeys) => {
                const updatedCheck = { ...check, pictureJson: JSON.stringify(updatedS3ObjectKeys) };
                setCheck(updatedCheck);
                await putRequest(`/api/checks/${id}`, updatedCheck);
              }}
            />
          )}
        </Layout.Section>
      </Layout>
    </Page>
  );
}
