import { useLocation, useNavigate } from "react-router-dom";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../../shared/filters/filterfunctions";
import { useCallback, useEffect, useState } from "react";
import { Filters, Card, DataTable, Link, Spinner, Button } from "@shopify/polaris";
import { formatDate, getRequest, getTableFooter, truncate } from "../../../shared/components/functions";
import { AddChecklistModal } from "./addChecklistModal";
import { PageSpinner } from "../../../shared/components/spinner/PageSpinner";

export const CheckListsPage = () => {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();
  const [modalActive, setModalActive] = useState(false);
  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
    ])
  );

  useEffect(() => {
    fetchItems();
  }, [filters]);

  const fetchItems = useCallback(async () => {
    let filterString = filtersToApiUrl(filters);
    const data = await getRequest(`/api/checklists?${filterString}`);
    setItems(data);
    if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    setLoaded(true);
  }, [filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        return [
          <Link removeUnderline url={`/checklists/${item.id}`}>
            {truncate(item.name, 40, false)}
          </Link>,
          truncate(item.description || "", 50, false),
          <Link removeUnderline url={`/workstations/${item.workstationId}`}>
            {item.workstationName}
          </Link>,
          item.variantRegex,
          item.Afkorting,
          formatDate(item.createdAt),
          formatDate(item.updatedAt),
        ];
      })
    : [];

    if (!loaded) return <PageSpinner />;

  return (
    <Card>
      <AddChecklistModal active={modalActive} setActive={setModalActive} fetchList={fetchItems} checkList={true} />
      <Filters
        queryValue={filters.get("q").value}
        filters={addFilters(filters, setFilters)}
        appliedFilters={applyFilters(filters, setFilters)}
        onClearAll={() => removeAllFilters(filters, setFilters)}
        onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
        onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
        autoComplete={false}
        queryPlaceholder="Filter checklists">
        <Button variant="primary" onClick={() => setModalActive(true)}>
          Add checklist
        </Button>
      </Filters>

      <DataTable
        verticalAlign="middle"
        columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text"]}
        headings={[<b>Name</b>, <b>Description</b>, <b>Workstation</b>, <b>Variant</b>, <b>Created by</b>, <b>Created at</b>, <b>Updated at</b>]}
        rows={rows}
        footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "checklists")}
      />
    </Card>
  );
};
