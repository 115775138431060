import { useState } from "react";
import AsyncSelect from "react-select/async";
import { getRequest } from "../functions";
import { Box, InlineStack, Text } from "@shopify/polaris";
import { getCodeString } from "./helpers";

export const SerialSelect = ({ id, value, onChange, label, isDisabled, isClearable }) => {
  const [selectValue, setSelectValue] = useState({
    value: value,
    label: value,
  });

  const loadOptions = async (inputValue, callback) => {
    if (inputValue) {
      const data = await getRequest(`/api/serials?q=${inputValue}`);
      const options = data.map((serial) => {
        return {
          value: serial.serialnumber,
          label: `${serial.serialnumber} | ` + (serial.remarks ? ` | ${getCodeString(serial.remarks)}` : ""),
        };
      });
      callback(options);
    }
  };

  return (
    <Box width="100%">
      <Box paddingBlockEnd="100">
        <InlineStack align="space-between">{label && <Text>{label}</Text>}</InlineStack>
      </Box>

      <AsyncSelect
        id="SerialSelect"
        isDisabled={isDisabled}
        value={value ? selectValue : null}
        loadOptions={loadOptions}
        isClearable={isClearable}
        menuPortalTarget={document.body}
        styles={{
          valueContainer: (base) => ({ ...base, height: "32px" }),
          indicatorSeperator: (base) => ({ ...base, height: "32px" }),
          indicatorsContainer: (base) => ({ ...base, height: "32px" }),
          control: (base) => ({ ...base, height: "32px", minHeight: "32px", borderRadius: "8px", borderColor: "#919191" }),
          menu: (provided) => ({ ...provided, zIndex: 9999 }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        }}
        defaultOptions
        onChange={(props) => {
          if (props) {
            const { value, label } = props;
            setSelectValue({ value: value, label: label });
            onChange(value, id);
          } else {
            setSelectValue({ value: null, label: null });
            onChange(null, id);
          }
        }}
      />
    </Box>
  );
};
