import React, { useEffect } from "react";
import { Page, Button, Text, Card, BlockStack, InlineStack, InlineGrid } from "@shopify/polaris";
import { useAuthStore, useStationStore } from "../../utils/useStore";
import { Timer } from "../workstation/components/workstationTimer";
import { removeSerialFromStation } from "../../database/db";

export default function HomePage() {
  const user = useAuthStore();
  const [stationData, setStationData] = useStationStore((state) => [state.stationData, state.setStationData]);

  useEffect(() => {
    const interval = setInterval(() => {
      setStationData();
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <Page title={`Welcome ${user.user.username}`} fullWidth>
      <InlineGrid columns={6} gap="200">
        {stationData.length > 0
          ? stationData.map((station, idx) => {
              return (
                <Card key={idx}>
                  <BlockStack gap="200">
                    <BlockStack>
                      <Button size="large" url={`/workstations/${station.workstation_id}`}>
                        <Text variant="headingLg" as="h5">
                          {station.workstation_name}
                        </Text>
                      </Button>
                      {station.serial_number_id && <Timer timestamp={station.last_entry} />}
                    </BlockStack>

                    {/*  vertical stack with all the entries */}
                    {station.entries?.length > 0 ? (
                      station.entries.map((e, idx) => {
                        return (
                          <InlineStack key={idx} blockAlign="center" align="space-between">
                            <Text variant="headingMd" as="h6">
                              {e.serialnumber}
                            </Text>
                            <Button
                              onClick={() => {
                                if (window.confirm("Are you sure you want to delete this machine from station? ")) {
                                  removeSerialFromStation(e.serialnumber);
                                }
                              }}
                              size="slim"
                              variant="primary"
                              tone="critical">
                              Delete from station
                            </Button>
                          </InlineStack>
                        );
                      })
                    ) : (
                      <Text>Station empty</Text>
                    )}

                    {station.serial_number_id && (
                      <div>
                        <BlockStack>
                          <Text variant="headingMd" as="h6">
                            Serial number:
                          </Text>
                          {station.schuilenburg_order_id ? (
                            <Text variant="headingMd" as="h6">
                              Sales order:
                            </Text>
                          ) : (
                            <div style={{ height: "24px" }}></div>
                          )}
                        </BlockStack>

                        <BlockStack>
                          <Text variant="headingMd" as="h6">
                            <Link removeUnderline url={`/serials/${station.serial_number_id}`}>
                              {station.serial_number_id}
                            </Link>
                          </Text>

                          <Text variant="headingMd" as="h6">
                            <div style={{ height: "24.5px" }}>
                              {station.schuilenburg_order_id ? (
                                <Link removeUnderline url={`/sales/${station.schuilenburg_order_id}`}>
                                  {station.schuilenburg_order_id}
                                </Link>
                              ) : (
                                ""
                              )}
                            </div>
                          </Text>
                        </BlockStack>
                      </div>
                    )}
                  </BlockStack>
                </Card>
              );
            })
          : null}
      </InlineGrid>

      {/* //comment out:
        <Stack fullWidth>
          <Stack.Item fill>
            <Card sectioned>
              <Scanner />
            </Card>
          </Stack.Item>
        </Stack> */}
    </Page>
  );
}
