import { DataTable, Link } from "@shopify/polaris";
import { getRequest, pad_with_zeroes, formatToCurrency } from "../../../shared/components/functions";
import { useEffect, useState } from "react";

export default function PurchasesCompanyPage({ FIRNR }) {
  const [purchases, setPurchases] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/purchases?FIRNR=${FIRNR}`);
      setPurchases(data);
    }
    fetchData();
  }, []);

  const SharePointFactuurTranslations = {
    1: "Bestelling",
    2: "Commande",
    3: "Order",
    4: "Bestellung",
  };

  const rows = purchases.map((item) => {
    var customer_ref_date = item.RefKlantDatum ? item.RefKlantDatum : "";
    return [
      <Link removeUnderline url={`/purchases/${item.BestellingID}`}>
        {item.Bestellingnummer}
      </Link>,
      <Link
        url={`https://schuilenburg.sharepoint.com/Schuilenburg/${pad_with_zeroes(item.Klantnummer, 5)}/Bestelling/${item.Bestellingnummer}/${SharePointFactuurTranslations[item.TaalID]}%20${
          item.Bestellingnummer
        }.pdf`}>
        PDF
      </Link>,
      String(item.Datum).substring(0, 10),
      item.customer_name,
      item.RefKlant,
      String(customer_ref_date).substring(0, 10),
      formatToCurrency(item.total_value),
    ];
  });

  return (
    <DataTable
      verticalAlign="middle"
      increasedTableDensity
      columnContentTypes={["text", "text", "text", "text", "text", "text", "numeric"]}
      headings={[<b>Number</b>, <b>PDF</b>, <b>Date</b>, <b>Customer</b>, <b>Reference customer</b>, <b>Date customer</b>, <b>Price</b>]}
      rows={rows}
    />
  );
}
