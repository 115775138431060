import { Badge, Divider, Select, Text, TextField, Button, ButtonGroup, BlockStack, InlineStack, Box } from "@shopify/polaris";
import { useEffect, useState } from "react";
import { formatFloatToString, getRequest, patchRequest, postRequest } from "../../shared/components/functions";

const handleInventory = async (isPatch, productId, aantalValue) => {
  if (isPatch) {
    await patchRequest(`/api/inventory/${productId}`, { stockdiff: aantalValue });
  } else {
    await postRequest("/api/inventory", {
      productId: productId,
      stockplaats: 1,
      rack: 0,
      legplank: 0,
      aantal: aantalValue,
    });
  }
};

export const CurrentStock = ({ stockData, fetchInventory, fetchStockHistory, productID }) => {
  const [stockOptions, setStockOptions] = useState([]);
  const initialStock = { Aanpassing: "", StockAanpassingTypeID: 1, Source: "" };

  const [newStock, setNewStock] = useState(initialStock);
  const [adjustStockLoading, setAdjustStockLoading] = useState(false);
  const [sign, setSign] = useState("+");

  const handleStockChange = (value, id) => {
    let stockvalue = value;
    if (id === "Aanpassing") {
      stockvalue = stockvalue.replace(/[^0-9.,]/g, "").replace(/,/g, ".");
      const dotCount = stockvalue.split(".").length - 1;
      if (dotCount > 1) stockvalue = stockvalue.slice(0, -1);
    }
    if (id === "StockAanpassingTypeID") stockvalue = parseInt(stockvalue);

    setNewStock((newStock) => ({ ...newStock, [id]: stockvalue }));
  };

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest("/api/stockchanges/adjustment/types");
      const options = data.map((o) => ({ value: o.StockAanpassingTypeID, label: o.Type }));
      setStockOptions(options);
    }
    fetchData();
  }, []);

  const handleStockAdjust = async () => {
    setAdjustStockLoading(true);

    if (sign === "-") newStock.Aanpassing = -Math.abs(newStock.Aanpassing);

    if (newStock.Aanpassing === 0 || !newStock.Aanpassing) {
      alert(`Stockchange can't be 0`);
    } else {
      const roundedDiff = Math.round(newStock.Aanpassing * 100) / 100;
      const payload = {
        part_id: productID,
        stock_diff: roundedDiff,
        timestamp: new Date(),
        source: newStock.Source,
        stockchange_type_id: newStock.StockAanpassingTypeID,
      };
      await postRequest("/api/stockchanges", payload);
      await handleInventory(!Array.isArray(stockData), productID, roundedDiff);
      fetchStockHistory();
      fetchInventory();
    }
    newStock.Aanpassing = "";
    newStock.Source = "";
    newStock.StockAanpassingTypeID = 1;
    setAdjustStockLoading(false);
    setSign("+");
  };

  // const fastAdjustPosValues = [1, 2, 3, 4, 5, 10, 20, 50, 100];
  // const fastAdjustNegValues = [-1, -2, -3, -4, -5, -10, -20, -50, -100];

  const newValueButton = Math.round((parseFloat(stockData.aantal) + parseFloat(sign === "-" ? -Math.abs(newStock.Aanpassing) : newStock.Aanpassing)) * 100) / 100;

  return (
    <div>
      <BlockStack gap="300">
        <CurrentStockTable stockData={stockData} />
        {/* <Text variant="headingLg" as="h4">
        Fast adjust:
      </Text>
      <Stack vertical>
        <Stack distribution="equalSpacing">
          {fastAdjustPosValues.map((v, idx) => (
            <FastAdjustButton
              key={idx}
              value={v}
              stockData={stockData}
              productID={productID}
              fetchInventory={fetchInventory}
              fetchStockHistory={fetchStockHistory}
            />
          ))}
        </Stack>
        <Stack distribution="equalSpacing">
          {fastAdjustNegValues.map((v, idx) => (
            <FastAdjustButton
              key={idx}
              value={v}
              stockData={stockData}
              productID={productID}
              fetchInventory={fetchInventory}
              fetchStockHistory={fetchStockHistory}
            />
          ))}
        </Stack> 
      </Stack> */}

        <BlockStack gap="300">
          <Text variant="headingMd" as="h5">
            Custom adjust:
          </Text>

          <InlineStack gap="200" blockAlign="center" wrap={false}>
            <InlineStack gap="200" wrap={false}>
              <ButtonGroup variant="segmented">
                <Button size="large" pressed={sign === "+"} onClick={() => setSign("+")}>
                  +
                </Button>
                <Button size="large" pressed={sign === "-"} onClick={() => setSign("-")}>
                  -
                </Button>
              </ButtonGroup>
              <TextField id="Aanpassing" value={newStock.Aanpassing} onChange={handleStockChange} prefix={sign} />
            </InlineStack>
            <Box width="100%">
              <Select label="" id="StockAanpassingTypeID" value={newStock.StockAanpassingTypeID} options={stockOptions} onChange={handleStockChange} />
            </Box>
          </InlineStack>

          <TextField label="Reason" labelHidden id="Source" value={newStock.Source} placeholder="Reason for change (optional)" onChange={handleStockChange} />
          <Box>
            <Button size="large" loading={adjustStockLoading} disabled={newStock.Aanpassing === 0} onClick={handleStockAdjust}>
              {newStock.Aanpassing && newStock.Aanpassing !== 0
                ? `ADJUST STOCK TO TOTAL OF ${!isNaN(newValueButton) ? formatFloatToString(newValueButton) : Math.round(parseFloat(stockData.aantal) * 100) / 100}`
                : "Adjust stock"}
            </Button>
          </Box>
        </BlockStack>
      </BlockStack>
    </div>
  );
};

const CurrentStockTable = ({ stockData }) => {
  return (
    <BlockStack gap="200">
      <InlineStack align="space-between">
        <Text fontWeight="bold" variant="headingMd" as="h5">
          Stock
        </Text>
        <Text fontWeight="bold" alignment="end" variant="headingMd" as="h5">
          Quantity
        </Text>
      </InlineStack>
      <Divider />
      <InlineStack align="space-between">
        <Text variant="headingMd" as="h5">
          Total available:
        </Text>
        <Box>
          {typeof stockData.aantal === "number" ? (
            <Badge>
              <Text fontWeight="bold" variant="headingMd" as="h6">
                {stockData.aantal.toFixed(2).replace(".", ",")}
              </Text>
            </Badge>
          ) : null}
        </Box>
      </InlineStack>
    </BlockStack>
  );
};

// const FastAdjustButton = ({
//   value,
//   stockData,
//   fetchInventory,
//   fetchStockHistory,
//   productID,
// }) => {
//   const [loading, setLoading] = useState(false);
//   async function handleClick() {
//     setLoading(true);
//     const payload = {
//       part_id: productID,
//       stock_diff: value,
//       timestamp: new Date(),
//       source: "Fast adjust",
//       stockchange_type_id: 1,
//     };
//     await postRequest("/api/stockchanges", payload);
//     await handleInventory(!Array.isArray(stockData), productID, value);
//     await fetchInventory();
//     await fetchStockHistory();
//     setLoading(false);
//   }
//   return (
//     <Button
//       loading={loading}
//       onClick={handleClick}
//       size="large"
//       primary={value > 0}
//       destructive={value < 0}
//     >
//       <div style={{ width: "40px" }}>{value > 0 ? `+${value}` : value}</div>
//     </Button>
//   );
// };
