import { Card, Page, Text, EmptyState, Layout, Spinner, BlockStack, InlineStack, Button } from "@shopify/polaris";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getRequest } from "../../shared/components/functions";
import { WorkstationHeader } from "./components/workstationHeader";
import { PartsToPick } from "./components/partsToPick";
import { Checklist } from "./components/workstationChecklist";
import { OperationSteps } from "./components/operationSteps";
import { PartsScan } from "./components/partsScan";
import { WorkstationFeedbackModal } from "./components/feedbackModal";
import { ChecksCard } from "./components/checksCard";
import { useLookupStore, useStationStore } from "../../utils/useStore";
import { getProducedProductInForWorkStation, removeSerialFromStation } from "../../database/db";
import { getCodeString } from "../../shared/components/serialHelpers/helpers";
import { CardHeading } from "../../shared/components/CardHeading";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export const WorkstationPage = () => {
  let { id } = useParams();
  const { users, workstations } = useLookupStore();
  const [rfidStationData, setRfidStationData] = useStationStore((state) => [state.stationData, state.setStationData]);
  const stationData = rfidStationData && rfidStationData.length > 0 ? rfidStationData.find((d) => d.workstation_id.toString() === id) : { serial_id: null };
  const workstation = workstations.find((w) => w.id.toString() === id);
  const [serial, setSerial] = useState({});
  const [loaded, setLoaded] = useState(false);
  const [checksActive, setChecksActive] = useState(false);
  const [checksForStation, setChecksForStation] = useState({});
  const [producedProductId, setProducedProductId] = useState(null);
  const SERIAL_NUMBER = stationData && stationData?.entries?.length > 0 ? stationData.entries[0].serialnumber : null;
  const [versionTree, setVersionTree] = useState([]);
  const [bomItems, setBomItems] = useState([]);
  const [bomItemsStock, setBomItemsStock] = useState([]); // used for BE changes, do not change
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const interval = setInterval(() => setRfidStationData(), 1000);
    return () => clearInterval(interval);
  }, []);

  const fetchRFIDStations = useCallback(async () => {
    if (stationData.entries?.length === 1) {
      const serialNumberInStation = stationData.entries[0].serialnumber;
      const serialData = await getRequest(`/api/serials/${serialNumberInStation}`);
      if (serialData) {
        setSerial(serialData);
        const checks = serialData.Checks ? JSON.parse(serialData.Checks + "") : null;
        setChecksForStation(checks ? checks[id] : null);
      }
      setLoaded(true);
    }
  }, [SERIAL_NUMBER]);

  useEffect(() => {
    fetchRFIDStations();
  }, [SERIAL_NUMBER]);

  useEffect(() => {
    const getProducedProduct = async () => {
      const producedProduct = await getProducedProductInForWorkStation(id);
      setProducedProductId(producedProduct.OnderdelenId);
    };
    getProducedProduct();
  }, []);

  useEffect(() => {
    async function getVersionTree() {
      setLoading(true);
      let versionString = serial?.remarks ? getCodeString(serial?.remarks || "")?.replace(/\*/g, "") : "";
      const underscoredVersion = versionString.replace("-", "_").replace("-", "_");
      const prod = underscoredVersion[2] === "G" ? "greg" : "jack";
      const data = await getRequest(`/api/${prod}/versions/${underscoredVersion}`);
      // setVersionTree(data);
      setBomItems(data.filter((version) => version.depth === 1).map((version) => ["x" + version.Quantity + "", version.PartId + "", version.ProductNameNL + ""]) || []);
      setBomItemsStock(data.filter((version) => version.depth === 1).map((version) => [version.Quantity, version.PartId, version.ProductNameNL]) || []);
      setLoading(false);
    }
    getVersionTree();
  }, [serial]);

  //  no machine in station
  if (stationData.entries?.length === 0) {
    return (
      <Page fullWidth>
        <Card>
          <CardHeading title={workstation.Name} />
          <EmptyState heading="Awaiting machine" image="https://cdn.shopify.com/s/files/1/0262/4071/2726/files/emptystate-files.png">
            <p>No machine detected in this station</p>
          </EmptyState>
        </Card>
      </Page>
    );
  }

  // multiple machines in station
  if (stationData.entries?.length > 1) {
    return (
      <Page fullWidth>
        <Card>
          <BlockStack gap="300">
            Multiple serial numbers on this station
            {stationData.entries.map((e, idx) => {
              return (
                <InlineStack key={idx} blockAlign="center" gap="300">
                  <Text variant="headingMd" as="h6">
                    {e.serialnumber}
                  </Text>
                  <Button
                    onClick={() => {
                      if (window.confirm("Are you sure you want to delete this machine from station? ")) {
                        removeSerialFromStation(e.serialnumber);
                      }
                    }}
                    size="slim"
                    variant="primary"
                    tone="critical">
                    Delete from station
                  </Button>
                </InlineStack>
              );
            })}
          </BlockStack>
        </Card>
      </Page>
    );
  }

  if (!loaded) return <PageSpinner />;

  // machine in station
  return (
    <Page fullWidth>
      <div style={{ position: "fixed", bottom: "30px", right: "40px", zIndex: 9999 }}>
        <WorkstationFeedbackModal id={SERIAL_NUMBER} workstation={stationData.workstation_id} workstations={workstations} users={users} />
      </div>

      <Card>
        {stationData ? (
          <WorkstationHeader workStationName={workstation.Name} timestamp={Number(stationData.last_entry)} serialnumber={SERIAL_NUMBER} salenumber={stationData.schuilenburg_order_id} serial={serial} />
        ) : (
          <Spinner size="large" color="green" />
        )}

        {checksActive ? (
          <ChecksCard workstationId={id} checks={checksForStation.checks} setChecksActive={setChecksActive} serial={serial} fetchRFIDStations={fetchRFIDStations} />
        ) : (
          <Layout>
            <Layout.Section variant="oneHalf">
              <Card>
                <InlineStack align="center">
                  <Text variant="heading2xl" as="h3">
                    BOM:
                  </Text>
                </InlineStack>
                <PartsToPick
                  bomItems={bomItems}
                  bomItemsStock={bomItemsStock}
                  partToProduceId={String(getCodeString(serial?.remarks || "")?.replace(/\*/g, "")).includes("G") ? 833 : 834}
                  serialNumber={SERIAL_NUMBER}
                  serial={serial}
                  checksForStation={checksForStation}
                />
              </Card>
            </Layout.Section>
            <Layout.Section variant="oneHalf">
              {stationData.workstation_id === 1 || stationData.workstation_id === 2 ? (
                <Card>
                  <OperationSteps />
                </Card>
              ) : null}
            </Layout.Section>

            <Layout.Section variant="oneThird">
              <BlockStack gap="500">
                {stationData.workstation_id === 2 && (
                  <Card>
                    <PartsScan id={SERIAL_NUMBER} />
                  </Card>
                )}

                <Card>
                  <Checklist checks={checksForStation} setChecksActive={setChecksActive} allChecks={serial.Checks ? JSON.parse(serial.Checks) : []} workstations={workstations} />
                </Card>
              </BlockStack>
            </Layout.Section>
          </Layout>
        )}
      </Card>
    </Page>
  );
};
