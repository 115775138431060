import { useState } from "react";
import { Tabs, Page } from "@shopify/polaris";
import FulfilmentOrders from "./components/fulfilmentOrders";
import FulfilmentProduct from "./components/fulfilmentProduct";
import FulfilmentCalendar from "./components/fulfilmentCalendar";
import FulfilmentMachines from "./components/fulfilmentMachines";
import { useNavigate } from "react-router-dom";

export default function FulfilmentPage() {
  const url = new URL(window.location.href);
  const tabIndex = parseInt(url.searchParams.get("tab") + "") || 0;

  const [selected, setSelected] = useState(tabIndex);
  const navigate = useNavigate();

  const handleTabChange = (selectedTabIndex) => {
    setSelected(selectedTabIndex);
    navigate(`/fulfilment?tab=${selectedTabIndex}`);
  };

  const tabs = [
    { id: "Orders", content: "Orders to fulfil", component: <FulfilmentOrders /> },
    { id: "fulfilement-products", content: "Fulfilment list", component: <FulfilmentProduct /> },
    { id: "fulfilment-machines", content: "Machines", component: <FulfilmentMachines /> },
    { id: "fulfilment-calendar", content: "Fulfilment calendar", component: <FulfilmentCalendar /> },
  ];

  return (
    <Page title="Fulfilment" fullWidth>
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        {tabs[selected].component}
      </Tabs>
    </Page>
  );
}
