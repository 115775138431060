import { Form, FormLayout, Modal, Text, TextField, ChoiceList, Select, BlockStack } from "@shopify/polaris";
import { useState } from "react";
import { postRequest } from "../../../shared/components/functions";
import { Toasts } from "../../../shared/components/toasts/toasts";
import { S3ImageUploader } from "../../../shared/components/s3_image_display/s3ImageUploader";
import { useAuthStore, useLookupStore } from "../../../utils/useStore";
import { ReactSelect } from "../../../shared/components/input/ReactSelect";

export const AddChecklistModal = ({ active, setActive, fetchList, checkList, unitOptions }) => {
  const user = useAuthStore();
  const { workstations } = useLookupStore();
  const [savingResult, setSavingResult] = useState(false);
  const [newItem, setNewItem] = useState(checkList ? {} : { type: ["boolean"], pictureJson: "null" });
  const [toast, setToast] = useState(null);
  const workstationOptions = workstations.map((d) => {
    return { value: d.id, label: d.Name };
  });

  const handleChange = (e, id) => {
    setNewItem((newItem) => ({
      ...newItem,
      [id]: e,
    }));
  };

  const handleSave = async () => {
    setSavingResult(true);
    const fields = {
      name: newItem.name,
      description: newItem.description ? newItem.description : "",
      userId: user.user.id,
      createdAt: new Date(),
      updatedAt: new Date(),
    };

    const additionalFields = checkList
      ? { workstationId: newItem.workstationId, variantRegex: newItem.variantRegex }
      : {
          type: newItem.type[0],
          unitId: newItem.unitId || null,
          acceptValueMin: newItem.acceptValueMin || null,
          acceptValueMax: newItem.acceptValueMax || null,
          pictureJson: newItem.pictureJson === "null" ? null : newItem.pictureJson,
        };
    const payload = Object.assign(fields, additionalFields);
    const url = checkList ? "/api/checklists" : "/api/checks";
    const response = await postRequest(url, payload, setToast);
    if (response) {
      fetchList();
      setActive(false);
    }
    setSavingResult(false);
    setNewItem(checkList ? {} : { type: ["boolean"], pictureJson: "null" });
  };

  return (
    <>
      <Toasts toast={toast} setToast={setToast} />
      <Modal
        open={active}
        onClose={() => setActive(false)}
        title={checkList ? "Add checklist" : "Add check"}
        primaryAction={{ content: "Save", onAction: () => handleSave(newItem), loading: savingResult }}>
        <Modal.Section>
          <Form onSubmit={() => handleSave(newItem)}>
            <FormLayout>
              <TextField value={newItem.name} onChange={handleChange} label="Name" id="name" />
              <TextField value={newItem.description} onChange={handleChange} label="Description" id="description" multiline={5} />

              {checkList ? (
                <BlockStack gap="400">
                  <TextField value={newItem.variantRegex} onChange={handleChange} label="Variant string" id="variantRegex" />
                  <ReactSelect id="workstationId" label="Workstation" options={workstationOptions} handleChange={(value) => handleChange(value, "workstationId")} value={newItem.workstationId} />
                </BlockStack>
              ) : (
                <BlockStack gap="400">
                  <ChoiceList
                    title="Type"
                    id="type"
                    choices={[
                      { label: "Boolean", value: "boolean" },
                      { label: "Value", value: "value" },
                      { label: "Text", value: "text" },
                      { label: "Picture", value: "picture" },
                    ]}
                    selected={newItem.type}
                    onChange={(e) => handleChange(e, "type")}
                  />

                  {newItem.type?.includes("value") ? (
                    <FormLayout.Group condensed>
                      <Select label="Unit" id="unitId" options={unitOptions} onChange={handleChange} value={newItem.unitId} />
                      <TextField id="acceptValueMin" type="number" label="Minimum value" value={newItem.acceptValueMin} onChange={handleChange} autoComplete="off" />
                      <TextField id="acceptValueMax" type="number" label="Maximum value" value={newItem.acceptValueMax} onChange={handleChange} autoComplete="off" />
                    </FormLayout.Group>
                  ) : null}

                  <BlockStack gap="100">
                    <Text>Additional pictures to display with the check</Text>
                    <S3ImageUploader
                      S3ObjectKeys={newItem.pictureJson}
                      S3ObjectNamePrefix="check_images"
                      onUploadImages={(updatedS3ObjectKeys) => handleChange(JSON.stringify(updatedS3ObjectKeys), "pictureJson")}
                      onRemoveImages={(updatedS3ObjectKeys) => handleChange(JSON.stringify(updatedS3ObjectKeys), "pictureJson")}
                    />
                  </BlockStack>
                </BlockStack>
              )}
            </FormLayout>
          </Form>
        </Modal.Section>
      </Modal>
    </>
  );
};
