import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layout, Page, Card, DataTable, Button, Banner, Link, Popover, ActionList, BlockStack, Box, InlineStack, Text } from "@shopify/polaris";
import { PrintIcon } from "@shopify/polaris-icons";
import { getRequest } from "../../shared/components/functions";
import { printSinglePartLabel, printAllPartLabels } from "../../shared/dymo/labels/parts_labels/partLabel";
import TimelineBox from "./components/timelineBox";
import ShippingInfoBox from "./components/shippingInfoBox";
import SummaryBox from "./components/summaryBox";
import DocsBox from "./components/docsBox";
import SerialsBox from "./components/serialsBox";
import { fullyFulfillSalesorder, deFullyFulfillSalesorder } from "./helpers/actions";
import { FulFillInput } from "./components/fullfilInput";
import { CardHeading } from "../../shared/components/CardHeading";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

/*
  Stages of fulilling an order:
  x all lines need to be fulfilled
  x translated lines
  x add stock to sale
  - stock need to be adjusted also.. for aha
  - fulfilment records still need to be written? Maybe  not! Only benefit is to know what is when fulfilled by who
  - all serials need to be connected and maybe scanned?
*/

export default function SalePage() {
  let { id } = useParams();

  const order_schema = {
    order: [],
    lines: [],
    lines_translated: [],
    serials: [],
  };

  const [initialSale, setInitialSale] = useState(order_schema);
  const [sale, setSale] = useState(order_schema);
  const [loaded, setLoaded] = useState(false);
  const [fulfillingOrder, setFulfillingOrder] = useState(false);
  const [toast, setToast] = useState(null);
  const [bigLabelActive, setBigLabelActive] = useState(false);
  const [smallLabelActive, setSmallLabelActive] = useState(false);
  let orderCanBeFulfilled = true;
  let orderHasTranslationErrors = false;

  useEffect(() => {
    getSale();
  }, []);

  // useEffect(() => {
  //   //if(orderHasTranslationErrors) setOrderCanBeFulfilled(false)
  // }, [orderHasTranslationErrors]);

  async function getSale() {
    const data = await getRequest(`/api/sales/${id}`);
    setSale(data);
    setInitialSale(data);
    setLoaded(true);
  }

  let qtyMachines = sale?.lines.reduce((acc, line) => {
    if (line.produktnr === 10956 || line.produktnr === 10957) acc += line.aantal;
    return acc;
  }, 0);

  const allSerialsAdded = qtyMachines <= sale?.serials.length;

  const sale_lines_rows = Array.isArray(sale.lines)
    ? sale.lines.map((line) => {
        const productAha = sale.lines_translated.find((tl) => tl.OnderdeelSchuilenburgID === line.produktnr);
        const remaingToFulfill = line.aantal - (line.aantalFulfilled || 0);
        const productAhaId = productAha?.OnderdeelAhaID;
        const productAhaName = productAha?.ProductNameNL;
        const ahaRatio = productAha?.ratio_aha || 1;
        const schuilRatio = productAha?.ratio_schuilenburg || 1;
        const ahaQty = (line.aantal / ahaRatio) * schuilRatio;

        if (!productAhaId) {
          orderHasTranslationErrors = true;
          return [];
        }

        return [
          <Link url={`/products/${productAhaId}`}>{productAhaId}</Link>,
          <BlockStack>
            <Text fontWeight="semibold" tone={!productAhaName ? "critical" : ""}>
              {`${productAhaName || "TRANSLATION ERROR NO AHA PIECE FOUND FOR SCHUIL PIECE"}`}
            </Text>
            <Text>{`schuil: #${line.produktnr} ${line.tekstlijn} (x${line.aantal})`}</Text>
          </BlockStack>,
          ahaQty,
          <Text fontWeight="semibold" tone={remaingToFulfill > 0 ? "critical" : "success"}>
            {remaingToFulfill}
          </Text>,
          <Text fontWeight="semibold" tone={ahaQty > productAha.aantal > 0 ? "critical" : "success"}>
            {productAha.aantal}
          </Text>,
          line.aantalFulfilled || 0,
          <FulFillInput line={line} getSale={getSale} ahaInventory={productAha.aantal} ahaProductId={productAhaId} remaingToFulfill={remaingToFulfill} />,
          remaingToFulfill === 0 ? "✅" : "❌",
          // formatToCurrency(line.VP),
          // formatToCurrency(line.aantal * line.VP),
          <Button icon={PrintIcon} onClick={() => printSinglePartLabel({ ...line, id: line.produktnr }, true, true)} />,
          <Button icon={PrintIcon} onClick={() => printSinglePartLabel({ ...line, id: line.produktnr }, false, true)} />,
          <Button icon={PrintIcon} onClick={() => printSinglePartLabel(line, true)} />,
          <Button icon={PrintIcon} onClick={() => printSinglePartLabel(line, false)} />,
        ];
      })
    : [];

  // an order is fulfilled when for all the lines the qty is fulfilled
  const saleIsFulfilled = () => {
    let fulfilled = true;
    sale.lines.forEach((line) => {
      if (line.aantalFulfilled < line.aantal && line.produktnr > 50) fulfilled = false;
    });
    return fulfilled;
  };

  orderCanBeFulfilled = !orderHasTranslationErrors;

  if (!loaded) return <PageSpinner />;

  return (
    <Page backAction={{ content: "Sales", url: "/fulfilment" }} title={`Sales order #${sale.order.order_number}`} fullWidth>
      <BlockStack gap="400">
        {orderHasTranslationErrors && (
          <Banner tone="critical" title="This order has translation errors, it cannot be fulfilled! Or the AHA part does not have a valid stockplaats for the Magazijn!!" />
        )}

        {loaded && (
          <BlockStack>
            {!saleIsFulfilled() ? (
              <Banner tone={orderCanBeFulfilled ? "warning" : "critical"} title="This order is not fulfilled yet.">
                {/* <Button
                      loading={fulfillingOrder}
                      onClick={async () => {
                        if (confirm("Are you sure you want to fully fulfill this sales order?")) {
                          setFulfillingOrder(true)
                          await fullyFulfillSalesorder(sale.order.order_id)
                          await getSale()
                          setFulfillingOrder(false)
                        }
                      }}>Fully fulfill order</Button> */}
              </Banner>
            ) : (
              <Banner tone="success" title="This order is fulfilled.">
                {/* <Button
                    loading={fulfillingOrder}
                    onClick={async () => {
                      if (confirm("Are you sure you want to open this sales order again so it can get fulfilled again?")) {
                        setFulfillingOrder(true)
                        await deFullyFulfillSalesorder(sale.order.order_id)
                        await getSale()
                        setFulfillingOrder(false)
                      }
                    }}>Fully open order again for fulfilment</Button> */}
              </Banner>
            )}

            {!allSerialsAdded && <Banner tone="critical" title="You need to add all serial numbers!" />}
          </BlockStack>
        )}

        {sale.order.note && (
          <Card>
            <CardHeading title="Note" />
            {sale.order.note}
          </Card>
        )}

        <Card>
          <Box paddingBlockEnd="300">
            <InlineStack align="space-between" wrap={false}>
              <Text variant="headingMd" as="h2">
                Lines
              </Text>
              <InlineStack gap="300">
                <Popover
                  active={smallLabelActive}
                  activator={
                    <Button disclosure onClick={() => setSmallLabelActive(true)}>
                      Print small labels
                    </Button>
                  }
                  onClose={() => setSmallLabelActive(false)}>
                  <ActionList
                    items={[
                      { content: "With qty (one label per line)", onAction: () => printAllPartLabels(sale.lines, true, true) },
                      { content: "Without qty (one label per individual item)", onAction: () => printAllPartLabels(sale.lines, false, true) },
                    ]}
                  />
                </Popover>
                <Popover
                  active={bigLabelActive}
                  activator={
                    <Button disclosure onClick={() => setBigLabelActive(true)}>
                      Print big labels
                    </Button>
                  }
                  onClose={() => setBigLabelActive(false)}>
                  <ActionList
                    items={[
                      { content: "With qty (one label per line)", onAction: () => printAllPartLabels(sale.lines, true, false) },
                      { content: "Without qty (one label per individual item)", onAction: () => printAllPartLabels(sale.lines, false, false) },
                    ]}
                  />
                </Popover>
              </InlineStack>
            </InlineStack>
          </Box>

          <DataTable
            verticalAlign="middle"
            increasedTableDensity
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              "text",
              // "numeric",
              // "numeric",
              "text",
              "text",
              "text",
              "text",
            ]}
            headings={[
              "Code",
              "Description",
              "Qty",
              "Remaining",
              "Stock",
              "Fulfilled",
              "Fulfill",
              "Status",
              // "Unit price",
              // "Line total",
              "Small label (with qty printed)",
              "Small label",
              "Label (with qty printed)",
              "Label",
            ]}
            rows={sale_lines_rows}
          />
        </Card>

        {/* <Card title="Translated lines" sectioned>
            Translated lines will come here
          </Card> */}

        <Layout>
          <Layout.Section variant="oneThird">
            <BlockStack gap="400">
              <SummaryBox sale={sale} loaded={loaded} />
              <DocsBox sale={sale} loaded={loaded} />
              <TimelineBox timeline={sale.timeline} loaded={loaded} />
            </BlockStack>
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <ShippingInfoBox sale={sale} loaded={loaded} getSale={getSale} setToast={setToast} />
          </Layout.Section>
          <Layout.Section variant="oneThird">
            <SerialsBox sale={sale} getSale={getSale} />
          </Layout.Section>
        </Layout>
      </BlockStack>
    </Page>
  );
}
