import { helpQR } from "../dataImg";

function footerQrBigLabel(doc) {
  //footer
  doc.addImage(helpQR, "png", 8, 130, 25, 25, "", "FAST");
  doc.setFont("helvetica", "bold");
  doc.text("Reach out to us", 35, 133);
  doc.setFont("helvetica", "normal");
  doc.text("help.perfectmoose.com", 35, 139);
  doc.text("help@perfectmoose.com", 35, 144);
  doc.text("+32 52 49 90 79", 35, 149);
  doc.text("@perfectmoose.foamer", 35, 154);
}

function footerQrBigLabelLandscape(doc) {
  //footer
  doc.addImage(helpQR, "png", 5, 73, 25, 25, "", "FAST");
  doc.setFont("helvetica", "bold");
  doc.text("Reach out to us", 32, 76);
  doc.setFont("helvetica", "normal");
  doc.text("help.perfectmoose.com", 32, 81);
  doc.text("help@perfectmoose.com", 32, 86);
  doc.text("+32 52 49 90 79", 32, 91);
  doc.text("@perfectmoose.foamer", 32, 96);
}

export { footerQrBigLabel, footerQrBigLabelLandscape };
