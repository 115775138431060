import { useState, useCallback, useEffect } from "react";
import { Button, DescriptionList, Modal, TextField } from "@shopify/polaris";
import { LabelPrinterIcon } from "@shopify/polaris-icons";
import { printQCLabel } from "../../dymo/labels/qc_labels/qcLabels";
//✔️ ✖️

export const QCLabelPrintModal = ({ qc, purchaseNumber }) => {
  const [active, setActive] = useState(false);
  const [savingResult, setSavingResult] = useState(false);
  const [label, setLabel] = useState({});

  const handleModal = useCallback(() => {
    setActive(!active);
  }, [active]);

  const activator = <Button icon={LabelPrinterIcon} size='slim' onClick={handleModal} />;

  const handleSave = () => {
    setSavingResult(true);
    printQCLabel(label);
    setSavingResult(false);
    setActive(false);
  };

  useEffect(() => {
    if (active)
      setLabel({
        qcId: qc.ID,
        orderNumber: purchaseNumber,
        supplier: qc.stockchange.product.supplier.name,
        partId: qc.stockchange.product.id,
        partName: qc.stockchange.product.name,
        quantity: qc.stockchange.quantity,
        qcDate: qc.Timestamp.substring(0, 10),
        status: qc.QualityPassed ? "OK" : `× FAILED: ${qc.classification_type.classification}`,
        classification: qc.classification_type.classification,
      });
  }, [qc, active]);

  return (
    <Modal open={active} onClose={handleModal} activator={activator} title="Print QC label" primaryAction={{ content: "Download label", onAction: () => handleSave(label), loading: savingResult }}>
      <Modal.Section>
        <DescriptionList
          items={[
            { term: "Purchase number", description: label.orderNumber },
            { term: "Supplier", description: label.supplier },
            {
              term: "Status",
              description: "",
              // <Stack spacing="baseTight">
              //   <Text>{qc.QualityPassed ? "✅" : "❌"}</Text>
              //   <Text>{!qc.QualityPassed ? label.classification : ""}</Text>
              // </Stack>
            },
            { term: "Part ID", description: label.partId },
            { term: "Part name", description: label.partName },
            {
              term: "Quantity",
              description: (
                <div style={{ width: "200px" }}>
                  <TextField type="number" id="quantity" value={label.quantity} onChange={(e) => setLabel({ ...label, quantity: e })} />
                </div>
              ),
            },
          ]}
        />
      </Modal.Section>
    </Modal>
  );
};
