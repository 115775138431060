import { FormLayout, TextField } from "@shopify/polaris";
import ProductSelect from "../../../shared/components/productSelect";

export const BomForm = ({ workOrder, handleChange, getBomEntries }) => {
  return (
    <FormLayout>
      <div>
        <div style={{ paddingBottom: "5px" }}>Part to produce</div>
        <ProductSelect
          value={workOrder.OnderdelenId.value}
          onChange={async (value) => {
            handleChange(value, "OnderdelenId");
            await getBomEntries(value);
          }}
        />
      </div>

      <FormLayout.Group>
        <TextField label="Quantity" id="Qty" type="number" value={workOrder.Qty} onChange={handleChange} />
        <TextField label="Due date" id="DueDate" type="date" value={workOrder.DueDate} onChange={handleChange} />
      </FormLayout.Group>
    </FormLayout>
  );
};
