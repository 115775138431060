import { Badge } from "@shopify/polaris";

export const getStillOnOrderBadge = (stillOnOrder) => {
  if (stillOnOrder) {
    let numberFormatter = new Intl.NumberFormat("en-US");
    if (stillOnOrder > 0) return <Badge tone="warning">{numberFormatter.format(stillOnOrder)}</Badge>;
    return <Badge tone="success">{stillOnOrder}</Badge>;
  }
  return <Badge tone="success">0</Badge>;
};

export const getQcStatusBadge = (item) => {
  if (item.ResolutionDate)
    return (
      <Badge progress="complete" tone="warning">
        QC resolved
      </Badge>
    );
  if (item.QualityPassed)
    return (
      <Badge progress="complete" tone="success">
        QC passed
      </Badge>
    );
  return (
    <Badge progress="incomplete" tone="critical">
      QC failed
    </Badge>
  );
};
