import { Page, Text, Badge, Tabs, Banner, Card, Layout, Box, InlineStack, BlockStack, Divider } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { useState, useCallback, useEffect } from "react";
import ContactsTable from "./components/companycontacts.jsx";
import PurchasesCompanyPage from "./components/PurchasesCompany.jsx";
import { getRequest } from "../../shared/components/functions.jsx";
import { CardHeading } from "../../shared/components/CardHeading.jsx";
import ProductsCompany from "./components/ProductsCompany.jsx";

/*
    STILL TO DO:
    - Financials: payment contdions (already fetched, but something wrong with datamatching access)
    - Invoicing address if it is alternative 
    - Status reason only shown when gesperd
    - Btw plichtig bit veld
    - display remark fields in a prettier way
*/

function NewlineText(text) {
  const newText = text.split("\r\n\r\n").map((str, idx) => {
    return (
      <Text key={idx} className="new_line">
        {str}
      </Text>
    );
  });
  return newText;
}

function CompanyPage() {
  let { id } = useParams();

  const languages = {
    1: "Dutch",
    2: "French",
    3: "English",
    4: "German",
  };

  //diffent specific company entities (all the differnt tables)
  const [company, setCompany] = useState(0);
  const [selected, setSelected] = useState(0);

  const handleTabChange = useCallback((selectedTabIndex) => setSelected(selectedTabIndex), []);

  useEffect(() => {
    async function fetchCompany() {
      const data = await getRequest(`/api/companies/${id}`);
      setCompany(data[0]);
    }
    fetchCompany();
  }, []);

  const particulier = company && company.Particulier ? <Badge>Private</Badge> : "";
  const supplier = company && company.Leverancier ? <Badge>Supplier</Badge> : "";
  const customer = company && company.Klant ? <Badge>Customer</Badge> : "";

  const company_status =
    company &&
    (company.Status === "Actief" ? (
      <Badge tone="success">Actief</Badge>
    ) : company.Status === "Gesperd" ? (
      <Badge tone="critical">Gesperd</Badge>
    ) : company.Status === "In Aanvraag" ? (
      <Badge tone="info">In aanvraag</Badge>
    ) : company.Status === "Inactief" ? (
      <Badge tone="warning">Inactief</Badge>
    ) : (
      ""
    ));

  const company_banner = company && company.Status === "Gesperd" && (
    <Box paddingBlockEnd="400">
      <Banner title="COMPANY BLOCKED!" tone="critical">
        <Text>This company is blocked due to overdue payments or other reasons....</Text>
        {company.StatusReden && (
          <Box>
            <Text as="p" fontWeight="bold">
              Specified reason:
            </Text>
            <Text>{company.StatusReden}</Text>
          </Box>
        )}
      </Banner>
    </Box>
  );

  const company_info_address = company && (
    <Card>
      <CardHeading title="Company overview" />
      <BlockStack gap="300">
        <Box>
          <CardHeading title="Default address" />
          <BlockStack>
            {company.NAAM}
            {company.STRAAT_NR && <Text>{company.STRAAT_NR}</Text>}
            {company.WOONPL && <Text>{company.WOONPL}</Text>}
            {company.POSTNR && <Text>{company.POSTNR}</Text>}
            {company.Land_NL && <Text>{company.Land_NL}</Text>}
          </BlockStack>
        </Box>

        <BlockStack gap="100">
          <InlineStack gap="200">
            <Text>Language:</Text>
            <Box>
              <Badge tone="info">{languages[company.TaalID]}</Badge>
            </Box>
          </InlineStack>
          {company.TEL ? (
            <InlineStack gap="200">
              <Text>Tel:</Text>
              {company.TEL}
            </InlineStack>
          ) : null}
          {company.FAX ? (
            <InlineStack gap="200">
              <Text>Fax:</Text>
              {company.FAX}
            </InlineStack>
          ) : null}
          {company.GSM ? (
            <InlineStack gap="200">
              <Text>Gsm:</Text>
              {company.GSM}
            </InlineStack>
          ) : null}
          {company["web-site"] ? (
            <InlineStack gap="200">
              <Text>Website:</Text>
              {company["web-site"]}
            </InlineStack>
          ) : null}
          {company["E-mail"] ? (
            <InlineStack gap="200">
              <Text>Email:</Text>
              {company["E-mail"]}
            </InlineStack>
          ) : null}
        </BlockStack>

        <Divider />

        <Box>
          <CardHeading title="Financial" />
          <BlockStack gap="100">
            {company.BTW && (
              <InlineStack gap="200">
                <Text>VAT:</Text>
                <Box>
                  <Badge>{company.BTW}</Badge>
                </Box>
              </InlineStack>
            )}
            <InlineStack gap="200">
              <Text>Payment conditions:</Text>
              <Box>
                {particulier}
                {customer}
                {supplier}
              </Box>
            </InlineStack>
          </BlockStack>
        </Box>

        <Divider />

        <Box>
          <CardHeading title="Company info" />
          <BlockStack>
            {company.SECTOR && (
              <InlineStack gap="200">
                <Text>Sector:</Text>
                {company.SECTOR}
              </InlineStack>
            )}
            {company.Artikel && (
              <InlineStack gap="200">
                <Text>Artikel:</Text>
                {company.Artikel}
              </InlineStack>
            )}
            <InlineStack gap="200">
              <Text>Workforce:</Text>
              {company.personeelsbestand}
            </InlineStack>
          </BlockStack>
        </Box>
      </BlockStack>
    </Card>
  );

  const company_info_remarks = company && (
    <Card>
      <CardHeading title="Remarks and notes" />
      {company.OPMERKING && (
        <BlockStack>
          <CardHeading title="General remarks" />
          {NewlineText(company.OPMERKING)}
        </BlockStack>
      )}
      {company.Opmerkingen1 && (
        <BlockStack>
          <CardHeading title="Installation note remarks" />
          {NewlineText(company.Opmerkingen1)}
        </BlockStack>
      )}
      {company.Opmerkingen2 && (
        <BlockStack>
          <CardHeading title="Pay attention next visit" />
          {NewlineText(company.Opmerkingen2)}
        </BlockStack>
      )}
    </Card>
  );

  const tabs = [
    {
      id: "general-info",
      content: "General",
      comp: (
        <Layout>
          <Layout.Section>{company_info_address}</Layout.Section>
          <Layout.Section>{company_info_remarks}</Layout.Section>
          <Layout.Section variant="oneThird">
            <Card>
              <CardHeading title="Contacts" />
              <ContactsTable id={id} />
            </Card>
          </Layout.Section>
        </Layout>
      ),
    },
    {
      id: "orders",
      content: "Purchase orders",
      comp:  <PurchasesCompanyPage FIRNR={id} />,
    },
    {
      id: "products",
      content: "Products",
      comp: <ProductsCompany FIRNR={id} />,
    }
  ];

  return company ? (
    <Page
      fullWidth
      breadcrumbs={[{ content: "Companies", url: "/companies" }]}
      title={company.NAAM}
      subtitle={`#${company.Klantnummer}, ${company.WOONPL} (${company.Land_NL})`}
      titleMetadata={
        <InlineStack gap="100">
          {particulier}
          {supplier}
          {customer}
          {company_status}
        </InlineStack>
      }>
      {company_banner}
      <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
        {tabs[selected].comp}
      </Tabs>
    </Page>
  ) : (
    "Company not found"
  );
}

export default CompanyPage;
