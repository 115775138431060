import { ContextualSaveBar } from '@shopify/polaris';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { handleSaveOnEnter } from '../functions';

export const SaveBar = ({ isDirty, handleSave, handleDiscard, savingResult }) => {
  const location = useLocation();

  const discard = () => {
    if (window.location.pathname.split('/')[2] === 'new') {
      //if in new resource go one page back in history
      window.history.back();
      handleDiscard();
    } else {
      handleDiscard();
    }
  };

  const handleLocationChange = () => {
    window.onbeforeunload = () => {
      if (isDirty) {
        console.log('is Dirty', isDirty);
        return 'message';
      } else {
        window.onbeforeunload = null;
      }
    };
  };

  useEffect(() => {
    handleLocationChange(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, location.pathname]);

  return isDirty ? (
    <div onKeyDown={(e) => handleSaveOnEnter(e, handleSave)}>
      <ContextualSaveBar
        message={`Unsaved changes, click save or press enter.`}
        saveAction={{
          onAction: handleSave,
          loading: savingResult
        }}
        fullWidth={false}
        alignContentFlush
        discardAction={{
          onAction: () => discard()
        }}
      />
      </div>
  ) : null;
};