import { useState, useEffect, useCallback } from "react";
import { Badge, DataTable, Page, Card, Filters, Link, Button } from "@shopify/polaris";
import { EditIcon } from "@shopify/polaris-icons";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequest, getTableFooter, truncate } from "../../shared/components/functions";
import { useToggle } from "../../shared/hooks/useToggle";
import { SerialsModal } from "../../shared/components/serialHelpers/serialsModal";
import { Toasts } from "../../shared/components/toasts/toasts";
import { initialSerial } from "../../shared/components/serialHelpers/helpers";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function SerialsPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
      ["user", { type: "text", name: "Check by", value: url.get("user") || "" }],
    ])
  );
  const [modal, setModal] = useToggle(false);
  const [isEdit, setIsEdit] = useState(false);
  const [serial, setSerial] = useState(initialSerial);
  const [toast, setToast] = useState(null);
  const [loaded, setLoaded] = useState(false);

  const fetchSerials = useCallback(async () => {
    let filterString = filtersToApiUrl(filters);
    const data = await getRequest(`/api/serials?${filterString}`);
    if (data) {
      setItems(data);
      setLoaded(true);
    }
    if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
  }, [filters]);

  const fetchSerialToEdit = useCallback(async (id) => {
    const data = await getRequest(`/api/serials/${id}`);
    if (data) setSerial(data);
  }, []);

  useEffect(() => {
    fetchSerials();
  }, [filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        return [
          <Link removeUnderline url={`/serials/${item.serialnumber}`}>
            {item.serialnumber}
          </Link>,
          <Badge>{item.qualityCheckDate}</Badge>,
          <Badge>{item.Afkorting}</Badge>,
          item.recipe,
          item.softwareVersion,
          item.hardwareVersion,
          item.hardwareBoilerVersion,
          <Badge tone={item.coupled === 0 ? "success" : "attention"}>{item.coupled === 0 ? "Stock" : "Sold to customer"}</Badge>,
          item.RfidLocation && item.RfidLocation !== " " && item.RfidLocation !== "null" ? <Badge>{item.RfidLocation}</Badge> : null,
          truncate(item.remarks, 30),
          <Button icon={EditIcon} onClick={() => openModal(item.serialnumber)} size="slim" varian="plain" />,
        ];
      })
    : [];

  const openModal = async (id) => {
    await fetchSerialToEdit(id);
    setIsEdit(true);
    setModal();
  };

  const closeModal = () => {
    setSerial(initialSerial);
    fetchSerials();
    setIsEdit(false);
    setModal();
  };

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="Serial numbers" fullWidth primaryAction={{ content: "Add serial number", onAction: setModal }}>
      {modal ? <SerialsModal modalActive={modal} closeModal={closeModal} isEditSerial={isEdit} isRFID={false} serialToEdit={serial} setSerialToast={setToast} /> : null}
      <Toasts toast={toast} setToast={setToast} />
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter serials"
        />
        <DataTable
          verticalAlign="middle"
          increasedTableDensity
          columnContentTypes={["text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "text", "text"]}
          headings={[
            <b>Serial number</b>,
            <b>Check date</b>,
            <b>Check by</b>,
            <b>Recipe</b>,
            <b>Software</b>,
            <b>Hardware</b>,
            <b>Hardware boiler</b>,
            <b>Status</b>,
            <b>Station</b>,
            <b>Remarks</b>,
            <b>Edit</b>,
          ]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "serials")}
        />
      </Card>
    </Page>
  );
}
