import { useState, useEffect } from "react";
import { Modal, Button, DataTable } from "@shopify/polaris";
import { MaximizeIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";
import { getRequest } from "../../../shared/components/functions";
import { useToggle } from "../../../shared/hooks/useToggle";

export default function QuickViewSalesOrderComponent({ id }) {
  const navigate = useNavigate();

  const [popoverActive, setPopoverActive] = useToggle(false);
  const [sale, setSale] = useState({ sale: "", lines: [], qcs: [] });

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest(`/api/sales/${id}`);
      setSale(data);
    }
    if (popoverActive) fetchData();
  }, [popoverActive]);

  const activator = <Button icon={MaximizeIcon} size="slim" onClick={setPopoverActive} />;

  const formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "EUR" });

  const rows = sale.lines.map((line) => [line.produktnr, line.produkthulpnr, line.tekstlijn, line.aantal, formatter.format(line.VP), formatter.format(line.aantal * line.VP)]);

  return (
    <Modal
      size="large"
      activator={activator}
      open={popoverActive}
      onClose={setPopoverActive}
      title={`Sales order #${sale.order?.order_number}`}
      primaryAction={{ content: "Open sales order", onAction: () => navigate(`/sales/${id}`) }}>
      <Modal.Section>
        <DataTable columnContentTypes={["text", "text", "text", "numeric", "numeric", "numeric"]} headings={["Code", "Reference", "Description", "Qty", "Value Item", "Value Total"]} rows={rows} />
      </Modal.Section>
    </Modal>
  );
}
