import { useState, useEffect, useCallback } from "react";
import { Layout, Page, Card, Text, Badge, Link, Banner, Button, InlineGrid, BlockStack } from "@shopify/polaris";
import { useParams } from "react-router-dom";
import { formatDate, getRequest, postRequest } from "../../shared/components/functions";
import { TreeConsumer } from "./components/TreeConsumer";
import { cancelDbWorkOrder, finishDbWorkOrder, getProductBom } from "../../database/db";
import { CardHeading } from "../../shared/components/CardHeading";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function WorkOrderPage() {
  let { id } = useParams();
  const [workOrder, setWorkOrder] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [bomEntries, setBomEntries] = useState([]);
  const [loadingFinishingOrder, setLoadingFinishingOrder] = useState(false);
  const [S3ObjectUrl, setS3ObjectUrl] = useState([]);
  const [docWarning, setDocWarning] = useState(false);

  const fetchWorkorder = useCallback(async () => {
    const data = await getRequest(`/api/workorders/${id}`);
    if (data) {
      setWorkOrder(data[0]);
      const prod = await getRequest(`/api/products/${data[0].OnderdelenId}`);
      const types = JSON.parse(prod[0].Files === "null" ? "[]" : prod[0].Files);
      if (Array.isArray(types)) {
        const instructions = types.filter((k) => k.type === "work_instruction");
        let s3data = [];
        for (let i = 0; i < instructions.length; i++) {
          const pdf = await postRequest(`/api/aws/s3/download`, { keys: [instructions[i].key] });
          if (pdf[0].key.endsWith("pdf")) s3data.push(pdf[0]);
          else setDocWarning(true);
        }
        setS3ObjectUrl(s3data);
      }
    }
    setIsLoaded(true);
  }, []);

  useEffect(() => {
    fetchWorkorder();
  }, []);

  useEffect(() => {
    async function fetchData() {
      let rows = [];
      if (workOrder.VariantName) {
        const data = await getRequest(`/api/${workOrder.StructureName.toLowerCase()}/versions/${workOrder.VariantName}/assemblybom/${workOrder.OnderdelenId}`);
        rows = data.map((d) => ({ id: d.PartId, name: d.ProductNameNL, qty: d.Quantity }));
      } else {
        const data = await getProductBom(workOrder.OnderdelenId);
        rows = data.map((d) => ({ id: d.SubOnderdelenId, name: d.subOnderdelenName, qty: d.Qty }));
      }
      setBomEntries(rows);
    }
    if (workOrder.OnderdelenId) fetchData();
  }, [workOrder.OnderdelenId]);

  async function finishWorkOrder() {
    if (confirm("Are you sure you want to finish this work order? The appropriate inventory changes will be done")) {
      setLoadingFinishingOrder(true);
      await finishDbWorkOrder(
        id,
        bomEntries.map((entry) => ({ productId: entry.id, Qty: entry.qty * workOrder.Qty }))
      );
      await fetchWorkorder();
      setLoadingFinishingOrder(false);
    }
  }

  async function cancelWorkOrder() {
    if (confirm("Are you sure you want to cancel this work order? This will not consume any inventory.")) {
      await cancelDbWorkOrder(id);
      await fetchWorkorder();
    }
  }

  const statusBanner = workOrder.Status !== "OPEN" && (
    <Layout.Section>
      <Banner tone={workOrder.Status === "FINISHED" ? "success" : "critical"} title={workOrder.Status === "FINISHED" ? "This work order has been finished" : "This work order has been cancelled"} />
    </Layout.Section>
  );

  if (!isLoaded) return <PageSpinner />;

  return isLoaded ? (
    <Page
      backAction={{ content: "Work orders", url: "/workorders" }}
      title={`Work order #${id}`}
      titleMetadata={
        <Badge tone="info">
          <Text variant="headingMd" as="h5">
            {workOrder.VariantName && workOrder.StructureName ? "DEVICE" : "SPARE PARTS"}
          </Text>
        </Badge>
      }
      fullWidth
      primaryAction={{
        content: "FINISH WORK ORDER",
        loading: loadingFinishingOrder,
        disabled: workOrder.Status !== "OPEN",
        onAction: finishWorkOrder,
      }}
      secondaryActions={
        workOrder.Status === "OPEN" ? (
          <Button variant="primary" tone="critical" onClick={cancelWorkOrder} disabled={workOrder.Status !== "OPEN"}>
            Cancel work order
          </Button>
        ) : (
          {}
        )
      }>
      <Layout>
        {statusBanner}
        <Layout.Section variant={S3ObjectUrl.length > 0 ? "oneHalf" : "fullWidth"}>
          <BlockStack gap="400">
            <Card>
              <CardHeading title="Summary" />
              <InlineGrid columns={3} gap="200">
                <Textbox
                  label="Product name"
                  content={
                    <Link removeUnderline url={`/products/${workOrder.OnderdelenId}`}>
                      {workOrder.ProductNameNL}
                    </Link>
                  }
                />
                <Textbox label="Quantity" content={workOrder.Qty} />
                <Textbox label="Status" content={<Badge tone={workOrder.Status === "CANCELLED" ? "critical" : workOrder.Status === "OPEN" ? "success" : ""}>{workOrder.Status}</Badge>} />
              </InlineGrid>

              <InlineGrid columns={3}>
                <Textbox label="Created at" content={workOrder.CreatedAt ? formatDate(workOrder.CreatedAt) : null} />
                <Textbox label="Assignment date" content={workOrder.AssignmentDate ? formatDate(workOrder.AssignmentDate) : null} />
                <Textbox label="Due date" content={workOrder.DueDate ? formatDate(workOrder.DueDate) : null} />
              </InlineGrid>

              <InlineGrid columns={3}>
                <Textbox
                  label="Sale"
                  content={
                    workOrder.OrderbevestigingID ? (
                      <Link removeUnderline url={`/sales/${workOrder.OrderbevestigingID}`}>
                        {workOrder.OrderbevestigingID}
                      </Link>
                    ) : null
                  }
                />
                <Textbox label="Assignees" content={workOrder.Afkorting} />
              </InlineGrid>
            </Card>

            <Card>
              <CardHeading title="Bill of material" />
              <TreeConsumer qtyToProduce={workOrder.Qty} bomEntries={bomEntries} />
            </Card>
          </BlockStack>
        </Layout.Section>

        {S3ObjectUrl && S3ObjectUrl.length > 0 && (
          <Layout.Section>
            <Card>
              <CardHeading title="Work instructions" />
              <BlockStack gap="400">
                {docWarning && <Banner tone="warning">Some of the work instruction files cannot be displayed. Please check if they are correctly uploaded as PDF.</Banner>}
                {S3ObjectUrl.map((u, idx) => (
                  <iframe key={idx} src={u.url} embedded="true" style={{ width: "100%", minHeight: "1000px" }}></iframe>
                ))}
              </BlockStack>
            </Card>
          </Layout.Section>
        )}
      </Layout>
    </Page>
  ) : null;
}

const Textbox = ({ label, content }) => {
  if (!content) return null;
  return (
    <div style={{ padding: "10px 0" }}>
      <Text variant="headingSm" as="p">
        {label}:
      </Text>
      <div style={{ whiteSpace: "pre-wrap", fontSize: "18px", lineHeight: "25px" }}>{content}</div>
    </div>
  );
};
