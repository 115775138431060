import { useState, useEffect } from "react";
import { Badge, DataTable, Link, Page, Card, Filters } from "@shopify/polaris";
import { formatToCurrency, getTableFooter, getRequest, postRequest } from "../../shared/components/functions";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function ProductsPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const statusOptions = [
    { label: "All", value: "all" },
    { label: "Active", value: "0" },
    { label: "Distcontinued", value: "1" },
  ];

  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
      ["name", { type: "text", name: "Name", value: url.get("name") || "" }],
      ["reference", { type: "text", name: "Reference", value: url.get("reference") || "" }],
      ["supplier", { type: "text", name: "Supplier", value: url.get("supplier") || "" }],
      ["status", { type: "status", name: "Status", value: url.get("status") || "all", options: statusOptions }],
    ])
  );

  useEffect(() => {
    async function fetchData() {
      let filterString = filtersToApiUrl(filters);
      const data = await getRequest(`/api/products?${filterString}`);
      setItems(data);
      setLoaded(true);
      if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
    }
    if (filters) fetchData();
  }, [filters]);

  const rows = Array.isArray(items)
    ? items.map((item) => {
        let productImageS3Key = "";
        if (item.Image && item.Image !== "null") productImageS3Key = JSON.parse(item.Image)[0];

        return [
          <Link removeUnderline url={"/products/" + item.ProductID}>
            {item.ProductID}
          </Link>,
          <ImageThumbNailViewer s3Key={productImageS3Key} key={`product_img_${item.ProductID}`} />,
          item.ProductNameNL,
          item.Productref,
          <Link removeUnderline url={`/companies/${item.supplier.ID}`}>
            {item.supplier.name}
          </Link>,
          <Badge tone={item.Discontinued ? "critical" : "success"}>{item.Discontinued ? "Discontinued" : "Active"}</Badge>,
          formatToCurrency(item.Eenheidsaankoopprijs),
          formatToCurrency(item["Eenheids verkoopprijs"]),
        ];
      })
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="Products" fullWidth>
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter products"
        />
        <DataTable
          verticalAlign="middle"
          columnContentTypes={["text", "text", "text", "text", "text", "text", "numeric", "numeric"]}
          headings={[<b>Code</b>, <b>Img</b>, <b>Product name</b>, <b>Product reference</b>, <b>Supplier</b>, <b>Status</b>, <b>Purchase price</b>, <b>Selling price</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "products")}
        />
      </Card>
    </Page>
  );
}

export const ImageThumbNailViewer = ({ s3Key }) => {
  const [url, setUrl] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);

  useEffect(() => {
    if (!s3Key) {
      setUrl("https://www.kaffee-1.de/images/logoperfectmooseverticalblack.png?crc=4231604857");
      return;
    }

    async function fetchUrl() {
      const data = await postRequest(`/api/aws/s3/download`, { keys: [s3Key] });
      setUrl(data[0].url);
    }

    fetchUrl();
  }, [s3Key]);

  const containerStyle = {
    marginTop: "-3px",
    marginBottom: "-3px",
    padding: "0px",
    overflow: "hidden",
    height: "40px",
    width: "40px",
    position: "relative",
    cursor: "pointer",
  };

  const imageStyleBase = {
    border: "1px solid gray",
    borderRadius: "5px",
    objectFit: "cover",
    height: "100%",
    width: "100%",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const imageFullscreenStyle = {
    objectFit: "contain",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "80vh",
    width: "80vw",
    zIndex: 1000,
    border: "1px solid gray",
    borderRadius: "5px",
    backgroundColor: "white",
    cursor: "zoom-out",
  };

  return (
    <>
      <div style={containerStyle} onClick={() => setIsFullscreen(true)}>
        <img src={url} alt={s3Key} style={imageStyleBase} />
      </div>

      {isFullscreen && <img src={url} alt={s3Key} style={imageFullscreenStyle} onClick={() => setIsFullscreen(false)} />}
    </>
  );
};
