import { BlockStack, Box, InlineStack, Link, Text } from "@shopify/polaris";
import { Timer } from "./workstationTimer";
import { useCallback, useEffect, useRef, useState } from "react";
import { getRequest } from "../../../shared/components/functions";
import JsBarcode from "jsbarcode";
import { getCodeString } from "../../../shared/components/serialHelpers/helpers";

const Barcode = ({ id, value }) => {
  const svgRef = useRef(null);
  useEffect(() => {
    JsBarcode(svgRef.current, value, { width: 4 });
  }, [value]);
  return <svg id={id} ref={svgRef} />;
};

function getSecuTesterCodeForString(serialString) {
  if (serialString.includes("PMJ20")) return "JACK230";
  if (serialString.includes("PMG10")) return "MOOSE_110V";
  if (serialString.includes("PMG20")) return "MOOSE_230V";
  return "NO_SECU_CODE";
}

export const WorkstationHeader = ({ workStationName, timestamp, serialnumber, salenumber, serial }) => {
  const [sale, setSale] = useState({});
  const [loaded, setLoaded] = useState(false);

  const serialString = getCodeString(serial?.remarks || "");
  const productString = serialString?.replace(/\*/g, "") || "";
  const secuValue = getSecuTesterCodeForString(productString);

  const fetchSale = useCallback(async () => {
    const saleData = await getRequest(`/api/sales/${salenumber}`);
    if (saleData) setSale(saleData);
  }, []);

  useEffect(() => {
    if (salenumber) fetchSale();
    setLoaded(true);
  }, [salenumber]);

  return loaded ? (
    <Box paddingBlockEnd="400">
      <InlineStack align="space-between">
        <BlockStack gap="300">
          <Text variant="headingXl">{workStationName}</Text>
          <InlineStack>
            <Text variant="headingLg" as="h5">
              Due date:
            </Text>
            <Text variant="headingLg" as="h5">
              <div style={{ height: "24.5px" }}>{sale.order?.delivery_term || ""}</div>
            </Text>
          </InlineStack>

          <InlineStack>
            <Text variant="headingLg" as="h5">
              Sales order:
            </Text>
            <Text variant="headingLg" as="h5">
              <div style={{ height: "24.5px" }}>
                {sale.order ? (
                  <Link removeUnderline url={`/sales/${salenumber}`}>
                    #{sale.order?.order_number}
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </Text>
          </InlineStack>

          <InlineStack>
            <Text variant="headingLg" as="h5">
              Customer:
            </Text>
            <Text variant="headingLg" as="h5">
              <div style={{ height: "24.5px" }}>
                <Link removeUnderline url={`https://admin.schuilenburg.be/companies/${sale.order?.customer.company_id}`}>
                  {sale.order?.customer.company_name}
                </Link>
              </div>
            </Text>
          </InlineStack>
        </BlockStack>

        <InlineStack gap="600">
          {serialnumber && <Barcode id="serialnumber" value={serialnumber} />}
          {productString && secuValue && <Barcode id="secu" value={secuValue} />}
        </InlineStack>

        <div style={{ width: "260px" }}>
          <BlockStack gap="300">
            <InlineStack gap="100">
              <Text variant="headingLg" as="h5">
                Serial number:
              </Text>
              <Text variant="headingLg" as="h5">
                <Link removeUnderline url={`/serials/${serialnumber}`}>
                  {serialnumber}
                </Link>
              </Text>
            </InlineStack>
            <InlineStack gap="100">
              <Text variant="headingLg" as="h5">
                Timer:
              </Text>
              <div style={{ height: "24.5px" }}>
                <Timer timestamp={timestamp} />
              </div>
            </InlineStack>
            <InlineStack gap="100">
              <Text variant="headingLg" as="h5">
                String:
              </Text>
              <Text variant="headingLg" as="h5">
                <div style={{ height: "24.5px" }}>{productString}</div>
              </Text>
            </InlineStack>
          </BlockStack>
        </div>
      </InlineStack>
    </Box>
  ) : null;
};
