import { Box, Divider, Text } from "@shopify/polaris";
import { formatRoundedWeight, formatToCurrency } from "../../../shared/components/functions";
import { CardHeading } from "../../../shared/components/CardHeading";

export const ProductInfo = ({ product, units }) => {
  return (
    <>
      <Textbox label="Supplier" content={product.supplierName} />
      <Textbox label="Reference" content={product.Productref} />
      <Textbox label="Product name NL" content={product.ProductNameNL} />
      <Textbox label="Product name FR" content={product.ProductNameFR} />
      <Textbox label="Product name DE" content={product.ProductNameDE} />
      <Textbox label="Product name EN" content={product.ProductNameUK} />
      <div style={{ display: "flex", width: "30%", justifyContent: "space-between" }}>
        <Textbox label="Unit of measure" content={units.filter((u) => u.EenheidID === product.EenheidID)[0]?.Eenheid} />
        <Textbox label="Qty per unit" content={product.EenheidAantal} />
      </div>
      <Textbox label="Description" content={product.Omschrijving} />
      <Textbox label="Discontinued" content={product.Discontinued ? "Product is discontinued" : "Product is active"} />

      <Divider />
      <Box paddingBlockStart="400">
        <CardHeading title="Pricing" />
        <div style={{ display: "flex", width: "30%", justifyContent: "space-between" }}>
          <Textbox label="Purchase price" content={formatToCurrency(product.Eenheidsaankoopprijs)} />
          <Textbox label="Selling price" content={formatToCurrency(product["Eenheids verkoopprijs"])} />
        </div>
      </Box>

      <Divider />
      <Box paddingBlockStart="400">
        <CardHeading title="Shipping & export" />
        <div style={{ display: "flex", width: "30%", justifyContent: "space-between" }}>
          <Textbox label="Weight" content={formatRoundedWeight(product.Kg)} />
          <Textbox label="HS code" content={product.HScode} />
        </div>
      </Box>
    </>
  );
};

const Textbox = ({ label, content }) => {
  return content ? (
    <div style={{ padding: "10px 0" }}>
      <Text variant="headingMd" as="p">
        {label}:
      </Text>
      <div style={{ whiteSpace: "pre-wrap", fontSize: "18px", lineHeight: "25px" }}>{content}</div>
    </div>
  ) : null;
};
