import { jsPDF } from "jspdf";
import qrcode from "qrcode-generator";

function printProductLabel(product, inventory) {
  const doc = new jsPDF("l", "mm", [89, 41]);
  doc.setFont("helvetica", "normal");

  const nameWidth = doc.getTextWidth(product.ProductNameNL);
  let name1 = product.ProductNameNL,
    name2;

  if (nameWidth > 70) {
    name1 = product.ProductNameNL.substring(0, 48);
    name2 = product.ProductNameNL.substring(48);
  }

  if (!name2) {
    doc.setFontSize(10);
    doc.text(name1 || "", 6, 6);
  } else {
    doc.setFontSize(9);
    doc.text(name1 || "", 6, 6);
    doc.text(name2, 8, 9);
  }

  const refWidth = doc.getTextWidth(product.Productref);
  let ref1 = product.Productref,
    ref2;
  if (refWidth > 65) {
    ref1 = product.Productref.substring(0, 40);
    ref2 = product.Productref.substring(40);
  }

  if (!ref2) {
    doc.setFontSize(10);
    doc.text(`Ref: ${ref1 || ""}`, 6, 14);
  } else {
    doc.setFontSize(9);
    doc.text(`Ref: ${ref1}`, 6, 12);
    doc.text(ref2, 8, 15);
  }

  const typeNumber = 0;
  const errorCorrectionLevel = "L";
  const qr = qrcode(typeNumber, errorCorrectionLevel);
  qr.addData(
    `https://production.perfectmoose.com/products/${product.ProductID}`
  );
  qr.make();
  const qrCodeImageData = qr.createDataURL(10, 10);
  doc.addImage(qrCodeImageData, "PNG", 6, 17, 20, 20, '', 'FAST');

  doc.setFontSize(9);
  doc.text(product.supplierName || "", 32, 20);
  doc.setFontSize(12);
  doc.setFont("helvetica", "bold");
  doc.text(`Code: ${product.ProductID || ""}`, 32, 25);
  doc.setFont("helvetica", "normal");
  doc.text(`Rack: ${inventory.Rack || ""}`, 32, 30);
  doc.text(`Legplank: ${inventory.Legplank || ""}`, 32, 35);

  doc.save(`product_${product.ProductID}.pdf`);
}

export { printProductLabel };
