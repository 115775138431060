import { jsPDF } from "jspdf";
import { imgurl } from "../dataImg";

async function printLabel(box) {
  // printed on big shipping labels
  const doc = new jsPDF("p", "mm", [104, 159]);
  doc.setFontSize(20);
  doc.text(`Pitcher Box SN ${box.data.ID}`, 8, 12);
  doc.setFontSize(14);
  doc.text(`#${String(box.data.ProductIDSchuilenburg)} ${String(box.data.Name)}`, 8, 19);
  doc.setFontSize(10);
  let pitchers_sorted = box.pitchers_added.sort((a, b) => {
    if (a.PitcherType < b.PitcherType) return -1;
    if (a.PitcherType > b.PitcherType) return 1;
    return 0;
  });
  for (let i = 0; i < pitchers_sorted.length; i++) {
    doc.text(String(box.pitchers_added[i].RfidSn + "   " + box.pitchers_added[i].PitcherType), 8, 30 + i * 7);
  }

  doc.addImage(imgurl, "png", 63, 140, 40, 15, "", "FAST");
  doc.save(`PitcherBoxSN${box.data.ID}.pdf`);
}

export { printLabel };
