import { useState, useEffect } from "react";
import { Button, Card, Link } from "@shopify/polaris";
import { getRequest } from "../../../shared/components/functions";
import { PageSpinner } from "../../../shared/components/spinner/PageSpinner";

/*
     Mistakes:
     - we are grouping the connected togheter over the lines, this is not correct
     - order should not show up anymore under the machines tab from the moment it has all serials connected
*/

export default function FulfilmentMachines() {
  const [items, setItems] = useState([]);
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    async function fetchData() {
      const data = await getRequest("/api/fulfilment/machines");
      setItems(data);
      setLoaded(true)
    }
    fetchData();
  }, []);

  if (!loaded) return <PageSpinner />;

  return (
    <Card>
      Not fulfilled machines
      {items.map((item, idx) => {
        const status = item.connected_serials < item.to_fulfill ? "❌" : "✅";
        return (
          <div style={{ marginTop: "20px" }} key={`subcomp-${idx}`}>
            <Card key={`${item.OrderbevestigingID}-${idx}`}>
              <Link url={`/sales/${item.OrderbevestigingID}`}>{`${status} # ${item.Orderbevestigingnummer}  | ${item.NAAM} | Connected to order: (${item.connected_serials}/${item.to_fulfill})`}</Link>
              <div>{`x${item.to_fulfill}  ${item.tekstlijn}`}</div>
              <div>{`📆 ${item.Leveringstermijn}`}</div>
              {item.Notities && <div>{`📒 ${item?.Notities}`}</div>}
              {new Array(item.to_fulfill).fill(0).map((_, idx) => {
                return (
                  <div key={`subcomp-sub-${idx}`}>
                    <div style={{ marginTop: "10px", marginRight: "15px" }}>
                      <span style={{ marginRight: "15px" }}>{`${item.config_string}`}</span>
                      <Button size="slim" onClick={() => alert("this is not working yet")}>
                        START PRODUCTION
                      </Button>
                    </div>
                  </div>
                );
              })}
            </Card>
          </div>
        );
      })}
    </Card>
  );
}
