import "@shopify/polaris/build/esm/styles.css";
import enTranslations from "@shopify/polaris/locales/en.json";
import { Frame, ActionList, AppProvider, Navigation, TopBar } from "@shopify/polaris";
import {
  BarcodeIcon,
  FlowerFilledIcon,
  ClipboardCheckFilledIcon,
  PersonIcon,
  DragDropIcon,
  HashtagIcon,
  HomeFilledIcon,
  InventoryIcon,
  ExitIcon,
  ProductIcon,
  SettingsIcon,
  ProductFilledIcon,
  OrderFilledIcon,
  EyeCheckMarkIcon,
  PackageFilledIcon,
  LanguageFilledIcon,
  SearchRecentIcon,
  ConfettiIcon,
  MetafieldsFilledIcon,
  MetaobjectListIcon,
} from "@shopify/polaris-icons";
import { useState, useCallback, useRef, useEffect } from "react";
import { Outlet, useLocation, useNavigate, Link as ReactRouterLink } from "react-router-dom";
import { formatToCurrency, getRequestWithSignal } from "./shared/components/functions.jsx";
import { useToggle } from "./shared/hooks/useToggle.js";
import logo from "./assets/pm_logo.png";
import { useAuthStore } from "./utils/useStore.js";
import { useDebounce } from "./shared/hooks/useDebounce.js";

function Link({ children, url = "", external, ...rest }) {
  // Use a regular a tag for external and download links
  if (isOutboundLink(url) || rest.download) {
    rest.target = "_blank";
    rest.rel = "noopener noreferrer";
    return (
      <a href={url} {...rest} className="Polaris-Link">
        {children}
      </a>
    );
  }
  return (
    <ReactRouterLink to={url} {...rest}>
      {children}
    </ReactRouterLink>
  );
}

function isOutboundLink(url) {
  return /^(?:[a-z][a-z\d+.-]*:|\/\/)/.test(url);
}

function FrameExample() {
  const location = useLocation();
  const navigate = useNavigate();
  const onLoginPage = location.pathname === "/login" ? true : false;
  const skipToContentRef = useRef(null);
  const { user, logout } = useAuthStore();

  const [searchValue, setSearchValue] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [userMenuActive, setUserMenuActive] = useToggle(false);
  const [mobileNavigationActive, setMobileNavigationActive] = useToggle(false);

  const handleSearchResultsDismiss = useCallback(() => {
    setSearchValue("");
  }, []);

  const handleSearchFieldChange = useCallback(async (value) => {
    setSearchValue(value);
  }, []);

  const fetchData = async (abortController) => {
    try {
      const data = await getRequestWithSignal(`/api/search?page=1&limit=20&q=${encodeURIComponent(searchValue).replace(/_/g, "[_]")}`, abortController.signal);
      setSearchResults(data);
    } catch (error) {
      if (error.name === "AbortError") return;
    }
  };

  const debouncedFetchData = useDebounce(fetchData);

  useEffect(() => {
    const abortController = new AbortController();
    const fetchDataWithAbort = async () => debouncedFetchData(abortController);
    fetchDataWithAbort();
    return () => abortController.abort();
  }, [searchValue]);

  useEffect(() => {
    setSearchValue("");
  }, [location.pathname]);

  const handleLogout = useCallback(async () => {
    await logout();
    navigate("/login");
  }, [navigate]);

  const userMenuMarkup = user && (
    <TopBar.UserMenu name={user.username} open={userMenuActive} onToggle={setUserMenuActive} actions={[{ items: [{ content: "Log out", icon: ExitIcon, onAction: handleLogout }] }]} />
  );

  const searchResultsMarkup = (
    <ActionList
      items={searchResults.map((result) => {
        const { entity_type, helptext1, helptext2, helptext3, Name } = result;
        let helptext = "";
        let icon = "";
        let url = "";
        if (entity_type === "company") {
          let city = helptext1 || "";
          helptext = `#${helptext3} ${city} (${helptext2})`;
          icon = InventoryIcon;
          url = `/companies/${result.ID}`;
        } else if (entity_type === "product") {
          helptext = `#${result.ID}, ${formatToCurrency(helptext2)} - ${helptext1}`;
          icon = ProductIcon;
          url = `/products/${result.ID}`;
        } else {
          //it has to be a contact
          helptext = helptext1;
          icon = PersonIcon;
          url = `/contacts/${result.ID}`;
        }
        return { content: Name, url, icon, helpText: helptext };
      })}
    />
  );

  const searchFieldMarkup = <TopBar.SearchField onChange={handleSearchFieldChange} value={searchValue} placeholder="Search" style={{ backgroundColor: "#bf9b5c" }} />;

  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      userMenu={userMenuMarkup}
      searchResultsVisible={searchValue}
      searchField={searchFieldMarkup}
      searchResults={searchResultsMarkup}
      onSearchResultsDismiss={handleSearchResultsDismiss}
      onNavigationToggle={setMobileNavigationActive}
    />
  );

  const navigationMarkup = location.pathname.includes("/workstations/") ? null : (
    <Navigation location={location.pathname}>
      <Navigation.Section
        fill
        items={[
          { url: "/home", label: "Home", icon: HomeFilledIcon },
          { url: "/products", label: "Products", icon: ProductFilledIcon },
          { url: "/purchases", label: "Purchase orders", icon: OrderFilledIcon },
          { url: "/workorders", label: "Work orders", icon: FlowerFilledIcon },
          { url: "/qc", label: "Quality control", icon: EyeCheckMarkIcon },
          { url: "/serials", label: "Serial numbers", icon: HashtagIcon },
          { url: "/checkoverview", label: "Checklists", icon: ClipboardCheckFilledIcon },
          { url: "/box", label: "Box production", icon: MetaobjectListIcon },
          { url: "/pitchers", label: "Pitchers", icon: MetafieldsFilledIcon },
          { url: "/boxes", label: "Boxes", icon: PackageFilledIcon },
          { url: "/fulfilment", label: "Fulfilment", icon: ConfettiIcon },
          { url: "/stock", label: "Stock history", icon: SearchRecentIcon },
          { url: "/designer/greg", label: "Product designer Greg", icon: DragDropIcon },
          { url: "/designer/jack", label: "Product designer Jack", icon: DragDropIcon },
          { url: "/translations", label: "Translations", icon: LanguageFilledIcon },
          { url: "/rfid", label: "RFID", icon: BarcodeIcon },
        ]}
      />
      <Navigation.Section separator items={[{ url: "/settings", label: "Settings", icon: SettingsIcon }]} />
    </Navigation>
  );

  return (
    <AppProvider i18n={enTranslations} linkComponent={Link}>
      {!onLoginPage ? (
        <Frame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={setMobileNavigationActive}
          skipToContentTarget={skipToContentRef.current}
          logo={{ width: 124, contextualSaveBarSource: logo, topBarSource: logo, url: "/home" }}>
          <div style={{ paddingBottom: "30px" }}>
            <Outlet />
          </div>
        </Frame>
      ) : (
        <Outlet />
      )}
    </AppProvider>
  );
}

export default FrameExample;
