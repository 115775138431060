import React, { useEffect, useState } from "react";
import { Button, Badge, Link, Text, ButtonGroup, BlockStack } from "@shopify/polaris";
import { S3ImageUploader } from "../../../shared/components/s3_image_display/s3ImageUploader";
import InventoryReceiving from "./InventoryReceiving";
import { postRequest } from "../../../shared/components/functions";

export default function InventoryReceivingItem({ getPurchase, item, classTypes, toggleModal, setToasts }) {
  const { Productref, tekstlijn, aantal, produktnr, dropbox_quality, product_files, StillOnOrder } = item;
  const [ok, setOk] = useState(null);
  const [nok, setNok] = useState(null);
  const [S3ObjectKeys, setS3ObjectKeys] = useState(null);
  const [S3FileUrls, setS3FileUrls] = useState([]);

  const fileKeys = JSON.parse(product_files === "null" ? "[]" : product_files) || [];
  const qualityFiles = fileKeys.filter((k) => k.type === "quality_control");

  useEffect(() => {
    async function fetchS3ObjectUrls() {
      if (!fileKeys) return setS3FileUrls([]);
      if (fileKeys.length === 0) return setS3FileUrls([]);
      const data = await postRequest(`/api/aws/s3/download`, { keys: fileKeys.map((key) => key.key) });
      setS3FileUrls(data);
    }
    fetchS3ObjectUrls();
  }, []);

  return (
    <BlockStack gap="300">
      <BlockStack>
        <Link url={`/products/${produktnr}`} target="_blank" removeUnderline>
          <Text variant="headingMd" as="h6">{`#${produktnr} ${tekstlijn}`}</Text>
        </Link>
        <Text>Reference: {Productref}</Text>
        <Text>Ordered: {aantal}</Text>
        <Text>
          Incoming: <Badge tone="warning">{StillOnOrder}</Badge>
        </Text>

        {qualityFiles && qualityFiles.length > 0 ? (
          <>
            <Text>Quality control files:</Text>
            <BlockStack>
              {qualityFiles.map((q, idx) => {
                return (
                  <Link key={idx} target="_blank" removeUnderline url={S3FileUrls.find((key) => key.key === q.key)?.url}>
                    {q.name}
                  </Link>
                );
              })}
            </BlockStack>
          </>
        ) : null}
      </BlockStack>

      <ButtonGroup variant="segmented">
        <Button
          pressed={ok}
          onClick={() => {
            setOk(true);
            setNok(false);
          }}>
          OK
        </Button>
        <Button
          tone="critical"
          pressed={nok}
          onClick={() => {
            setNok(true);
            setOk(false);
          }}>
          NOK
        </Button>
      </ButtonGroup>

      <S3ImageUploader
        S3ObjectKeys={S3ObjectKeys}
        S3ObjectNamePrefix="qc_images"
        onUploadImages={async (updatedS3ObjectKeys) => setS3ObjectKeys(JSON.stringify(updatedS3ObjectKeys))}
        onRemoveImages={async (updatedS3ObjectKeys) => setS3ObjectKeys(JSON.stringify(updatedS3ObjectKeys))}
      />

      <InventoryReceiving
        isOk={ok}
        getPurchase={getPurchase}
        line={item}
        reminderChecklist={ok ? dropbox_quality : null}
        active={ok ? ok : nok}
        setActive={ok ? setOk : setNok}
        ordered={StillOnOrder}
        classTypes={ok ? null : classTypes}
        toggleModal={toggleModal}
        S3ObjectKeys={S3ObjectKeys}
        setToasts={setToasts}
      />
    </BlockStack>
  );
}
