import { useState, useEffect } from "react";
import { DataTable, Page, Card, Filters, Button, Link, InlineStack } from "@shopify/polaris";
import { addFilters, applyFilters, filtersToApiUrl, handleFilterChange, limitOptions, removeAllFilters, useUrl } from "../../shared/filters/filterfunctions";
import { useLocation, useNavigate } from "react-router-dom";
import { deleteRequest, getRequest, getTableFooter, postRequest, putRequest } from "../../shared/components/functions";
import { EditIcon, DeleteIcon } from "@shopify/polaris-icons";
import { useToggle } from "../../shared/hooks/useToggle";
import { AddEditTranslationModal } from "./translationModal";
import { Toasts } from "../../shared/components/toasts/toasts";
import { PageSpinner } from "../../shared/components/spinner/PageSpinner";

export default function TranslationsPage() {
  const url = useUrl();
  const location = useLocation();
  const navigate = useNavigate();

  const emptyTranslation = {
    OnderdeelSchuilenburgID: "",
    ratio_schuilenburg: 1,
    ratio_aha: 1,
    OnderdeelAhaID: "",
  };

  const [loaded, setLoaded] = useState(false);
  const [toast, setToast] = useState(null);
  const [savingResult, setSavingResult] = useState(false);
  const [modalActive, setModalActive] = useToggle(false);
  const [valid, setValid] = useState(false);
  const [translation, setTranslation] = useState(emptyTranslation);
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState(
    new Map([
      ["limit", { type: "limit", name: "Limit", value: url.get("limit") || "100", options: limitOptions }],
      ["page", { type: "page", name: "Page", value: url.get("page") || "1" }],
      ["q", { type: "text", name: "Q", value: url.get("q") || "" }],
    ])
  );

  async function fetchData() {
    let filterString = filtersToApiUrl(filters);
    const data = await getRequest(`/api/translations?${filterString}`);
    setItems(data);
    setLoaded(true);
    if (window.location.pathname === location.pathname) navigate(`${window.location.pathname}?${filterString}`);
  }

  useEffect(() => {
    fetchData();
  }, [filters, toast]);

  const openModal = (id) => {
    setModalActive();
    setTranslation(parseInt(id) ? items.find((i) => i.OnderdelenTranslationID === id) : emptyTranslation);
  };

  const handleRemove = async (id) => {
    setSavingResult(true);
    await deleteRequest(`/api/translations/${id}`, setToast);
    await fetchData();
    setSavingResult(false);
  };

  const handleSave = async (payload) => {
    setSavingResult(true);
    if (valid) {
      if (payload.OnderdelenTranslationID) {
        await putRequest(`/api/translations/${payload.OnderdelenTranslationID}`, payload, setToast);
      } else {
        await postRequest("/api/translations", payload, setToast);
      }
      setSavingResult(false);
      setModalActive(false);
    }
  };

  const rows = Array.isArray(items)
    ? items.map((item, idx) => {
        const previousItem = items[idx - 1];
        const previousItemID = previousItem?.OnderdeelSchuilenburgID;
        const duplicate = previousItemID === item?.OnderdeelSchuilenburgID;

        return [
          item.schuil_name,
          duplicate ? `❌${item.OnderdeelSchuilenburgID}❌` : `${item.OnderdeelSchuilenburgID}`,
          item.ratio_schuilenburg,
          item.ratio_aha,
          item.OnderdeelAhaID,
          <Link removeUnderline url={`/products/${item.OnderdeelAhaID}`}>
            {item.pm_name}
          </Link>,
          <InlineStack gap="100" align="end">
            <Button size="slim" icon={EditIcon} onClick={() => openModal(item.OnderdelenTranslationID)} />
            <Button size="slim" tone="critical" icon={DeleteIcon} onClick={() => handleRemove(item.OnderdelenTranslationID)} />
          </InlineStack>,
        ];
      })
    : [];

  if (!loaded) return <PageSpinner />;

  return (
    <Page title="Translations" fullWidth primaryAction={{ content: "Add translation", onAction: openModal }}>
      <Toasts toast={toast} setToast={setToast} />
      <AddEditTranslationModal
        active={modalActive}
        setActive={setModalActive}
        handleSave={handleSave}
        translation={translation}
        setTranslation={setTranslation}
        setValid={setValid}
        savingResult={savingResult}
      />
      <Card>
        <Filters
          queryValue={filters.get("q").value}
          filters={addFilters(filters, setFilters)}
          appliedFilters={applyFilters(filters, setFilters)}
          onClearAll={() => removeAllFilters(filters, setFilters)}
          onQueryChange={(e) => handleFilterChange(e, "q", filters.get("q").value, setFilters)}
          onQueryClear={() => handleFilterChange("", "q", filters.get("q").value, setFilters)}
          autoComplete={false}
          queryPlaceholder="Filter translations"
        />
        <DataTable
          verticalAlign="middle"
          columnContentTypes={["text", "text", "text", "text", "text", "text", "numeric"]}
          headings={[<b>Schuilenburg name</b>, <b>Schuil_ID</b>, <b>Schuil_ratio</b>, <b>Aha_ratio</b>, <b>PM_ID</b>, <b>Perfect Moose name</b>, <b>Edit/remove</b>]}
          rows={rows}
          footerContent={getTableFooter(rows, filters.get("page"), filters.get("limit"), setFilters, "translations")}
        />
      </Card>
    </Page>
  );
}
