import { jsPDF } from "jspdf";
import { imgurl, imgVertical, helpQR } from "../dataImg";
import qrcode from "qrcode-generator";

function printSinglePartLabel(line, include_qty, smallLabel) {
  const doc = new jsPDF(smallLabel ? "l" : "p", "mm", smallLabel ? [89, 41] : [104, 159]);
  getContentPartLabel(doc, line, include_qty, smallLabel);
  doc.save(`part_label_${line.ProductID}.pdf`);
}

function printAllPartLabels(all_lines, include_qty, smallLabel) {
  const doc = new jsPDF(smallLabel ? "l" : "p", "mm", smallLabel ? [89, 41] : [104, 159]);

  var lines = all_lines.filter((line) => {
    if (line.produktnr > 50) return line;
  });

  for (var i = 0; i < lines.length; i++) {
    if (lines[i].produktnr > 50) {
      if (include_qty) {
        getContentPartLabel(doc, lines[i], true, smallLabel);
        if (i < lines.length - 1) doc.addPage();
      } else {
        for (var y = 0; y < lines[i].aantal; y++) {
          getContentPartLabel(doc, lines[i], false, smallLabel);
          if (!(i < lines.length - 1)) {
            if (y < lines[i].aantal - 1) doc.addPage();
          } else {
            doc.addPage();
          }
        }
      }
    }
  }
  doc.save(`part_label${""}.pdf`);
}

async function getContentPartLabel(doc, line, include_qty, smallLabel) {
  const maxProductNameLineLength = smallLabel ? 38 : 35;
  const numPxsPerLine = smallLabel ? 5 : 7;
  const productName = line.produktnr > 50 ? line.ProductNameNL : line.tekstlijn;

  const numLinesPerProductName = Math.ceil(productName.length / maxProductNameLineLength);
  const ySpaceAfterProductName = (numLinesPerProductName - 1) * numPxsPerLine;

  // SMALL LABEL

  if (smallLabel) {
    doc.addImage(imgurl, "png", 1, 2, 40, 15, "", "FAST");

    if (line.ExternalQrUrl) {
      const qr = qrcode(0, "L");
      qr.addData(line.ExternalQrUrl);
      qr.make();
      const qrCodeImageData = qr.createDataURL(10, 10);
      doc.addImage(qrCodeImageData, "png", 68, 1, 20, 20, "", "FAST");
      doc.setFontSize(7);
      doc.text("Product manual", 69, 23);
    }

    doc.setFontSize(9);
    //part ref
    doc.setFont("helvetica", "bold");
    doc.text("Product ref", 5, 23);
    doc.setFont("helvetica", "normal");
    doc.text(`${line.produktnr}`, 28, 23);

    //part name
    doc.setFont("helvetica", "bold");
    doc.text("Product name", 5, 28);
    doc.setFont("helvetica", "normal");

    if (numLinesPerProductName > 1) {
      let slicedProductName;
      for (let i = 0; i < numLinesPerProductName; i++) {
        slicedProductName = productName.slice(maxProductNameLineLength * i, maxProductNameLineLength * (i + 1));
        doc.text(`${slicedProductName}`, 28, 28 + i * numPxsPerLine);
      }
    } else {
      doc.text(`${productName}`, 28, 28);
    }

    if (include_qty) {
      //qty
      doc.setFont("helvetica", "bold");
      doc.text("Quantity", 5, 33 + ySpaceAfterProductName);
      doc.setFont("helvetica", "normal");
      doc.text(`${line.aantal}`, 28, 33 + ySpaceAfterProductName);
    }

    return;
  }

  // BIG LABEL

  doc.addImage(imgVertical, "png", 30, 5, 40, 55, "", "FAST");
  doc.line(8, 72, 96, 72);
  //part info
  doc.setFontSize(10);
  //part ref
  doc.setFont("helvetica", "bold");
  doc.text("Product ref", 8, 80);
  doc.setFont("helvetica", "normal");
  doc.text(`${line.produktnr}`, 35, 80);
  //part name
  doc.setFont("helvetica", "bold");
  doc.text("Product name", 8, 87);
  doc.setFont("helvetica", "normal");

  if (numLinesPerProductName > 1) {
    let slicedProductName;
    for (let i = 0; i < numLinesPerProductName; i++) {
      slicedProductName = productName.slice(maxProductNameLineLength * i, maxProductNameLineLength * (i + 1));
      doc.text(`${slicedProductName}`, 35, 87 + i * numPxsPerLine);
    }
  } else {
    doc.text(`${productName}`, 35, 87);
  }

  if (include_qty) {
    //qty
    doc.setFont("helvetica", "bold");
    doc.text("Quantity", 8, 94 + ySpaceAfterProductName);
    doc.setFont("helvetica", "normal");
    doc.text(`${line.aantal}`, 35, 94 + ySpaceAfterProductName);
    doc.line(8, 100 + ySpaceAfterProductName, 96, 100 + ySpaceAfterProductName);
  } else {
    doc.line(8, 93 + ySpaceAfterProductName, 96, 93 + ySpaceAfterProductName);
  }

  doc.setFontSize(9);
  if (line.ExternalQrUrl) {
    doc.text("Product manual", 9, 128);
    const qr = qrcode(0, "L");
    qr.addData(line.ExternalQrUrl);
    qr.make();
    const qrCodeImageData = qr.createDataURL(10, 10);
    doc.addImage(qrCodeImageData, "png", 8, 129, 27, 27, "", "FAST");
  }

  doc.text("Reach out to us", 72, 128);
  doc.addImage(helpQR, "png", 71, 130, 25, 25, "", "FAST");
}

export { printSinglePartLabel, printAllPartLabels };
