import { Badge } from "@shopify/polaris";

export const getAanbodBadge = (item, diffDays) => {
  if (item.canceled) return <Badge tone="warning">Cancelled</Badge>;
  if (item.Aanbod) return String(item.Datum).substring(0, 10);
  if (diffDays < 7) return <Badge>Awaiting</Badge>;
  if (diffDays >= 7 && diffDays < 14) return <Badge tone="attention">{">7 days awaiting"}</Badge>;
  if (diffDays >= 14 && diffDays < 21) return <Badge tone="warning">{">14 days awaiting"}</Badge>;
  return <Badge tone="critical">{">21 days awaiting"}</Badge>;
};

export const getStatusBadge = (item) => {
  if (item.canceled) return <Badge tone="warning">Cancelled</Badge>;
  if (item.Ontvangen || item.num_parst_still_on_order <= 0) return <Badge progress="complete">Received</Badge>;
  return (
    <Badge tone="success" progress="incomplete">
      Awaiting
    </Badge>
  );
};

export const getQcBadge = (item) => {
  if (item.canceled) return <Badge tone="warning">Cancelled</Badge>;
  if (item.num_parts_ordered === item.num_parst_still_on_order) {
    if (item.Ontvangen)
      return (
        <Badge progress="incomplete" tone="info">
          Awaiting QC
        </Badge>
      );
    return (
      <Badge progress="incomplete" tone="success">
        Awaiting delivery
      </Badge>
    );
  }
  if (item.num_parst_still_on_order <= 0) return <Badge progress="complete">Done</Badge>;
  return (
    <Badge progress="partiallyComplete" tone="warning">
      Partially
    </Badge>
  );
};

export const getStillOnOrderBadge = (stillOnOrder) => {
  if (stillOnOrder) {
    let numberFormatter = new Intl.NumberFormat("en-US");
    if (stillOnOrder > 0) return <Badge tone="warning">{numberFormatter.format(stillOnOrder)}</Badge>;
    return <Badge tone="success">{stillOnOrder}</Badge>;
  }
  return <Badge tone="success">0</Badge>;
};
