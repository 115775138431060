import { BlockStack, Button, Card, Collapsible, DataTable, Form, Modal, Page, Text, TextField } from "@shopify/polaris";
import { ChevronDownIcon, ChevronUpIcon, EditIcon } from "@shopify/polaris-icons";
import { useState } from "react";
import { postRequest, putRequest } from "../../shared/components/functions";
import { Toasts } from "../../shared/components/toasts/toasts";
import { useToggle } from "../../shared/hooks/useToggle";
import { useLookupStore } from "../../utils/useStore";
import { CardHeading } from "../../shared/components/CardHeading";

const SettingsCard = ({ title, url, items, getData }) => {
  const [collapsible, setCollapsible] = useToggle(false);
  const [modal, setModal] = useToggle(false);
  const [toast, setToast] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [itemToEdit, setItemToEdit] = useState({ id: null, name: "" });

  const openModal = (item) => {
    setItemToEdit(item);
    setModal();
  };

  const rows = items.map((item) => [item.id, item.name, <Button icon={EditIcon} onClick={() => openModal(item)} size="slim" />]);

  const handleSave = async (item) => {
    setIsSaving(true);
    item.id ? await putRequest(`/api/${url}/${item.id}`, item, setToast) : await postRequest(`/api/${url}`, item, setToast);
    setIsSaving(false);
    handleClose();
  };

  const handleClose = () => {
    setItemToEdit({ id: null, name: "" });
    setModal();
    getData();
  };

  return (
    <Card>
      <CardHeading
        title={
          <Button variant="tertiary" icon={collapsible ? ChevronUpIcon : ChevronDownIcon} onClick={setCollapsible}>
            <Text variant="headingMd" as="h5">{`${title} types`}</Text>
          </Button>
        }
        actions={[{ content: "Add", onAction: setModal, variant: 'secondary' }]}
      />
      <SettingsModal
        modal={modal}
        setModal={setModal}
        title={`${title} type`}
        itemToEdit={itemToEdit}
        setItemToEdit={setItemToEdit}
        handleSave={handleSave}
        handleClose={handleClose}
        isSaving={isSaving}
      />
      <Toasts toast={toast} setToast={setToast} toastContent="Changes saved" />
      <Collapsible open={collapsible}>
        <DataTable increasedTableDensity verticalAlign="middle" columnContentTypes={["text", "text", "numeric"]} headings={[<b>ID</b>, <b>{title}</b>, <b>Edit</b>]} rows={rows} />
      </Collapsible>
    </Card>
  );
};

const SettingsModal = ({ modal, setModal, title, handleSave, handleClose, isSaving, itemToEdit, setItemToEdit }) => {
  return (
    <Modal
      open={modal}
      onClose={handleClose}
      title={(itemToEdit.id ? "Edit " : "Add ") + title?.toLowerCase()}
      primaryAction={{ content: "Save", onAction: () => handleSave(itemToEdit), loading: isSaving }}
      secondaryActions={[{ content: "Close", onAction: setModal }]}>
      <Modal.Section>
        <Form onSubmit={() => handleSave(itemToEdit)}>
          <TextField label="Type name" value={itemToEdit.name} onChange={(e) => setItemToEdit((i) => ({ ...i, name: e }))} autoComplete="off" />
        </Form>
      </Modal.Section>
    </Modal>
  );
};

export default function SettingsPage() {
  const { producttypes, setProducttypes, recipetypes, setRecipetypes, softwaretypes, setSoftwaretypes, hardwaretypes, setHardwaretypes, hardwareboilertypes, setHardwareboilertypes } =
    useLookupStore();

  const productOptions = producttypes.map((d) => {
    return { id: d.ProductTypeID, name: d.Product };
  });
  const recipeOptions = recipetypes.map((d) => {
    return { id: d.RecipeTypeID, name: d.Recipe };
  });
  const softwareOptions = softwaretypes.map((d) => {
    return { id: d.SofwareTypeID, name: d.Software };
  });
  const hardwareOptions = hardwaretypes.map((d) => {
    return { id: d.HardwareTypeID, name: d.Hardware };
  });
  const hardwareBoilerOptions = hardwareboilertypes.map((d) => {
    return { id: d.HardwareBoilerTypeID, name: d.HardwareBoiler };
  });

  return (
    <Page title="Settings" fullWidth>
      <BlockStack gap="400">
        <SettingsCard title="Product" url="producttypes" items={productOptions} getData={setProducttypes} />
        <SettingsCard title="Recipe" url="recipetypes" items={recipeOptions} getData={setRecipetypes} />
        <SettingsCard title="Software" url="softwaretypes" items={softwareOptions} getData={setSoftwaretypes} />
        <SettingsCard title="Hardware" url="hardwaretypes" items={hardwareOptions} getData={setHardwaretypes} />
        <SettingsCard title="Hardware boiler" url="hardwareboilertypes" items={hardwareBoilerOptions} getData={setHardwareboilertypes} />
      </BlockStack>
    </Page>
  );
}
