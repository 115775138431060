import { useState, useEffect, useCallback } from "react";
import { Card, Link, Button, TextField, DataTable, InlineStack } from "@shopify/polaris";
import Select from "react-select";
import { deleteRequest, getRequest, postRequest, putRequest, truncate } from "../../shared/components/functions";
import { DeleteIcon, EditIcon } from "@shopify/polaris-icons";
import { CardHeading } from "../../shared/components/CardHeading";

export default function ChecksOnListTable({ checkListId, checkOptions, setToast }) {
  const [savingResult, setSavingResult] = useState(false);
  const [isDirty, setIsDirty] = useState(false);
  const [checksOnList, setChecksOnList] = useState([]);
  const [lineToEdit, setLineToEdit] = useState(null);

  const fetchChecksOnList = useCallback(async () => {
    const data = await getRequest(`/api/check_on_checklists?checkListId=${checkListId}`);
    if (data) setChecksOnList(data.sort((a, b) => (a.priority > b.priority ? 1 : b.priority > a.priority ? -1 : 0)));
  }, []);

  useEffect(() => {
    fetchChecksOnList();
  }, []);

  const handleAddCheck = () => {
    const priorities = checksOnList.map((c) => c.priority);
    const newRow = {
      checkId: "",
      priority: Math.max(...priorities) + 1,
      new: true,
    };
    setChecksOnList((prevChecksOnList) => [...prevChecksOnList, newRow]);
    setLineToEdit(newRow);
  };

  const handleChange = (value, fieldName) => {
    setLineToEdit((lineToEdit) => ({ ...lineToEdit, [fieldName]: value }));
    setIsDirty(true);
  };

  const handleSave = async () => {
    setSavingResult(true);
    const payload = {
      checkId: lineToEdit.checkId.value || lineToEdit.checkId,
      checkListId: checkListId,
      priority: lineToEdit.priority,
    };
    lineToEdit.id ? await putRequest(`/api/check_on_checklists/${lineToEdit.id}`, payload, setToast) : await postRequest(`/api/check_on_checklists`, payload, setToast);
    setIsDirty(false);
    setLineToEdit(null);
    fetchChecksOnList();
    setSavingResult(false);
  };

  const handleDelete = async (lineId) => {
    const confirmed = window.confirm("Are you sure you want to delete this check from the list?");
    if (confirmed) {
      await deleteRequest(`/api/check_on_checklists/${lineId}`, setToast);
      fetchChecksOnList();
    }
  };

  const handleDiscard = () => {
    setIsDirty(false);
    setLineToEdit(null);
    const linesWithId = checksOnList.filter((c) => c.id);
    setChecksOnList(linesWithId);
  };

  const rows = Array.isArray(checksOnList)
    ? checksOnList.map((check, idx) => {
        const isEditing = lineToEdit && lineToEdit.id === check.id;

        const prio = isEditing ? (
          <TextField id="priority" value={lineToEdit.priority} onChange={(value) => handleChange(value, "priority", idx)} label="Priority" labelHidden type="number" />
        ) : (
          check.priority
        );

        const checkSelect = isEditing ? (
          <div style={{ width: "350px" }}>
            <Select
              id={"checkId"}
              options={checkOptions}
              menuPortalTarget={document.body}
              value={checkOptions.find((c) => c.value === lineToEdit.checkId)}
              onChange={(selectedOption) => handleChange(selectedOption.value, "checkId")}
              styles={{
                valueContainer: (base) => ({ ...base, height: "32px" }),
                indicatorSeperator: (base) => ({ ...base, height: "32px" }),
                indicatorsContainer: (base) => ({ ...base, height: "32px" }),
                control: (base) => ({ ...base, height: "32px", minHeight: "32px", borderRadius: "8px", borderColor: "#919191" }),
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
              }}
            />
          </div>
        ) : (
          <Link removeUnderline url={`/checks/${check.checkId}`}>
            {check.checkName}
          </Link>
        );

        return [
          prio,
          checkSelect,
          !isEditing && truncate(check.checkDescription, 20, false),
          !isEditing && check.checkType ? check.checkType?.charAt(0).toUpperCase() + check.checkType.slice(1) : null,
          !lineToEdit && (
            <InlineStack align="end" gap="100">
              <Button
                icon={EditIcon}
                onClick={() => {
                  setLineToEdit(check);
                  setIsDirty(false);
                }}
              />
              <Button tone="critical" icon={DeleteIcon} onClick={() => handleDelete(check.id)} />
            </InlineStack>
          ),
        ];
      })
    : [];

  return (
    <Card>
      <CardHeading
        title="Checks"
        actions={
          lineToEdit
            ? [
                { content: "Cancel", onAction: handleDiscard, variant: "primary", tone: "critical", disabled: savingResult },
                { content: "Save", onAction: handleSave, variant: "primary", loading: savingResult, disabled: !isDirty },
              ]
            : [{ content: "Add check", onAction: handleAddCheck, variant: "secondary" }]
        }
      />
      {checksOnList && checksOnList.length > 0 ? (
        <DataTable
          verticalAlign="middle"
          increasedTableDensity
          columnContentTypes={["text", "text", "text", "text", "numeric"]}
          headings={[<b>Priority</b>, <b>Check</b>, <b>Description</b>, <b>Type</b>, <b>Edit/remove</b>]}
          rows={rows}
        />
      ) : null}
    </Card>
  );
}
