async function getRequest(url: string) {
  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser") || "{}").token, "Content-Type": "application/json" }),
  });
  const data = await response.json();
  return data;
}

async function getRequestWithSignal(url: RequestInfo, signal: AbortSignal) {
  const response = await fetch(url, {
    method: "GET",
    headers: new Headers({ Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser") || "{}").token, "Content-Type": "application/json" }),
    signal: signal
  });
  if (!response.ok) return console.log(response);
  return response.json();
}

async function postRequest(url: string, payload: any, setToast = console.log) {
  const response = await fetch(url, {
    method: "POST",
    headers: new Headers({ Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser") || "{}").token, "Content-Type": "application/json" }),
    body: JSON.stringify(payload),
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    setToast('ok');
    return data;
  } else {
    return setToast('error');
  }
}

async function patchRequest(url: string, payload: any, setToast = console.log) {
  const response = await fetch(url, {
    method: "PATCH",
    headers: new Headers({ Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser") || "{}").token, "Content-Type": "application/json" }),
    body: JSON.stringify(payload),
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    setToast('ok');
    return data;
  } else {
    return setToast('error');
  }
}

async function putRequest(url: string, payload: any, setToast = console.log) {
  const response = await fetch(url, {
    method: "PUT",
    headers: new Headers({ Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser") || "{}").token, "Content-Type": "application/json" }),
    body: JSON.stringify(payload),
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    setToast('ok');
    return data;
  } else {
    return setToast('error');
  }
}

async function deleteRequest(url: string, setToast = console.log,) {
  const response = await fetch(url, {
    method: "DELETE",
    headers: new Headers({ Authorization: "Bearer " + JSON.parse(localStorage.getItem("currentUser") || "{}").token, "Content-Type": "application/json", }),
  });
  if (response.status >= 200 && response.status <= 399) {
    const data = await response.json();
    setToast('ok');
    return data;
  } else {
    return setToast('error');
  }
}

export {
  getRequest,
  postRequest,
  patchRequest,
  putRequest,
  deleteRequest,
  getRequestWithSignal
};
